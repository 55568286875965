import { React, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';

const warehouseHeaders = Array.from(['warehouseName', 'country', 'warehouseContacts']);

const buttonList = Array.from(['warehouseTable', 'addWarehouse']);

export function WarehouseButtons({ token, setToken, removeToken }) {
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);

    console.count('Rerender ');

    function handleActiveTab(activeTab) {
        if (activeTab === 'warehouseTable') {
            setShowForm(false);
            setShowTable(true);
        } else if (activeTab === 'addWarehouse') {
            setShowTable(false);
            setShowForm(true);
        }
    }

    return (
        <>
            <ButtonMenu buttonList={buttonList} key="warehouseButtonMenu" setActiveTab={handleActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={warehouseHeaders}
                dataFetchId="/warehouse_management"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={handleActiveTab}
                formFetchId={'addWarehouse'}
                token={token}
                removeToken={removeToken}
            />
        </>
    );
}
