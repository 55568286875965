import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import {
    Home,
    Error404,
    InventoryManagement,
    ContainerManagement,
    OpenOrders,
    DraftOrders,
    ProcessedOrders,
    ExportCentre,
    RedProducts,
    FactoryDefects,
    Invoicing,
    Pricing,
    ChannelManagement,
    ApplyLeave,
    UserManagement,
    TrainingMaterial,
    PurchaseOrders,
    FactoryManagement,
    WarehouseManagement,
    ResetPasswordConfirm,
} from './pages';
import LogoutButton from './logout';

const homeSubtabs = [];

const homeInfo = {
    icon: 'fas fa-home',
    title: 'Home',
};

const inventorySubtabs = [
    'Inventory Management',
    'Container Management',
    'Purchase Orders',
    'Factory Management',
    'Warehouse Management',
];

const inventoryInfo = {
    icon: 'fas fa-boxes',
    title: 'Inventory',
};

const ordersSubtabs = ['Open Orders', 'Processed Orders', 'Draft Orders'];

const ordersInfo = {
    icon: 'fas fa-book',
    title: 'Orders',
};

const integrationsSubtabs = ['Channel Management'];

const integrationsInfo = {
    icon: 'fas fa-link',
    title: 'Integrations',
};

const dataSubtabs = ['Pricing', 'Export Centre', 'Red Products', 'Factory Defects', 'Invoicing'];

const dataInfo = {
    icon: 'fas fa-table',
    title: 'Data',
};

const hradminSubtabs = ['User Management', 'Apply Leave', 'Training Material'];

const hradminInfo = {
    icon: 'fas fa-users',
    title: 'Admin',
};

function tabToLink(subtab) {
    let text = subtab.toString();
    text = text.toLowerCase();
    text = text.replace(/ /g, '_');
    return text;
}

function toggleSelect(tab, select) {
    if (select.subtab === tab.subtab) {
        return 'selected';
    } else {
        return 'unselected';
    }
}

function returnLink(title) {
    if (title === 'Home') {
        return '/';
    } else {
        return '#navbar';
    }
}

function Tab({ subtabs, info, select, setSelect }) {
    const [tabClass, setTabClass] = useState('dropdown-menu');
    return (
        <li className="navbarContainer-dropdown" key="dropdown">
            <Link
                to={returnLink(info.title)}
                onClick={() => {
                    if (info.title === 'Home') {
                        setSelect('');
                    } else {
                        tabClass === 'dropdown-menu'
                            ? setTabClass('dropdown-menu-clicked')
                            : setTabClass('dropdown-menu');
                    }
                }}
            >
                <span>&ensp;</span>
                <i className={info.icon} aria-hidden="true">
                    {' '}
                    <p>{info.title}</p>
                </i>
            </Link>
            <ul className={tabClass} key={tabClass}>
                {subtabs.map((subtab) => (
                    <li key={subtab}>
                        <Link
                            to={tabToLink(subtab)}
                            onClick={() => (select === { subtab } ? setSelect('') : setSelect({ subtab }))}
                            className={toggleSelect({ subtab }, select)}
                        >
                            {subtab}
                        </Link>
                    </li>
                ))}
            </ul>
        </li>
    );
}

export function Pages({ token, removeToken, setToken, user, removeUser, setUser }) {
    return (
        <Routes key="pages">
            <Route
                key="Home"
                path="/"
                element={<Home token={token} setToken={setToken} removeToken={removeToken} user={user} />}
            />
            <Route
                key="InventoryManagement"
                path="/inventory_management"
                element={<InventoryManagement token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="ContainerManagement"
                path="/container_management"
                element={<ContainerManagement token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="FactoryManagement"
                path="/factory_management"
                element={<FactoryManagement token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="WarehouseManagement"
                path="/warehouse_management"
                element={<WarehouseManagement token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="PurchaseOrders"
                path="/purchase_orders"
                element={<PurchaseOrders token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="OpenOrders"
                path="/open_orders"
                element={<OpenOrders token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="DraftOrders"
                path="/draft_orders"
                element={<DraftOrders token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="ProcessedOrders"
                path="/processed_orders"
                element={<ProcessedOrders token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="ChannelManagement"
                path="/channel_management"
                element={<ChannelManagement token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="Pricing"
                path="/pricing"
                element={<Pricing token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="ExportCentre"
                path="/export_centre"
                element={<ExportCentre token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="RedProducts"
                path="/red_products"
                element={<RedProducts token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="FactoryDefects"
                path="/factory_defects"
                element={<FactoryDefects token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="Invoicing"
                path="/invoicing"
                element={<Invoicing token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="ApplyLeave"
                path="/apply_leave"
                element={<ApplyLeave token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="UserManagement"
                path="/user_management"
                element={<UserManagement token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route
                key="TrainingMaterial"
                path="/training_material"
                element={<TrainingMaterial token={token} setToken={setToken} removeToken={removeToken} />}
            />
            <Route key="Error404" path="*" element={<Error404 />} />
            {/* <Route path="/reset_password/:pathParam?" element={<ResetPasswordConfirm token={"pathParam"} setToken={setToken} removeToken={removeToken} />} /> */}
        </Routes>
    );
}

export function Hamburger() {
    return (
        <a className="navbarContainer-hamburger" href="#navbar" aria-label="Open main menu." key="hamburger">
            <span className="sr-only">Open main menu</span>
            <i className="fas fa-bars" aria-hidden="true" />
        </a>
    );
}

export function Navbar({ token, removeToken, setToken }) {
    const [selectState, setSelectState] = useState('');
    return (
        <div className="navbarContainer">
            <nav id="navbar" className="navbarContainer-nav">
                <ul className="navbarContainer-ul" key="ul-container">
                    <li key="close">
                        <a className="navbarContainer-close" href="#" aria-label="Close main menu.">
                            <span className="sr-only">Close main menu.</span>
                            <i className="far fa-times-circle" aria-hidden="true">
                                <h4>Close</h4>
                            </i>
                        </a>
                    </li>
                    <Tab subtabs={homeSubtabs} info={homeInfo} select={selectState} setSelect={setSelectState} />
                    <Tab
                        subtabs={inventorySubtabs}
                        info={inventoryInfo}
                        select={selectState}
                        setSelect={setSelectState}
                    />
                    <Tab subtabs={ordersSubtabs} info={ordersInfo} select={selectState} setSelect={setSelectState} />
                    <Tab
                        subtabs={integrationsSubtabs}
                        info={integrationsInfo}
                        select={selectState}
                        setSelect={setSelectState}
                    />
                    <Tab subtabs={dataSubtabs} info={dataInfo} select={selectState} setSelect={setSelectState} />
                    <Tab subtabs={hradminSubtabs} info={hradminInfo} select={selectState} setSelect={setSelectState} />
                    <br></br>
                    <LogoutButton removeToken={removeToken} />
                </ul>
            </nav>
        </div>
    );
}
