import { useState, useEffect } from 'react';
import { handleMultiTitlesValues, handleTitleValue } from './helperFunctions';

export function DropdownWithSearchBar({
    options,
    addNewTitlesValues,
    setAddNewTitlesValues,
    mode,
    subTableId,
    setSubFilterList,
    setSearchTerm,
    identifier,
    title,
    setTitle,
    commonDataProps,
    inputVariables,
}) {
    const [hideDropdown, setHideDropdown] = useState(true);
    const wrapperClass = hideDropdown ? 'dropdownWrapperHidden' : 'dropdownWrapper';
    const [selectTitle, setSelectTitle] = useState('Select...');

    function handleDropdown() {
        if (hideDropdown === true) {
            setHideDropdown(false);
        } else {
            setHideDropdown(true);
        }
    }

    function handleSelect(e) {
        setSelectTitle(e.target.textContent);
        setHideDropdown(true);
        if (mode === 'dropdown-text') {
            handleTitleValue(e, {
                addNewTitlesValues: addNewTitlesValues,
                setAddNewTitlesValues: setAddNewTitlesValues,
                title: title,
                setTitle: setTitle,
                mode: mode,
            });
        } else {
            handleMultiTitlesValues(e, {
                addNewTitlesValues: addNewTitlesValues,
                setAddNewTitlesValues: setAddNewTitlesValues,
                subTableId: subTableId,
                inputVariables: inputVariables,
                mode: mode,
            });
        }

        if (setSubFilterList) {
            setSubFilterList(e.target.textContent);
        }
    }

    useEffect(() => {
        if (subTableId === 'fetchTableChannel') {
            setSelectTitle('All About Chairs');
        } else if (subTableId === 'fetchTableWarehouse') {
            setSelectTitle('Parcelship');
        }
    }, [subTableId]);

    if (options) {
        if (!identifier) {
            return (
                <>
                    <div className="dropdownButtonContainer" key={'dropdownButtonContainer' + subTableId}>
                        <button type="button" className="dropdownSubTableButton" onClick={(e) => handleDropdown(e)}>
                            {selectTitle ? selectTitle : 'Select...'}
                        </button>
                    </div>
                    <div className={wrapperClass} key={'contentWrapper' + subTableId}>
                        <input
                            className="dropdownSearchBar"
                            type="text"
                            placeholder="Search..."
                            onChange={(e) => setSearchTerm(e.target.value)}
                        ></input>
                        <ul className="dropdownContentExtend">
                            {options.map((option, i) => {
                                return (
                                    <li key={i} value={option} name={option} onClick={(e) => handleSelect(e)}>
                                        {option}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="dropdownButtonContainer" key={'dropdownButtonContainer' + subTableId}>
                        <button type="button" className="dropdownSubTableButton" onClick={(e) => handleDropdown(e)}>
                            {selectTitle ? selectTitle : 'Select...'}
                        </button>
                    </div>
                    <div className={wrapperClass} key={'contentWrapper' + subTableId}>
                        <input
                            className="dropdownSearchBar"
                            type="text"
                            placeholder="Search..."
                            onChange={(e) => setSearchTerm(e.target.value)}
                        ></input>
                        <ul className="dropdownContentExtend">
                            {options.map((option, i) => {
                                return (
                                    <li
                                        key={i}
                                        value={option[identifier] ? option[identifier] : option}
                                        name={identifier}
                                        onClick={(e) => handleSelect(e)}
                                    >
                                        {option[identifier] ? option[identifier] : option}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            );
        }
    }
}

export function DropdownWithSearchBarAndStock({
    options,
    addNewTitlesValues,
    setAddNewTitlesValues,
    mode,
    subTableId,
    setSubFilterList,
    setSearchTerm,
    identifier,
    title,
    setTitle,
    commonDataProps,
    selectedWarehouse,
    inputVariables,
}) {
    const [hideDropdown, setHideDropdown] = useState(true);
    const wrapperClass = hideDropdown ? 'dropdownWrapperHidden' : 'dropdownWrapper';
    const [selectTitle, setSelectTitle] = useState('Select...');

    function handleDropdown() {
        if (hideDropdown === true) {
            setHideDropdown(false);
        } else {
            setHideDropdown(true);
        }
    }

    function handleSelect(e, sku) {
        setSelectTitle(sku);
        setHideDropdown(true);
        if (mode === 'dropdown-text') {
            handleTitleValue(e, {
                addNewTitlesValues: addNewTitlesValues,
                setAddNewTitlesValues: setAddNewTitlesValues,
                title: title,
                setTitle: setTitle,
                mode: mode,
            });
        } else {
            handleMultiTitlesValues(e, {
                addNewTitlesValues: addNewTitlesValues,
                setAddNewTitlesValues: setAddNewTitlesValues,
                mode: mode,
                inputVariables: inputVariables,
                subTableId: subTableId,
                commonDataProps: commonDataProps,
            });
        }

        if (setSubFilterList) {
            setSubFilterList(sku);
        }
    }

    function returnAvailable(mainId, skuArray) {
        let tempAvailable = 0;
        skuArray.forEach((row, r) => {
            let total = JSON.parse(row['stock'])[selectedWarehouse];
            let available = JSON.parse(row['inOpenOrders'])[selectedWarehouse];
            if (row[identifier] === mainId) {
                tempAvailable = parseFloat(total) - parseFloat(available);
            }
        });
        return tempAvailable;
    }

    if (options) {
        return (
            <>
                <div className="dropdownButtonContainer" key={'dropdownButtonContainer' + subTableId}>
                    <button type="button" className="dropdownSubTableButton" onClick={handleDropdown}>
                        {selectTitle}
                    </button>
                </div>
                <div className={wrapperClass} key={'contentWrapper' + subTableId}>
                    <input
                        className="dropdownSearchBar"
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                    ></input>
                    <ul className="dropdownContent">
                        {options.map((option, i) => {
                            let sku = option[identifier];
                            let available = returnAvailable(sku, options);

                            if (available > 0) {
                                return (
                                    <li
                                        key={i}
                                        value={option['sku']}
                                        name={'sku'}
                                        onClick={(e) => handleSelect(e, sku)}
                                    >
                                        {sku}
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={i} value={option['sku']} name={'sku'} className="unselectableDropdown">
                                        {sku + ', Available: ' + available}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            </>
        );
    }
}
