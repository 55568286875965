//CREATE DESCRIPTION FOR TABLE ENTRY LINK
import { useEffect, useState } from 'react';
import { ConfirmBox } from './confirmBox';
import { TableSpinner } from './spinner';
import { SubTable } from './subTable';
import { ErrorMessage } from './errorMessage';
import { Fragment } from 'react';
import {
    fetchOptions,
    handleSubmit,
    refreshLongData,
    parseDate,
    handleInfoClear,
    handleEdit,
    handleEditValues,
    handleMeasurementType,
    ClearAndSave,
    parseTitle,
    parseSQLToTitles,
    calcCBM,
    showConfirm,
    handleDeleteImage,
    handleUploadImage,
    editOn,
} from './helperFunctions';
const baseURL = process.env.REACT_APP_API_INVOKE_URL
/**COMMON HEADERS */
const addressHeaders = Array.from(['unitNumber', 'addressLine1', 'addressLine2', 'city', 'postalCode', 'state']);

/**CONDITIONALLY RENDER TABLE BASED ON ORDER FETCH ID */
export function TableItemDescription({
    setLoading,
    setTargetDesc,
    targetDesc,
    dataFetchId,
    showTableItemDescription,
    setShowTableItemDescription,
    token,
    selectedWarehouse,
}) {
    const [showHideEditForm, setShowHideEditForm] = useState(false);
    const [longData, setLongData] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const descriptionDisplay = { showTableItemDescription, showProdDescription, hideProdDescription };
    const editDisplay = { showHideEditForm, showEditForm, hideEditForm };
    const commonDataProps = { longData, targetDesc, setTargetDesc, dataFetchId, selectedWarehouse };
    const utilityProps = { errorMessage, setErrorMessage, setLoading };

    /**SKU specific fetch when targetDesc changes, dataFetchId */
    useEffect(() => {
        if (targetDesc) {
            fetch(baseURL + dataFetchId + '/' + targetDesc, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setLongData(data);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Server responded with - ' + error);
                });
        }
    }, [targetDesc, dataFetchId, token]);

    /**Set spinner when loading. */
    useEffect(() => {
        if (longData && showTableItemDescription) {
            setLoading(false);
        } else if (!longData && showTableItemDescription && !errorMessage) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [longData, showTableItemDescription, errorMessage, setLoading]);

    function showProdDescription() {
        setShowTableItemDescription(true);
    }

    function hideProdDescription(e) {
        setShowTableItemDescription(false);
        localStorage.removeItem('isDisplayed');
        localStorage.removeItem('targetDesc');
        setShowHideEditForm(false);
        setLongData('');
        setErrorMessage(null);
    }

    function showEditForm() {
        setShowHideEditForm(true);
    }

    function hideEditForm() {
        setShowHideEditForm(false);
    }

    if (!errorMessage) {
        if (dataFetchId === '/inventory_management') {
            return (
                <RenderInventoryDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        } else if (dataFetchId === '/purchase_orders') {
            return (
                <RenderPurchaseOrderDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        } else if (dataFetchId === '/warehouse_management') {
            return (
                <RenderWarehouseDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        } else if (dataFetchId === '/factory_defects') {
            return (
                <RenderFactoryDefectsDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        } else if (dataFetchId === '/factory_management') {
            return (
                <RenderFactoryDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        } else if (dataFetchId === '/variation_management') {
            return (
                <RenderVariationDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        } else if (dataFetchId === '/pricing_management') {
            return (
                <RenderPricingDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                    selectedWarehouse={commonDataProps.selectedWarehouse}
                />
            );
        } else if (dataFetchId === '/open_orders_management' || dataFetchId === '/processed_orders_management') {
            return (
                <RenderOpenOrdersDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                    selectedWarehouse={commonDataProps.selectedWarehouse}
                />
            );
        } else if (dataFetchId === '/user_management') {
            return (
                <RenderUsersDescription
                    descriptionDisplay={descriptionDisplay}
                    editDisplay={editDisplay}
                    commonDataProps={commonDataProps}
                    utilityProps={utilityProps}
                    token={token}
                />
            );
        }
        // if (dataFetchId === "/container_data"){
        //     return(
        //         <RenderContainerDescription
        //             descriptionDisplay={descriptionDisplay}
        //             editDisplay={editDisplay}
        //             commonDataProps={commonDataProps}
        //             utilityProps={utilityProps}
        //             parseJSONSku={parseJSONSku}
        //             token={token}
        //             />
        //     )
        // }
    } else {
        return <ErrorDescription descriptionDisplay={descriptionDisplay} utilityProps={utilityProps} />;
    }
}

/**RENDER ERROR MODAL FOR ERRORS */
export function ErrorDescription({ showHide, hideProdDescription, errorMessage }) {
    const showHideClassName = showHide ? 'modal display-block' : 'modal display-none';

    return (
        <>
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="productDetails">
                        <p className="modalTitle">{errorMessage}</p>
                        <br></br>
                        <br></br>
                        <ErrorMessage errorMessage={errorMessage} />
                        <button
                            key="cancel"
                            type="button"
                            onClick={(e) => hideProdDescription(e)}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                    </form>
                </td>
            </tr>
        </>
    );
}

/**RENDER DESCRIPTION MODAL FOR INVENTORY CLEAN*/
export function RenderInventoryDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [customImageUploadButtonClass, setCustomImageUploadButtonClass] = useState('customImageUploadButton');
    const [selectedActiveImage, setSelectedActiveImage] = useState('');
    const [confirmData, setConfirmData] = useState('');
    const [imageDeletionIndex, setImageDeletionIndex] = useState('');
    const [subTableFormData, setSubTableFormData] = useState(null);
    const [editTableFormData, setEditTableFormData] = useState(null);
    const [measurementStandard, setMeasurementStandard] = useState('metric');
    const [courierOptions, setCourierOptions] = useState(null);

    const subTableOptions = { courierOptions };
    const subTableCommonDataProps = {
        measurementStandard,
        setSubTableFormData,
        setShowHideConfirmBox,
        setConfirmData,
    };

    const [inputClass, setInputClass] = useState('inTableInput');
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardbutton');

    useEffect(() => {
        fetchOptions('/courier_management', token).then((response) => {
            setCourierOptions(response);
        });
    }, [token]);

    function imageHandler(e) {
        const files = e.target.files;
        setSelectedFiles(files);
        setCustomImageUploadButtonClass('customImageUploadButtonSelected');
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        if (confirmData === 'inventoryUploadImage') {
            console.log(confirmData);
            const options = {
                selectedFiles: selectedFiles,
                longData: commonDataProps.longData,
                mainId: 'sku',
                token: token,
                setCustomImageUploadButtonClass: setCustomImageUploadButtonClass,
                setLoading: utilityProps.setLoading,
                setErrorMessage: utilityProps.setErrorMessage,
                setShowHideConfirmBox: setShowHideConfirmBox,
                targetDesc: commonDataProps.targetDesc,
            };
            handleUploadImage(confirmData, options);
        } else if (confirmData === 'inventoryDeleteImage') {
            console.log(confirmData);
            const options = {
                longData: commonDataProps.longData,
                mainId: 'sku',
                token: token,
                setLoading: utilityProps.setLoading,
                setErrorMessage: utilityProps.setErrorMessage,
                setShowHideConfirmBox: setShowHideConfirmBox,
                targetDesc: commonDataProps.targetDesc,
                imageDeletionIndex: imageDeletionIndex,
            };
            handleDeleteImage(confirmData, options);
        } else if (confirmData === 'editSku') {
            console.log(confirmData);
            const options = {
                editFormData: editTableFormData,
                setEditFormData: setEditTableFormData,
                longData: commonDataProps.longData,
                token: token,
                setLoading: utilityProps.setLoading,
                setShowHideConfirmBox: setShowHideConfirmBox,
                setErrorMessage: utilityProps.setErrorMessage,
                mainId: 'sku',
                targetDesc: commonDataProps.targetDesc,
            };
            handleEdit(confirmData, options);
        } else {
            console.log(confirmData);
            const options = {
                subTableFormData: subTableFormData,
                setSubTableFormData: setSubTableFormData,
                longData: commonDataProps.longData,
                token: token,
                setLoading: utilityProps.setLoading,
                setShowHideConfirmBox: setShowHideConfirmBox,
                setErrorMessage: utilityProps.setErrorMessage,
                measurementStandard: measurementStandard,
                targetDesc: commonDataProps.targetDesc,
                mainId: 'sku',
                event: e,
            };
            handleSubmit(confirmData, options);
        }
    }

    function showConfirmDeleteImage(e, index) {
        e.preventDefault();
        setConfirmData('inventoryDeleteImage');
        setImageDeletionIndex(index);
        setShowHideConfirmBox(true);
    }

    function handleImageError(e) {
        e.target.value = 'Deleting image...';
    }

    function parseJSONImage(longDataArray) {
        if (longDataArray[0]['imagesLinks']) {
            return JSON.parse(longDataArray[0]['imagesLinks']).map((link, index) => {
                if (longDataArray[0]['imagesLinks'][index] !== null) {
                    return (
                        <div className="productImagesContainer" key={'imageContainer' + index}>
                            <img
                                id={'image' + index}
                                src={link}
                                key={'image' + index}
                                alt={'image' + index}
                                className={
                                    selectedActiveImage === 'image' + index ? 'productImagesSelected' : 'productImages'
                                }
                                onError={handleImageError}
                                onClick={setActiveImage}
                            />
                            <button
                                type="button"
                                className={selectedActiveImage === 'image' + index ? '' : 'imageDeleteButton'}
                                onClick={(e) => {
                                    showConfirmDeleteImage(e, index);
                                }}
                            >
                                X
                            </button>
                        </div>
                    );
                }
                return (
                    <div>
                        <p>Deleting image...</p>
                    </div>
                );
            });
        } else {
            return <p> No images found.</p>;
        }
    }

    function setActiveImage(e) {
        if (e.target.id === selectedActiveImage) {
            setSelectedActiveImage('');
        } else {
            setSelectedActiveImage(e.target.id);
        }
    }

    const mainTable = Array.from(['sku', 'title', 'productCategory', 'type', 'redProduct', 'factory']);

    const mainTableEdits = Array.from(['title', 'redProduct', 'productCategory', 'factory']);
    const subTables = Array.from([
        'stock',
        'cartonDimensions',
        'productDimensions',
        'altSku',
        'customProperties',
        'composition',
    ]);

    const editOptions = {
        readOnly: readOnly,
        setReadOnly: setReadOnly,
        setEditButtonClass: setEditButtonClass,
        setInputClass: setInputClass,
        confirmData: 'editSku',
        setConfirmData: setConfirmData,
        setShowHideConfirmBox: setShowHideConfirmBox,
        setEditTableFormData: setEditTableFormData,
    };

    if (commonDataProps.longData) {
        return (
            <>
                <tr className={showHideClassName} key="showHideClassName">
                    <td>
                        <form className="descriptionModal" id="productDetails" onSubmit={submitForm}>
                            <p className="modalTitle">SKU: {commonDataProps.longData[0]['sku']}</p>
                            <div className="tableDescriptionImageContainer">
                                <table className="mainDescriptionTable">
                                    <tbody>
                                        {mainTable.map((column, i) => {
                                            return (
                                                <tr key={column + 'TableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeader' + i}
                                                        name={column}
                                                        id={column}
                                                    >
                                                        {parseSQLToTitles(column) + ': '}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableValue' + i}
                                                    >
                                                        {mainTableEdits.includes(column) ? (
                                                            <input
                                                                className={inputClass}
                                                                placeholder={
                                                                    commonDataProps.longData[0][column]
                                                                        ? commonDataProps.longData[0][column]
                                                                        : 'Add line.'
                                                                }
                                                                name={column}
                                                                readOnly={readOnly}
                                                                onInput={(e) =>
                                                                    handleEditValues(e, {
                                                                        editTableFormData: editTableFormData,
                                                                        setEditTableFormData: setEditTableFormData,
                                                                    })
                                                                }
                                                            ></input>
                                                        ) : (
                                                            commonDataProps.longData[0][column]
                                                        )}
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="tableDescriptionImageDiv">
                                    {parseJSONImage(commonDataProps.longData) || 'No images added yet.'}
                                </div>
                                <div className="tableDescriptionImageButtons">
                                    <label className={customImageUploadButtonClass}>
                                        &nbsp;Select Files
                                        <input
                                            type="file"
                                            id="images"
                                            name="images"
                                            onChange={(e) => {
                                                imageHandler(e);
                                            }}
                                            multiple
                                            accept="image/*"
                                            className="standardUploadImageButton"
                                        />
                                    </label>
                                    <button
                                        id="uploadImageButton"
                                        key="uploadImageButton"
                                        type="submit"
                                        onClick={(e) => submitForm(e, 'inventoryUploadImage')}
                                        className="customImageUploadButton"
                                    >
                                        Upload Files
                                    </button>
                                    <button key="downloadImageButton" type="button" className="customImageUploadButton">
                                        Download
                                    </button>
                                </div>
                            </div>
                            <div className="cl-toggle-switch">
                                <label className="cl-switch">
                                    <input
                                        type="checkbox"
                                        onChange={() =>
                                            handleMeasurementType(measurementStandard, setMeasurementStandard)
                                        }
                                    />
                                    <span></span>
                                    <p>CM / IN</p>
                                </label>
                            </div>
                            <br></br>
                            {subTables.map((subTable, s) => {
                                return (
                                    <Fragment key={s}>
                                        <p className="modalTitle">{parseSQLToTitles(subTable)}</p>
                                        <div className="descriptionModalSubTableContainer">
                                            <SubTable
                                                commonDataProps={commonDataProps}
                                                utilityProps={utilityProps}
                                                subTableOptions={subTableOptions}
                                                subTableCommonDataProps={subTableCommonDataProps}
                                                token={token}
                                                subTableId={subTable}
                                            />
                                            {subTable.includes('Dimensions') ? (
                                                <div className="descriptionModalSubTableContainer">
                                                    <p>Always add values in cm / kg.</p>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <br></br>
                                    </Fragment>
                                );
                            })}
                            <ButtonContainer
                                showConfirmText="/inventory_management_delete/"
                                setSubTableFormData={subTableCommonDataProps.setSubTableFormData}
                                hideDescription={descriptionDisplay.hideProdDescription}
                                setShowHideConfirmBox={setShowHideConfirmBox}
                                editOptions={editOptions}
                            />
                            <ConfirmBox
                                showHideConfirmBox={showHideConfirmBox}
                                setShowHideConfirmBox={setShowHideConfirmBox}
                                submitForm={submitForm}
                                formName="productDetails"
                                confirmData={confirmData}
                                key="confirmBox"
                                subTableFormData={subTableFormData}
                                editTableFormData={editTableFormData}
                                longData={commonDataProps.longData}
                            />
                        </form>
                    </td>
                </tr>
            </>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR PURCHASE ORDERS STOPPED CLEAN*/
export function RenderPurchaseOrderDescription({
    descriptionDisplay,
    editDisplay,
    commonDataProps,
    utilityProps,
    token,
}) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [subTableFormData, setSubTableFormData] = useState(null);
    const [imageLinks, setImageLinks] = useState([]);
    const [courierOptions, setCourierOptions] = useState(null);

    const subTableOptions = { courierOptions };
    const subTableCommonDataProps = { setSubTableFormData, formId: 'poStockInfo' };

    useEffect(() => {
        if (commonDataProps.longData[0] && commonDataProps.longData[0]['poStockInfo']) {
            const tempArray = JSON.parse(commonDataProps.longData[0]['poStockInfo']).map((row, i) => {
                return row['sku'];
            });

            if (tempArray.length !== 0 && descriptionDisplay.showTableItemDescription) {
                const queryArray = `?data=${encodeURIComponent(JSON.stringify(tempArray))}`;

                fetch(`${baseURL}/fetch_lead_images${queryArray}`, {
                    credentials: 'include',
                    method: 'GET',
                    headers: new Headers({
                        'Access-Control-Allow-Origin': '*',
                        Authorization: 'Bearer ' + token,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setImageLinks(data);
                    })
                    .catch((error) => {
                        utilityProps.setErrorMessage('Server responded with - ' + error);
                    });
            }
        }
    }, [commonDataProps.longData, descriptionDisplay.showTableItemDescription]);

    useEffect(() => {
        fetchOptions('/courier_management', token).then((response) => {
            setCourierOptions(response);
        });
    }, [token]);

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'poNumber',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    function renderImages() {
        let tempArray = imageLinks;
        tempArray = tempArray.map((leadImage, i) => {
            if (leadImage) {
                return (
                    <img
                        className="productImagesNoZoom"
                        src={leadImage}
                        key={'leadImage' + i}
                        alt={'leadImage' + i}
                    ></img>
                );
            } else {
                return (
                    <img
                        className="noLeadImage"
                        key={'noLeadImage' + (i + 1)}
                        alt={'Missing lead image item line: ' + (i + 1)}
                    ></img>
                );
            }
        });

        return tempArray;
    }

    function checkDelivered() {
        if (commonDataProps.longData[0]['status'] === 'Pending') {
            return (
                <>
                    <button
                        key="deleteContainer"
                        type="button"
                        onClick={(e) => showConfirm(e, '/purchase_orders_delete/')}
                        className="standardButton"
                    >
                        Delete PO
                    </button>
                    <button
                        key="deliverPo"
                        type="button"
                        onClick={(e) => showConfirm(e, 'deliverPo')}
                        className="standardButton"
                    >
                        Deliver PO
                    </button>
                </>
            );
        } else if (commonDataProps.longData[0]['status'] === 'Delivered') {
            return (
                <>
                    <button key="deleteContainer" type="button" className="disabledButton" disabled>
                        Delete PO
                    </button>
                    <button key="deliverPo" type="button" className="disabledButton" disabled>
                        Delivered
                    </button>
                </>
            );
        }
    }

    function showConfirm(e, subTableId) {
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    const mainTable = Array.from([
        'poNumber',
        'supplierReferenceNumber',
        'dateAdded',
        'status',
        'factoryName',
        'warehouse',
        'total',
    ]);

    const subTables = Array.from(['poStockInfo']);

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="poStockInfo" onSubmit={submitForm}>
                        <p className="modalTitle" key="modalTitle">
                            Supplier Reference Number: {commonDataProps.longData[0]['supplierReferenceNumber']}
                        </p>
                        <div className="tableDescriptionImageContainer" key="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable">
                                <tbody>
                                    {mainTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="mainDescriptionTableTitle"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {column === 'total'
                                                        ? parseSQLToTitles(column) + ' ($): '
                                                        : parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    {commonDataProps.longData[0][column]}
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="tableDescriptionImageDiv">
                                <div className="productImagesContainer">{renderImages()}</div>
                            </div>
                        </div>
                        <br></br>
                        {subTables.map((subTable, s) => {
                            return (
                                <Fragment key={s}>
                                    <p className="modalTitle">{parseSQLToTitles(subTable)}</p>
                                    <div className="descriptionModalSubTableContainer">
                                        <SubTable
                                            commonDataProps={commonDataProps}
                                            utilityProps={utilityProps}
                                            subTableOptions={subTableOptions}
                                            subTableCommonDataProps={subTableCommonDataProps}
                                            token={token}
                                            subTableId={subTable}
                                        />
                                        {subTable !== 'stock' ? (
                                            <ClearAndSave confirmSubmit={showConfirm} subTableId={subTable} />
                                        ) : (
                                            <></>
                                        )}
                                        {subTable.includes('Dimensions') ? (
                                            <div className="descriptionModalSubTableContainer">
                                                <p>Always add values in cm / kg.</p>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <br></br>
                                </Fragment>
                            );
                        })}
                        <div className="buttonContainer">
                            {checkDelivered()}
                            <button
                                key="cancel"
                                type="button"
                                onClick={descriptionDisplay.hideProdDescription}
                                className="standardButton"
                            >
                                Cancel
                            </button>
                        </div>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="purchaseOrders"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={subTableFormData}
                            longData={commonDataProps.longData}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR WAREHOUSE CLEAN*/
export function RenderWarehouseDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [subTableFormData, setSubTableFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardButton');
    const [inputClass, setInputClass] = useState('inTableInput');

    const subTableOptions = {};
    const subTableCommonDataProps = { setSubTableFormData, formId: 'warehouseDetails' };

    function editOn(e) {
        if (readOnly) {
            setReadOnly(false);
            setEditButtonClass('standardButtonTriggered');
            setInputClass('inTableInputTriggered');
        } else {
            setReadOnly(true);
            setEditButtonClass('standardButton');
            showConfirm(e, 'warehouseDetails');
        }
    }

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function showConfirm(e, subTableId) {
        e.preventDefault();
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function handleEditForm(e) {
        let tempArray = subTableFormData;
        let tempKey = e.target.name;
        let tempValue = e.target.value;
        tempArray[tempKey] = tempValue;

        setSubTableFormData(tempArray);
        console.log(tempArray);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'warehouseName',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    const subTables = Array.from(['warehouseContacts']);

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="warehouseDetails" onSubmit={submitForm}>
                        <p className="modalTitle">Warehouse: {commonDataProps.longData[0]['warehouseName']}</p>
                        <div className="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable" key="factoryAddressTable">
                                <tbody>
                                    <tr key="warehouseNameKey">
                                        <th
                                            className="mainDescriptionTableTitle"
                                            key="warehouseyDescriptionTableHeaderFactoryName"
                                            name="warehouseName"
                                            id="warehouseName"
                                        >
                                            Warehouse:
                                        </th>
                                        <th
                                            className="mainDescriptionTableEntry"
                                            key="warehouseDescriptionTableValueWarehouseName"
                                        >
                                            &nbsp;{commonDataProps.longData[0]['warehouseName']}
                                        </th>
                                    </tr>
                                    {addressHeaders.map((header, i) => {
                                        return (
                                            <tr key={header + '_warehouse_' + i}>
                                                <th
                                                    key={header + 'WarehouseDescriptionTableHeader' + i}
                                                    name={header}
                                                    id={header}
                                                    className="mainDescriptionTableTitle"
                                                >
                                                    {parseSQLToTitles(header)}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={header + 'WarehouseDescriptionTableValue' + i}
                                                >
                                                    <input
                                                        key={header + 'WarehouseDescriptionTableValue' + i}
                                                        className={inputClass}
                                                        placeholder={
                                                            commonDataProps.longData[0][header]
                                                                ? commonDataProps.longData[0][header]
                                                                : 'Add line.'
                                                        }
                                                        name={header}
                                                        onChange={(e) => handleEditForm(e)}
                                                        readOnly={readOnly}
                                                    ></input>
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        {subTables.map((subTable, s) => {
                            return (
                                <Fragment key={s}>
                                    <p className="modalTitle">{parseSQLToTitles(subTable)}</p>
                                    <div className="descriptionModalSubTableContainer">
                                        <SubTable
                                            commonDataProps={commonDataProps}
                                            utilityProps={utilityProps}
                                            subTableOptions={subTableOptions}
                                            subTableCommonDataProps={subTableCommonDataProps}
                                            token={token}
                                            subTableId={subTable}
                                        />
                                        {subTable !== 'stock' ? (
                                            <ClearAndSave confirmSubmit={(e) => showConfirm(e, subTable)} />
                                        ) : (
                                            <></>
                                        )}
                                        {subTable.includes('Dimensions') ? (
                                            <div className="descriptionModalSubTableContainer">
                                                <p>Always add values in cm / kg.</p>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <br></br>
                                </Fragment>
                            );
                        })}
                        <div className="buttonContainer">
                            <button
                                key="editWarehouse"
                                type="button"
                                className={editButtonClass}
                                onClick={(e) => editOn(e)}
                            >
                                Edit Warehouse
                            </button>
                            <button
                                key="deleteWarehouse"
                                type="button"
                                className="standardButton"
                                onClick={(e) => showConfirm(e, '/factory_management_delete/')}
                            >
                                Delete Warehouse
                            </button>
                            <button
                                key="cancel"
                                type="button"
                                onClick={descriptionDisplay.hideProdDescription}
                                className="standardButton"
                            >
                                Cancel
                            </button>
                        </div>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="warehouseDetails"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={subTableFormData}
                            longData={commonDataProps.longData}
                            editOn={editOn}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR FACTORY DEFECTS */
export function RenderFactoryDefectsDescription({
    descriptionDisplay,
    editDisplay,
    commonDataProps,
    utilityProps,
    token,
}) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [subTableFormData, setSubTableFormData] = useState(null);

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function showConfirm(e, subTableId) {
        e.preventDefault();
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'id',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    const mainTable = Array.from([
        'date',
        'orderId',
        'productSku',
        'description',
        'replacementSku',
        'replacementQuantity',
    ]);

    const secondTable = Array.from(['channelName', 'claimAmount', 'claimType', 'factory', 'courier']);

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="containerDiscModalMainTr">
                <td>
                    <form className="descriptionModal" id="factoryDetails" onSubmit={submitForm}>
                        <p className="modalTitle">
                            Factory Defect Record: {commonDataProps.longData[0]['id']},{' '}
                            {commonDataProps.longData[0]['orderId']}
                        </p>
                        <div className="tableDescriptionImageContainer" key="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable">
                                <tbody>
                                    {mainTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="subTableTableHeader"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    {commonDataProps.longData[0][column]}
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <table className="mainDescriptionTable">
                                <tbody>
                                    {secondTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="subTableTableHeader"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    {commonDataProps.longData[0][column]}
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <p className="modalTitle">Images</p>
                        <br></br>
                        <div className="tableDescriptionImageContainer">
                            {JSON.parse(commonDataProps.longData[0]['imagesLinks']).length ? (
                                JSON.parse(commonDataProps.longData[0]['imagesLinks']).map((link, l) => {
                                    console.log(link);
                                    return (
                                        <img
                                            src={link}
                                            key={'image' + l}
                                            alt={'image' + l}
                                            className="factoryDefectImages"
                                        />
                                    );
                                })
                            ) : (
                                <p>No images available.</p>
                            )}
                        </div>
                        <br></br>
                        <button
                            key="cancel"
                            type="button"
                            onClick={descriptionDisplay.hideProdDescription}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                        <button
                            key="deleteContainer"
                            type="button"
                            onClick={(e) => showConfirm(e, '/delete_factory_defect/')}
                            className="standardButton"
                        >
                            Delete record
                        </button>
                        <ConfirmBox
                            formName="factoryDetails"
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            key="confirmBox"
                            longData={commonDataProps.longData}
                            confirmData={confirmData}
                            subTableFormData={subTableFormData}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR FACTORY CLEAN*/
export function RenderFactoryDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [editFormData, setEditFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardButton');
    const [inputClass, setInputClass] = useState('inTableInput');
    const [subTableFormData, setSubTableFormData] = useState(null);

    function editOn(e) {
        if (readOnly) {
            setReadOnly(false);
            setEditButtonClass('standardButtonTriggered');
            setInputClass('inTableInputTriggered');
        } else {
            setReadOnly(true);
            setEditButtonClass('standardButton');
            setConfirmData('factoryDetails');
            setInputClass('inTableInput');
            setShowHideConfirmBox(true);
        }
    }

    function handleEditForm(e) {
        let tempArray = editFormData;
        let tempKey = e.target.name;
        let tempValue = e.target.value;
        tempArray[tempKey] = tempValue;

        setSubTableFormData(tempArray);
        console.log(tempArray);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'factoryName',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    function showConfirmAddItems(e, subTableId) {
        e.preventDefault();
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    const mainTable = Array.from([
        'factoryName',
        'catalogue',
        'country',
        'defectRate',
        'historicalDefectRate',
        'supplierType',
    ]);

    const addressTable = Array.from(['unitNumber', 'addressLine1', 'addressLine2', 'city', 'postalCode', 'state']);

    const contactTable = Array.from(['contactName', 'contactEmail', 'contactPhone']);

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="factoryDetails" onSubmit={submitForm}>
                        <p className="modalTitle">Factory: {commonDataProps.longData[0]['factoryName']}</p>
                        <div className="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable" key="factoryDescriptionTable">
                                <tbody>
                                    {mainTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="subTableTableHeader"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {column === 'total'
                                                        ? parseSQLToTitles(column) + ' ($): '
                                                        : parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    {commonDataProps.longData[0][column] ? (
                                                        commonDataProps.longData[0][column]
                                                    ) : column === 'catalogue' ? (
                                                        <input
                                                            key="factoryDescriptionTableValueCatalogue"
                                                            className={inputClass}
                                                            placeholder={
                                                                commonDataProps.longData[0]['catalogue']
                                                                    ? commonDataProps.longData[0]['catalogue']
                                                                    : ' Add catalogue link.'
                                                            }
                                                            name="catalogue"
                                                            onChange={(e) => handleEditForm(e)}
                                                            readOnly={readOnly}
                                                        ></input>
                                                    ) : (
                                                        '0 %'
                                                    )}
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <table className="mainDescriptionTable" key="factoryAddressTable">
                                <tbody>
                                    {addressTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="subTableTableHeader"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {column === 'total'
                                                        ? parseSQLToTitles(column) + ' ($): '
                                                        : parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    <input
                                                        key={'tableDescriptionTableEntry' + i}
                                                        className={inputClass}
                                                        placeholder={
                                                            commonDataProps.longData[0][column]
                                                                ? commonDataProps.longData[0][column]
                                                                : 'Add line.'
                                                        }
                                                        name={column}
                                                        onChange={(e) => handleEditForm(e)}
                                                        readOnly={readOnly}
                                                    ></input>
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <table className="mainDescriptionTable" key="factoryContactTable">
                                <tbody>
                                    {contactTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="subTableTableHeader"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {column === 'total'
                                                        ? parseSQLToTitles(column) + ' ($): '
                                                        : parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    <input
                                                        key={'tableDescriptionTableEntry' + i}
                                                        className={inputClass}
                                                        placeholder={
                                                            commonDataProps.longData[0][column]
                                                                ? commonDataProps.longData[0][column]
                                                                : 'Add line.'
                                                        }
                                                        name={column}
                                                        onChange={(e) => handleEditForm(e)}
                                                        readOnly={readOnly}
                                                    ></input>
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        <p className="modalTitle">Monthly Defect Rate</p>
                        <div className="descriptionModalSubTableContainer">
                            {/* <SubTable
                                longData={commonDataProps.longData}
                                subTableId="stock"
                                thEditClass={stockInfoThEditClass}
                                inTableInputClass={stockInfoInTableInputClass}
                                readOnly={stockInfoReadOnly}
                                parseStockType={parseStockType}
                                setSubTableFormData={setSubTableFormData}
                                token={token}
                            /> */}
                            Pie chart with defect rate
                        </div>
                        <br></br>
                        <p className="modalTitle">Historical Defect Rate</p>
                        <div className="descriptionModalSubTableContainer">Pie chart with historical defect rate</div>
                        <br></br>
                        <button
                            key="cancel"
                            type="button"
                            onClick={descriptionDisplay.hideProdDescription}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                        <button
                            key="deleteFactory"
                            type="button"
                            className="standardButton"
                            onClick={(e) => showConfirmAddItems(e, 'delete')}
                        >
                            Delete Factory
                        </button>
                        <button key="editFactory" type="button" className={editButtonClass} onClick={(e) => editOn(e)}>
                            Edit Factory
                        </button>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="factoryDetails"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={editFormData}
                            longData={commonDataProps.longData}
                            editOn={editOn}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR VARIATION CLEAN*/
export function RenderVariationDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [editFormData, setEditFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardButton');
    const [inputClass, setInputClass] = useState('inTableInput');
    const [subTableFormData, setSubTableFormData] = useState(null);

    const subTableOptions = {};
    const subTableCommonDataProps = { setSubTableFormData };

    const mainTable = Array.from(['variationGroupSku', 'variationGroupType', 'variationGroupTitle']);

    function handleEditForm(e) {
        let tempArray = editFormData;
        let tempKey = e.target.name;
        let tempValue = e.target.value;
        tempArray[tempKey] = tempValue;

        setSubTableFormData(tempArray);
        console.log(tempArray);
    }

    function editOn(e) {
        if (readOnly) {
            setReadOnly(false);
            setEditButtonClass('standardButtonTriggered');
            setInputClass('inTableInputTriggered');
        } else {
            setReadOnly(true);
            setEditButtonClass('standardButton');
            setConfirmData('editVariation');
            setInputClass('inTableInput');
            setShowHideConfirmBox(true);
        }
    }

    function showConfirm(e, subTableId) {
        e.preventDefault();
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'variationGroupSku',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="variationDetails" onSubmit={submitForm}>
                        <p className="modalTitle">
                            Variation Group SKU: {commonDataProps.longData[0]['variationGroupSku']}
                        </p>
                        <div className="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable" key="factoryDescriptionTable">
                                <tbody>
                                    {mainTable.map((column, i) => {
                                        return (
                                            <tr key={column + 'TableHeader' + i}>
                                                <th
                                                    className="subTableTableHeader"
                                                    key={'tableDescriptionTableHeader' + i}
                                                    name={column}
                                                    id={column}
                                                >
                                                    {column === 'total'
                                                        ? parseSQLToTitles(column) + ' ($): '
                                                        : parseSQLToTitles(column) + ': '}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableValue' + i}
                                                >
                                                    {commonDataProps.longData[0][column] ? (
                                                        <input
                                                            key={'factoryDescriptionTableValue' + i}
                                                            className={inputClass}
                                                            placeholder={
                                                                commonDataProps.longData[0][column]
                                                                    ? commonDataProps.longData[0][column]
                                                                    : null
                                                            }
                                                            name={column}
                                                            onChange={(e) => handleEditForm(e)}
                                                            readOnly={
                                                                column !== 'variationGroupTitle' ? true : readOnly
                                                            }
                                                        ></input>
                                                    ) : null}
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        <p className="modalTitle">Variation Group Components</p>
                        <div className="descriptionModalSubTableContainer">
                            <SubTable
                                commonDataProps={commonDataProps}
                                utilityProps={utilityProps}
                                subTableOptions={subTableOptions}
                                subTableCommonDataProps={subTableCommonDataProps}
                                token={token}
                                subTableId="variationGroupComponents"
                            />
                        </div>
                        <div className="editButtonHolder" key="cartonDimensionsButtons">
                            <button
                                key="editVariationDetailsClear"
                                type="button"
                                className="editButton"
                                name="variationDetails"
                                onClick={(e) => handleInfoClear(e)}
                            >
                                Clear Input
                            </button>
                            <button
                                key="editVariationDetailsSave"
                                type="button"
                                className="editButton"
                                onClick={(e) => showConfirm(e, 'variationDetails')}
                            >
                                Save Changes
                            </button>
                        </div>
                        <button
                            key="cancel"
                            type="button"
                            onClick={descriptionDisplay.hideProdDescription}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                        <button
                            key="deleteFactory"
                            type="button"
                            className="standardButton"
                            onClick={(e) => showConfirm(e, '/variation_management_delete/')}
                        >
                            Delete Variation
                        </button>
                        <button key="editFactory" type="button" className={editButtonClass} onClick={(e) => editOn(e)}>
                            Edit Variation
                        </button>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="editVariation"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={editFormData}
                            longData={commonDataProps.longData}
                            editOn={editOn}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR PRICING */
export function RenderPricingDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'modal display-block'
        : 'modal display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [editFormData, setEditFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardButton');
    const [inputClass, setInputClass] = useState('inTableInput');
    const [subTableFormData, setSubTableFormData] = useState(null);
    const [cartonDimensions, setCartonDimensions] = useState({});

    const subTableCommonDataProps = { setSubTableFormData, formId: 'pricingDetails' };

    function handleEditForm(e) {
        let tempArray = editFormData;
        let tempKey = e.target.name;
        let tempValue = e.target.value;
        tempArray[tempKey] = tempValue;

        setSubTableFormData(tempArray);
        console.log(tempArray);
    }

    function editOn(e) {
        if (readOnly) {
            setReadOnly(false);
            setEditButtonClass('standardButtonTriggered');
            setInputClass('inTableInputTriggered');
        } else {
            setReadOnly(true);
            setEditButtonClass('standardButton');
            setConfirmData('editVariation');
            setInputClass('inTableInput');
            setShowHideConfirmBox(true);
        }
    }

    function showConfirm(e, subTableId) {
        e.preventDefault();
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData:
                confirmData === 'sellingPrice' || confirmData === 'shippingService' ? subTableFormData : editFormData,
            setSubTableFormData:
                confirmData === 'sellingPrice' || confirmData === 'shippingService'
                    ? setSubTableFormData
                    : setEditFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'sku',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    useEffect(() => {
        if (commonDataProps.longData[0]) {
            setCartonDimensions(JSON.parse(commonDataProps.longData[0]['cartonDimensions']));
        }
    }, [commonDataProps.longData]);

    // let cartonDimensions = longData[0] ? JSON.parse(longData[0]["cartonDimensions"]) : null
    // let keys = cartonDimensions ? Object.keys(cartonDimensions) : []
    // let cbm = keys.length === 4 ? (cartonDimensions["Length"]*cartonDimensions["Height"]*cartonDimensions["Width"])/10000000 : "Add carton dimensions."

    const subTables = Array.from(['sellingPrice', 'shippingService']);

    const cartonDimensionsList = Array.from(['Width', 'Height', 'Length', 'Weight']);

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="pricingDetails" onSubmit={submitForm}>
                        <p className="modalTitle">Pricing: {commonDataProps.longData[0]['sku']}</p>
                        <div className="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable" key="factoryDescriptionTable">
                                <tbody>
                                    <tr key={'rowTableHeader1'}>
                                        <th
                                            className="subTableTableHeader"
                                            key={'tableDescriptionTableHeaderRow1Column1'}
                                            name={'skuHeader'}
                                            id={'skuHeader'}
                                        >
                                            SKU
                                        </th>
                                        <th
                                            className="mainDescriptionTableEntry"
                                            key={'tableDescriptionTableHeaderRow1Column2'}
                                        >
                                            <input
                                                key={'factoryDescriptionTableValue1_2'}
                                                className={inputClass}
                                                placeholder={
                                                    commonDataProps.longData[0]['sku']
                                                        ? commonDataProps.longData[0]['sku']
                                                        : null
                                                }
                                                name={'sku'}
                                                onChange={(e) => handleEditForm(e)}
                                                readOnly={readOnly}
                                            ></input>
                                        </th>
                                    </tr>
                                    {cartonDimensions
                                        ? cartonDimensionsList.map((key, k) => {
                                              if (Object.keys(cartonDimensions).includes(key)) {
                                                  return (
                                                      <tr key={key + 'TableHeader' + k}>
                                                          <th
                                                              className="subTableTableHeader"
                                                              key={'tableDescriptionTableHeader' + key + k}
                                                              name={key}
                                                              id={key}
                                                          >
                                                              {key === 'Weight' ? key + ' (kg)' : key + ' (cm)'}
                                                          </th>
                                                          <th
                                                              className="mainDescriptionTableEntry"
                                                              key={'tableDescriptionTableValue' + key + k}
                                                          >
                                                              <input
                                                                  key={'factoryDescriptionTableValue' + key + k}
                                                                  className={inputClass}
                                                                  placeholder={cartonDimensions[key]}
                                                                  name={key}
                                                                  onChange={(e) => handleEditForm(e)}
                                                                  readOnly={readOnly}
                                                              ></input>
                                                          </th>
                                                      </tr>
                                                  );
                                              } else {
                                                  return (
                                                      <tr key={key + 'TableHeader' + k}>
                                                          <th
                                                              className="subTableTableHeader"
                                                              key={'tableDescriptionTableHeader' + key + k}
                                                              name={key}
                                                              id={key}
                                                          >
                                                              {key === 'Weight' ? key + ' (kg)' : key + ' (cm)'}
                                                          </th>
                                                          <th
                                                              className="mainDescriptionTableEntry"
                                                              key={'tableDescriptionTableValue' + key + k}
                                                          >
                                                              <input
                                                                  key={'factoryDescriptionTableValue' + key + k}
                                                                  className={inputClass}
                                                                  placeholder={'Dimension not added yet.'}
                                                                  name={key}
                                                                  onChange={(e) => handleEditForm(e)}
                                                                  readOnly={readOnly}
                                                              ></input>
                                                          </th>
                                                      </tr>
                                                  );
                                              }
                                          })
                                        : ''}
                                    {
                                        <tr key={'rowTableHeader5'}>
                                            <th
                                                className="subTableTableHeader"
                                                key={'tableDescriptionTableHeaderRow5Column1'}
                                                name={'cbmHeader'}
                                                id={'cbmHeader'}
                                            >
                                                CBM (m{<sup>3</sup>})
                                            </th>
                                            <th
                                                className="mainDescriptionTableEntry"
                                                key={'tableDescriptionTableHeaderRow5Column2'}
                                            >
                                                <input
                                                    key={'factoryDescriptionTableValue5_2'}
                                                    className={inputClass}
                                                    placeholder={calcCBM(cartonDimensions)}
                                                    name={'cbm'}
                                                    onChange={(e) => handleEditForm(e)}
                                                    readOnly={readOnly}
                                                ></input>
                                            </th>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        <br></br>
                        {subTables.map((subTable, s) => {
                            return (
                                <>
                                    <p className="modalTitle">{parseSQLToTitles(subTable)}</p>
                                    <div className="descriptionModalSubTableContainer">
                                        <SubTable
                                            commonDataProps={commonDataProps}
                                            utilityProps={utilityProps}
                                            subTableCommonDataProps={subTableCommonDataProps}
                                            token={token}
                                            subTableId={subTable}
                                        />
                                        <ClearAndSave confirmSubmit={(e) => showConfirm(e, subTable)} />
                                    </div>
                                    <br></br>
                                </>
                            );
                        })}
                        <button
                            key="cancel"
                            type="button"
                            onClick={descriptionDisplay.hideProdDescription}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="editVariation"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={editFormData}
                            longData={commonDataProps.longData}
                            editOn={editOn}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

/**RENDER DESCRIPTION MODAL FOR OPEN ORDERS */
export function RenderOpenOrdersDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [editFormData, setEditFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardButton');
    const [inputClass, setInputClass] = useState('inTableInput');
    const [subTableFormData, setSubTableFormData] = useState(null);
    const [editDisabled, setEditDisabled] = useState(true);
    const [userList, setUserList] = useState('');

    const subTableCommonDataProps = { setSubTableFormData, formId: 'pricingDetails' };

    function handleEditForm(e) {
        let tempArray = subTableFormData;
        let tempKey = e.target.name;
        let tempValue = e.target.value;
        tempArray[tempKey] = tempValue;

        setSubTableFormData(tempArray);
        console.log(tempArray);
    }

    function editOn(e) {
        if (readOnly) {
            setReadOnly(false);
            setEditButtonClass('standardButtonTriggered');
            setInputClass('inTableInputTriggered');
            setEditDisabled(false);
        } else {
            setReadOnly(true);
            setEditButtonClass('standardButton');
            setConfirmData('editOpenOrder');
            setInputClass('inTableInput');
            setShowHideConfirmBox(true);
            setEditDisabled(true);
        }
    }

    function showConfirm(e, subTableId) {
        e.preventDefault();
        console.log(subTableId);
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'orderId',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    let firstTableHeaders = Array.from([
        'orderId',
        'date',
        'channelName',
        'status',
        'paidStatus',
        'paymentMethod',
        'warehouse',
        'total',
    ]);

    let secondTableHeaders = Array.from(['customerName', 'address', 'tracking', 'customerReference']);

    let firstTableEdits = Array.from(['status', 'paidStatus', 'paymentMethod']);

    let thirdTableHeaders = Array.from(['createdBy', 'managedBy']);

    let thirdTableEdits = Array.from(['createdBy', 'managedBy']);

    const subTables = Array.from(['orderItems']);

    useEffect(() => {
        fetchOptions('/user_management', token).then((response) => {
            setUserList(response);
        });
    }, [token]);

    function returnDropdownValues(header) {
        let tempDropdownArray = Array.from(['']);
        if (header === 'status') {
            tempDropdownArray = Array.from(['Open', 'Processed']);
        } else if (header === 'paidStatus') {
            tempDropdownArray = Array.from(['Paid', 'Deposit Paid', 'Unpaid']);
        } else if (header === 'paymentMethod') {
            tempDropdownArray = Array.from(['PayPal', 'Direct Transfer']);
        } else if (header === 'status') {
            tempDropdownArray = Array.from(['Open', 'Processed']);
        }
        return tempDropdownArray;
    }

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="pricingDetails" onSubmit={submitForm}>
                        <p className="modalTitle">Order ID: {commonDataProps.longData[0]['orderId']}</p>
                        <div className="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable" key="orderIdDescriptionTable1">
                                <tbody>
                                    {firstTableHeaders.map((header, i) => {
                                        //if header is in first Table Edits, then return a dropdown, instead of input
                                        if (firstTableEdits.includes(header)) {
                                            return (
                                                <tr key={'rowTableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeaderRowKey' + i}
                                                        name={header}
                                                        id={header}
                                                    >
                                                        {parseSQLToTitles(header)}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableHeaderRowValue' + i}
                                                    >
                                                        <select
                                                            name={header}
                                                            onChange={(e) => handleEditForm(e)}
                                                            className={inputClass}
                                                        >
                                                            {returnDropdownValues(header).map((option, k) => {
                                                                if (option === commonDataProps.longData[0][header]) {
                                                                    return (
                                                                        <option key={'option_' + i + k} selected>
                                                                            {option}
                                                                        </option>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <option
                                                                            key={'option_' + i + k}
                                                                            disabled={editDisabled}
                                                                        >
                                                                            {option}
                                                                        </option>
                                                                    );
                                                                }
                                                            })}
                                                        </select>
                                                    </th>
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                <tr key={'rowTableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeaderRowKey' + i}
                                                        name={header}
                                                        id={header}
                                                    >
                                                        {parseSQLToTitles(header)}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableHeaderRowValue' + i}
                                                    >
                                                        <input
                                                            className={inputClass}
                                                            placeholder={
                                                                commonDataProps.longData[0][header]
                                                                    ? commonDataProps.longData[0][header]
                                                                    : null
                                                            }
                                                            name={header}
                                                            onChange={(e) => handleEditForm(e)}
                                                            readOnly
                                                        ></input>
                                                    </th>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                            <table className="mainDescriptionTable" key="orderIdDescriptionTable2">
                                <tbody>
                                    {secondTableHeaders.map((header, i) => {
                                        return (
                                            <tr key={'rowTableHeader' + i}>
                                                <th
                                                    className="mainDescriptionTableTitle"
                                                    key={'tableDescriptionTableHeaderRowKey' + i}
                                                    name={header}
                                                    id={header}
                                                >
                                                    {parseSQLToTitles(header)}
                                                </th>
                                                <th
                                                    className="mainDescriptionTableEntry"
                                                    key={'tableDescriptionTableHeaderRowValue' + i}
                                                >
                                                    <input
                                                        className={inputClass}
                                                        placeholder={
                                                            commonDataProps.longData[0][header]
                                                                ? commonDataProps.longData[0][header]
                                                                : null
                                                        }
                                                        defaultValue={
                                                            commonDataProps.longData[0][header]
                                                                ? commonDataProps.longData[0][header]
                                                                : null
                                                        }
                                                        name={header}
                                                        onChange={(e) => handleEditForm(e)}
                                                        readOnly={readOnly}
                                                    ></input>
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <table className="mainDescriptionTable" key="orderIdDescriptionTable3">
                                <tbody>
                                    {thirdTableHeaders.map((header, i) => {
                                        if (thirdTableEdits.includes(header)) {
                                            return (
                                                <tr key={'rowTableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeaderRowKey' + i}
                                                        name={header}
                                                        id={header}
                                                    >
                                                        {parseSQLToTitles(header)}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableHeaderRowValue' + i}
                                                    >
                                                        <select
                                                            name={header}
                                                            onChange={(e) => handleEditForm(e)}
                                                            className={inputClass}
                                                        >
                                                            {userList
                                                                ? userList.map((userRow, u) => {
                                                                      let fullName =
                                                                          userRow['firstName'] +
                                                                          ' ' +
                                                                          userRow['lastName'];
                                                                      if (
                                                                          fullName ===
                                                                          commonDataProps.longData[0][header]
                                                                      ) {
                                                                          return (
                                                                              <option key={'option_' + i + u} selected>
                                                                                  {fullName}
                                                                              </option>
                                                                          );
                                                                      } else {
                                                                          return (
                                                                              <option
                                                                                  key={'option_' + i + u}
                                                                                  disabled={editDisabled}
                                                                              >
                                                                                  {fullName}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  })
                                                                : ''}
                                                        </select>
                                                    </th>
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                <tr key={'rowTableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeaderRowKey' + i}
                                                        name={header}
                                                        id={header}
                                                    >
                                                        {parseSQLToTitles(header)}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableHeaderRowValue' + i}
                                                    >
                                                        <input
                                                            className={inputClass}
                                                            placeholder={
                                                                commonDataProps.longData[0][header]
                                                                    ? commonDataProps.longData[0][header]
                                                                    : null
                                                            }
                                                            name={header}
                                                            onChange={(e) => handleEditForm(e)}
                                                            readOnly
                                                        ></input>
                                                    </th>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        {subTables.map((subTable, s) => {
                            return (
                                <Fragment key={s}>
                                    <p className="modalTitle">{parseSQLToTitles(subTable)}</p>
                                    <div className="descriptionModalSubTableContainer">
                                        <SubTable
                                            commonDataProps={commonDataProps}
                                            utilityProps={utilityProps}
                                            subTableCommonDataProps={subTableCommonDataProps}
                                            token={token}
                                            subTableId={subTable}
                                        />
                                        <ClearAndSave confirmSubmit={(e) => showConfirm(e, subTable)} />
                                    </div>
                                    <br></br>
                                </Fragment>
                            );
                        })}
                        <button
                            key="cancel"
                            type="button"
                            onClick={descriptionDisplay.hideProdDescription}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                        <button
                            key="editOrder"
                            type="button"
                            className={
                                commonDataProps.dataFetchId === '/open_orders_management'
                                    ? editButtonClass
                                    : 'disabledButton'
                            }
                            onClick={(e) => editOn(e)}
                            disabled={commonDataProps.dataFetchId !== '/open_orders_management'}
                        >
                            Edit Order
                        </button>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="editVariation"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={subTableFormData}
                            editTableFormData={editFormData}
                            longData={commonDataProps.longData}
                            editOn={editOn}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

// /**RENDER DESCRIPTION MODAL FOR USERS */
export function RenderUsersDescription({ descriptionDisplay, editDisplay, commonDataProps, utilityProps, token }) {
    const showHideClassName = descriptionDisplay.showTableItemDescription
        ? 'descriptionModalContainer display-block'
        : 'descriptionModalContainer display-none';
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [editFormData, setEditFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [editButtonClass, setEditButtonClass] = useState('standardButton');
    const [inputClass, setInputClass] = useState('inTableInput');
    const [subTableFormData, setSubTableFormData] = useState(null);
    const [editDisabled, setEditDisabled] = useState(true);

    function handleEditForm(e) {
        let tempArray = editFormData;
        let tempKey = e.target.name;
        let tempValue = e.target.value;
        tempArray[tempKey] = tempValue;

        setSubTableFormData(tempArray);
        console.log(tempArray);
    }

    function editOn(e) {
        if (readOnly) {
            setReadOnly(false);
            setEditButtonClass('standardButtonTriggered');
            setInputClass('inTableInputTriggered');
            setEditDisabled(false);
        } else {
            setReadOnly(true);
            setEditButtonClass('standardButton');
            setConfirmData('editUserManagement');
            setInputClass('inTableInput');
            setShowHideConfirmBox(true);
            setEditDisabled(true);
        }
    }

    function showConfirm(e, subTableId) {
        e.preventDefault();
        console.log(subTableId);
        setConfirmData(subTableId);
        displayShowHideConfirmBox();
    }

    function displayShowHideConfirmBox() {
        setShowHideConfirmBox(true);
    }

    function submitForm(e, confirmData) {
        utilityProps.setLoading(true);
        const options = {
            subTableFormData: subTableFormData,
            setSubTableFormData: setSubTableFormData,
            longData: commonDataProps.longData,
            token: token,
            setLoading: utilityProps.setLoading,
            setShowHideConfirmBox: setShowHideConfirmBox,
            setErrorMessage: utilityProps.setErrorMessage,
            targetDesc: commonDataProps.targetDesc,
            mainId: 'id',
            event: e,
        };
        console.log(confirmData);
        handleSubmit(confirmData, options);
    }

    let firstTableHeaders = Array.from(['id', 'firstName', 'lastName', 'accessLevel', 'email']);

    let firstTableEdits = Array.from(['accessLevel']);

    function returnDropdownValues(header) {
        let tempDropdownArray = Array.from(['']);

        if (header === 'status') {
            tempDropdownArray = Array.from(['Open', 'Processed']);
        } else if (header === 'paidStatus') {
            tempDropdownArray = Array.from(['Paid', 'Unpaid']);
        } else if (header === 'paymentMethod') {
            tempDropdownArray = Array.from(['PayPal', 'Direct Transfer']);
        } else if (header === 'status') {
            tempDropdownArray = Array.from(['Open', 'Processed']);
        } else if (header === 'accessLevel') {
            tempDropdownArray = Array.from(['Basic', 'Elevated', 'Admin']);
        }

        return tempDropdownArray;
    }

    if (commonDataProps.longData) {
        return (
            <tr className={showHideClassName} key="showHideClassName">
                <td>
                    <form className="descriptionModal" id="pricingDetails" onSubmit={submitForm}>
                        <p className="modalTitle">
                            User:{' '}
                            {commonDataProps.longData[0]['firstName'] + ' ' + commonDataProps.longData[0]['lastName']}
                        </p>
                        <div className="tableDescriptionImageContainer">
                            <table className="mainDescriptionTable" key="orderIdDescriptionTable1">
                                <tbody>
                                    {firstTableHeaders.map((header, i) => {
                                        //if header is in first Table Edits, then return a dropdown, instead of input
                                        if (firstTableEdits.includes(header)) {
                                            return (
                                                <tr key={'rowTableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeaderRowKey' + i}
                                                        name={header}
                                                        id={header}
                                                    >
                                                        {parseSQLToTitles(header)}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableHeaderRowValue' + i}
                                                    >
                                                        {commonDataProps.longData[0][header] !== 'Super Admin' ? (
                                                            <select
                                                                name={header}
                                                                onChange={(e) => handleEditForm(e)}
                                                                className={inputClass}
                                                            >
                                                                {returnDropdownValues(header).map((option, k) => {
                                                                    if (
                                                                        option === commonDataProps.longData[0][header]
                                                                    ) {
                                                                        return <option selected>{option}</option>;
                                                                    } else {
                                                                        return (
                                                                            <option disabled={editDisabled}>
                                                                                {option}
                                                                            </option>
                                                                        );
                                                                    }
                                                                })}
                                                            </select>
                                                        ) : (
                                                            <>Super Admin</>
                                                        )}
                                                    </th>
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                <tr key={'rowTableHeader' + i}>
                                                    <th
                                                        className="mainDescriptionTableTitle"
                                                        key={'tableDescriptionTableHeaderRowKey' + i}
                                                        name={header}
                                                        id={header}
                                                    >
                                                        {parseSQLToTitles(header)}
                                                    </th>
                                                    <th
                                                        className="mainDescriptionTableEntry"
                                                        key={'tableDescriptionTableHeaderRowValue' + i}
                                                    >
                                                        <input
                                                            className={inputClass}
                                                            placeholder={
                                                                commonDataProps.longData[0][header]
                                                                    ? commonDataProps.longData[0][header]
                                                                    : null
                                                            }
                                                            name={header}
                                                            onChange={(e) => handleEditForm(e)}
                                                            readOnly
                                                        ></input>
                                                    </th>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        <br></br>
                        <button
                            key="cancel"
                            type="button"
                            onClick={descriptionDisplay.hideProdDescription}
                            className="standardButton"
                        >
                            Cancel
                        </button>
                        <button
                            key="clear"
                            type="button"
                            onClick={(e) => showConfirm(e, '/delete_user/')}
                            className="standardButton"
                        >
                            Delete User
                        </button>
                        <button key="clear" type="button" onClick={handleInfoClear} className="standardButton">
                            Clear Changes
                        </button>
                        <button
                            key="editOrder"
                            type="button"
                            className={
                                commonDataProps.dataFetchId === '/user_management' ? editButtonClass : 'disabledButton'
                            }
                            onClick={(e) => editOn(e)}
                            disabled={commonDataProps.dataFetchId !== '/user_management'}
                        >
                            Edit Order
                        </button>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            submitForm={submitForm}
                            formName="editVariation"
                            confirmData={confirmData}
                            key="confirmBox"
                            subTableFormData={subTableFormData}
                            editTableFormData={editFormData}
                            longData={commonDataProps.longData}
                            editOn={editOn}
                        />
                    </form>
                </td>
            </tr>
        );
    }
}

// /**RENDER DESCRIPTION MODAL FOR CONTAINER */
export function ButtonContainer({
    showConfirmText = undefined,
    setSubTableFormData = undefined,
    setShowHideConfirmBox = undefined,
    hideDescription = undefined,
    editOptions = undefined,
}) {
    console.log(editOptions);
    return (
        <div className="buttonContainer">
            <button key="edit" type="button" onClick={(e) => editOn(e, editOptions)} className="standardButton">
                Edit
            </button>
            <button
                key="deleteContainer"
                type="button"
                className="standardButton"
                onClick={(e) =>
                    showConfirm(e, {
                        subTableId: { showConfirmText },
                        setConfirmData: { setSubTableFormData },
                        setShowHideConfirmBox: { setShowHideConfirmBox },
                    })
                }
            >
                Delete SKU
            </button>
            <button key="cancel" type="button" onClick={() => hideDescription()} className="standardButton">
                Cancel
            </button>
        </div>
    );
}
