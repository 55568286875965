import { useState } from 'react';

function useToken() {
    function getToken() {
        const userToken = localStorage.getItem('token');
        return userToken && userToken;
    }

    function getUser() {
        const user = localStorage.getItem('user');
        return user && user;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    function saveToken(userToken) {
        localStorage.setItem('token', userToken);
        setToken(userToken);
    }

    function removeToken() {
        localStorage.removeItem('token');
        setToken(null);
    }

    function saveUser(user) {
        localStorage.setItem('user', user);
        setUser(user);
    }

    function removeUser() {
        localStorage.removeItem('user');
        setUser(null);
    }

    return {
        setToken: saveToken,
        token,
        removeToken,
        setUser: saveUser,
        user,
        removeUser,
    };
}

export default useToken;
