//CREATE TABLE BASED ON HEADERS FROM PROPS.
//TOGGLE OF VIEW FROM BUTTONS

import { useEffect, useState, useRef, Fragment } from 'react';
import { TableItemDescription } from './tableDescription';
import { Spinner } from './spinner';
import { ErrorMessage } from './errorMessage';
import { DropdownWithoutSearchBar } from './dropdownWithoutSearchBar';
import { parseSQLToTitles } from './helperFunctions';
const baseURL = process.env.REACT_APP_API_INVOKE_URL
const searchBarInventoryHeaders = Array.from(['SKU', 'Title', 'Product Category', 'Type', 'Red Product']);

const searchBarFactoryDefectsHeaders = Array.from([
    'ID',
    'Date',
    'Order ID',
    'Product SKU',
    'R. SKU',
    'Defect Type',
    'Channel',
    'Factory',
    'Courier',
]);

const searchBarPurchaseOrdersHeaders = Array.from([
    'PO No.',
    'Supplier Ref. No.',
    'Date Added',
    'Status',
    'Factory Name',
    'Warehouse',
    'Total',
]);

const searchBarWarehouseHeaders = Array.from(['warehouseName', 'warehouseContacts', 'country']);

const searchBarFactoryHeaders = Array.from([
    'Factory Name',
    'Country',
    'Defect Rate',
    'Historical Defect Rate',
    'Contact Name',
    'Catalogue',
    'Supplier Type',
]);

const searchBarVariationHeaders = Array.from([
    'Variation Group SKU',
    'Variation Group Type',
    'Variation Group Title',
    'Variation Group Components',
]);

const searchBarPricingHeaders = Array.from(['SKU', 'Shipping Service']);

const searchBarOpenOrdersHeaders = Array.from([
    'Order ID',
    'Date',
    'Status',
    'Paid Status',
    'Customer Name',
    'Channel Name',
    'Order Items',
    'Warehouse',
]);

const searchBarProcessedOrdersHeaders = Array.from([
    'Order ID',
    'Date',
    'Status',
    'Customer Name',
    'Channel Name',
    'Order Items',
    'Warehouse',
]);

const searchBarUserManagementHeaders = Array.from(['ID', 'Email', 'First Name', 'Last Name', 'Access Level']);

const includeWarehouseDropdown = Array.from([
    '/inventory_management',
    '/open_orders_management',
    '/pricing_management',
]);

export function Table({ showTable, columnHeaders, dataFetchId, token, removeToken }) {
    /**display table description */
    const [showTableItemDescription, setShowTableItemDescription] = useState(false);
    const [targetDesc, setTargetDesc] = useState(null);

    useEffect(() => {
        const pageReloaded = localStorage.getItem('pageReloaded') === 'true';

        if (pageReloaded) {
            console.log('Page was reloaded due to fetch completion');

            // Clear the reload flag
            localStorage.removeItem('pageReloaded');

            // Retrieve the persisted state
            const displayState = localStorage.getItem('isDisplayed') === 'true';
            const storedTargetDesc = localStorage.getItem('targetDesc') || '';

            setShowTableItemDescription(displayState);
            setTargetDesc(storedTargetDesc);
        } else {
            console.log('Page was reloaded manually by the user');
        }
    }, []);

    /**display table */
    const classNameShowTable = showTable ? 'TableDiv' : 'modal display-none';

    /**data and filtered data states */
    const [data, setData] = useState();
    const [filteredData, setFilteredData] = useState();

    /**search and sort states */
    const [searchTerms, setSearchTerms] = useState(
        columnHeaders.map((header, i) => {
            return { key: header, value: '' };
        }),
    );
    const [sortIcons, setSortIcons] = useState(
        columnHeaders.map((header, i) => {
            return { key: 'sortIcon' + i, value: 'fa fa-light fa-sort' };
        }),
    );
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortKey, setSortKey] = useState('');

    /**pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [pagination, setPagination] = useState();

    /**loading and error message */
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    /**link refs */
    const linkRef = useRef(null);
    const [link, setLink] = useState(null);

    /**optional options */
    const [compositionOptions, setCompositionOptions] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState('All About Chairs');
    const [channelOptions, setChannelOptions] = useState([]);
    const [warehouseData, setWarehouseData] = useState('');
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('Parcelship');

    useEffect(() => {
        setLoading(true);
        fetch(baseURL + dataFetchId, {
            credentials: 'include',
            method: 'GET',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        removeToken();
                        // setLoading(false);
                        // controller.abort()
                        //set error message
                    }
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error.status);
                setErrorMessage('Server responded with - ' + error);
                setLoading(false);
            });
    }, [dataFetchId, token, removeToken]);

    useEffect(() => {
        let tempArray = [];
        let dataLength = 25;
        let retrievedHeaders = retrieveHeaders(dataFetchId);

        let searchColumns = columnHeaders
            .map((header, i) => {
                if (retrievedHeaders.includes(parseSQLToTitles(header))) {
                    return header;
                } else {
                    return null;
                }
            })
            .filter((value) => value !== null);

        let filteredSearchTerms = searchTerms.filter((term) => searchColumns.includes(term.key));

        if (data && sortKey) {
            if (sortOrder === 'asc') {
                tempArray = data.sort((a, b) => {
                    let first = String(a[sortKey]);
                    let second = String(b[sortKey]);
                    return first.localeCompare(second);
                });
                tempArray = tempArray.filter((item) => {
                    return filteredSearchTerms.every((filteredSearchTerm, i) => {
                        let value = String(item[filteredSearchTerm.key]);
                        return value.toLowerCase().includes(filteredSearchTerm.value);
                    });
                });
            } else if (sortOrder === 'desc') {
                tempArray = data.sort((a, b) => {
                    let first = String(a[sortKey]);
                    let second = String(b[sortKey]);
                    return second.localeCompare(first);
                });
                tempArray = tempArray.filter((item) => {
                    return filteredSearchTerms.every((filteredSearchTerm, i) => {
                        let value = String(item[filteredSearchTerm.key]);
                        return value.toLowerCase().includes(filteredSearchTerm.value);
                    });
                });
            }
            const lastRowIndex = currentPage * rowsPerPage;
            const firstRowIndex = lastRowIndex - rowsPerPage;
            dataLength = tempArray.length;
            let currentRows = tempArray.slice(firstRowIndex, lastRowIndex);
            setFilteredData(currentRows);
            setPagination([...Array(Math.ceil(dataLength / rowsPerPage))]);
        } else if (data && sortKey === '') {
            tempArray = data.filter((item) => {
                return filteredSearchTerms.every((filteredSearchTerm, i) => {
                    let value = String(item[filteredSearchTerm.key]);
                    return value.toLowerCase().includes(filteredSearchTerm.value);
                });
            });
            const lastRowIndex = currentPage * rowsPerPage;
            const firstRowIndex = lastRowIndex - rowsPerPage;
            dataLength = tempArray.length;
            let currentRows = tempArray.slice(firstRowIndex, lastRowIndex);
            setFilteredData(currentRows);
            setPagination([...Array(Math.ceil(dataLength / rowsPerPage))]);
        }
    }, [data, dataFetchId, searchTerms, columnHeaders, sortKey, sortOrder, currentPage, rowsPerPage]);

    useEffect(() => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    }, [linkRef, link]);

    useEffect(() => {
        if (dataFetchId === '/inventory_management' || '/composition_options' || '/variation_management') {
            fetch(`${baseURL}/composition_options`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setCompositionOptions(data);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Server responded with - ' + error);
                });
        }
    }, [dataFetchId, token]);

    useEffect(() => {
        if (dataFetchId === '/pricing_management') {
            fetch(`${baseURL}/channel_management`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setChannelOptions(data);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Server responded with - ' + error);
                });
        }
    }, [dataFetchId, token]);

    useEffect(() => {
        if (data && data.length !== 0 && includeWarehouseDropdown.includes(dataFetchId)) {
            //return all possible warehouses
            fetch(`${baseURL}/warehouse_management`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setWarehouseData(data);
                    let tempArray = data.map((row, i) => {
                        return row['warehouseName'];
                    });
                    setWarehouseOptions(tempArray);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Server responded with - ' + error);
                });
            // let tempArray = Object.keys(JSON.parse(data[0]["stock"]))
            // setWarehouseOptions(tempArray)
        }
    }, [data, dataFetchId, token]);

    useEffect(() => {
        if (data && dataFetchId === '/open_orders_management') {
            let tempArray = data.filter((row, i) => {
                return row['warehouse'] === selectedWarehouse;
            });
            setFilteredData(tempArray);
        }
    }, [selectedWarehouse, data, dataFetchId]);

    function handleDownloadFilteredData() {
        if (dataFetchId === '/factory_defects') {
            let csvRows = filteredData.map((row) => {
                return [
                    String(row.id).replace(/,/g, ' '),
                    String(row.date).replace(/,/g, ' '),
                    String(row.orderId).replace(/,/g, ' '),
                    String(row.productSku).replace(/,/g, ' '),
                    String(row.description).replace(/,/g, ' '),
                    String(row.replacementSku).replace(/,/g, ' '),
                    String(row.replacementQuantity).replace(/,/g, ' '),
                    String(row.defectType).replace(/,/g, ' '),
                    String(row.channelName).replace(/,/g, ' '),
                    String(row.claimAmount).replace(/,/g, ' '),
                    String(row.claimType).replace(/,/g, ' '),
                    String(row.factory).replace(/,/g, ' '),
                    String(row.courier).replace(/,/g, ' '),
                ];
            });

            let headers = [
                'id',
                'date',
                'orderId',
                'productSku',
                'description',
                'replacementSku',
                'replacementQuantity',
                'defectType',
                'channelName',
                'claimAmount',
                'claimType',
                'factory',
                'courier',
            ];
            const headersString = [headers.join(',')];
            const csvString = headersString.concat(csvRows).join('\n');
            setLink(URL.createObjectURL(new Blob([csvString], { type: 'text/csv' })));
        }
    }

    function handleRowSelect(e) {
        setRowsPerPage(e.target.value);
    }

    function handleSort(e) {
        const updatedSortIcons = sortIcons.map((icon) => {
            return { key: icon.key, value: 'fa fa-light fa-sort' };
        });
        setSortIcons(updatedSortIcons);
        setSortKey(e.target.dataset.value);
        const datasetIndex = columnHeaders.indexOf(e.target.dataset.value);
        if (sortOrder === 'asc') {
            ///switch to desc
            setSortOrder('desc');
            const updatedSortIcons = sortIcons.map((icon) => {
                if ('sortIcon' + datasetIndex.toString() === icon.key) {
                    return { key: icon.key, value: 'fa fa-duotone fa-sort-up' };
                } else {
                    return icon;
                }
            });
            setSortIcons(updatedSortIcons);
        } else if (sortOrder === 'desc') {
            ///switch the asc
            setSortOrder('asc');
            const updatedSortIcons = sortIcons.map((icon) => {
                if ('sortIcon' + datasetIndex.toString() === icon.key) {
                    return { key: icon.key, value: 'fa fa-duotone fa-sort-down' };
                } else {
                    return icon;
                }
            });
            setSortIcons(updatedSortIcons);
        }
    }

    function handleSearch(e) {
        let updatedSearchTerms = '';
        updatedSearchTerms = searchTerms.map((term) => {
            if (term.key === e.target.name) {
                //if the value for said key is empty, then update value
                return { key: term.key, value: e.target.value };
            } else {
                //if return the same term
                return term;
            }
        });
        setSearchTerms(updatedSearchTerms);
    }

    function sortIcon(header, index) {
        if (sortIcons) {
            return (
                <i
                    key={'sortIcon' + index}
                    className={sortIcons[index].value}
                    data-value={header}
                    onClick={(e) => handleSort(e)}
                ></i>
            );
        }
    }

    function retrieveHeaders(dataFetchId) {
        if (dataFetchId === '/inventory_management') {
            return searchBarInventoryHeaders;
        } else if (dataFetchId === '/factory_defects') {
            return searchBarFactoryDefectsHeaders;
        } else if (dataFetchId === '/purchase_orders') {
            return searchBarPurchaseOrdersHeaders;
        } else if (dataFetchId === '/factory_management') {
            return searchBarFactoryHeaders;
        } else if (dataFetchId === '/warehouse_management') {
            return searchBarWarehouseHeaders;
        } else if (dataFetchId === '/variation_management') {
            return searchBarVariationHeaders;
        } else if (dataFetchId === '/pricing_management') {
            return searchBarPricingHeaders;
        } else if (dataFetchId === '/open_orders_management') {
            return searchBarOpenOrdersHeaders;
        } else if (dataFetchId === '/processed_orders_management' || dataFetchId === '/draft_orders_management') {
            return searchBarProcessedOrdersHeaders;
        } else if (dataFetchId === '/user_management') {
            return searchBarUserManagementHeaders;
        }
    }

    function searchBar(header, index) {
        let retrievedHeaders = retrieveHeaders(dataFetchId);
        if (retrievedHeaders.includes(parseSQLToTitles(header))) {
            return [
                <input
                    className="searchBar"
                    type="text"
                    name={header}
                    onChange={handleSearch}
                    placeholder="Filter..."
                    id={index}
                    key={header + '_search'}
                />,
            ];
        }
    }

    function displayTableItemDescription(e) {
        setShowTableItemDescription(true);
        setTargetDesc(e.target.id);
        localStorage.setItem('isDisplayed', 'true');
        localStorage.setItem('targetDesc', e.target.id);
    }

    function hideTableItemDescription() {
        setShowTableItemDescription(false);
        setTargetDesc('');
        localStorage.removeItem('isDisplayed');
        localStorage.removeItem('targetDesc');
    }

    if (data && data.length !== 0 && !errorMessage) {
        if (filteredData) {
            return (
                <>
                    <Spinner loading={loading} />
                    <div className="channelSelectorContainer" key="channelSelectorContainer">
                        {dataFetchId === '/pricing_management' && channelOptions ? (
                            <>
                                <p>Select channel: </p>
                                <DropdownWithoutSearchBar
                                    options={channelOptions.map((entry, i) => {
                                        return entry['channelName'];
                                    })}
                                    setAddNewValue={setSelectedChannel}
                                    addNewValue={selectedChannel}
                                    subTableId="fetchTableChannel"
                                />
                            </>
                        ) : null}
                        {includeWarehouseDropdown.includes(dataFetchId) && warehouseOptions ? (
                            <>
                                <p>Select warehouse: </p>
                                <DropdownWithoutSearchBar
                                    options={warehouseOptions}
                                    setAddNewValue={setSelectedWarehouse}
                                    addNewValue={selectedWarehouse}
                                    subTableId="fetchTableWarehouse"
                                />
                            </>
                        ) : null}
                    </div>
                    <div className={classNameShowTable} key="columnHeaderKey3">
                        <table className="mainTable" key="columnHeaderKey2">
                            <tbody key="columnHeaderKey1">
                                <tr className="mainTable-titleRow" key="columnHeaderKey">
                                    {columnHeaders.map((header, i) => (
                                        <th key={i + header}>
                                            {sortIcon(header, i)}
                                            &nbsp;
                                            {parseSQLToTitles(header)}
                                            {searchBar(header, i)}
                                        </th>
                                    ))}
                                </tr>
                                <TableItemDescription
                                    showTableItemDescription={showTableItemDescription}
                                    setShowTableItemDescription={setShowTableItemDescription}
                                    displayTableItemDescription={displayTableItemDescription}
                                    hideTableItemDescription={hideTableItemDescription}
                                    dataFetchId={dataFetchId}
                                    targetDesc={targetDesc}
                                    setTargetDesc={setTargetDesc}
                                    key="tableDescModal"
                                    setLoading={setLoading}
                                    loading={loading}
                                    initData={data}
                                    compositionOptions={compositionOptions}
                                    parseSQLToTitles={parseSQLToTitles}
                                    channelOptions={channelOptions}
                                    warehouseOptions={warehouseOptions}
                                    selectedWarehouse={selectedWarehouse}
                                    token={token}
                                />
                                <ConditionalRender
                                    data={data}
                                    filteredData={filteredData}
                                    displayTableItemDescription={displayTableItemDescription}
                                    columnHeaders={columnHeaders}
                                    selectedChannel={selectedChannel}
                                    dataFetchId={dataFetchId}
                                    selectedWarehouse={selectedWarehouse}
                                    warehouseData={warehouseData}
                                />
                            </tbody>
                        </table>
                        <div className="mainTable-downloadFilteredButtons" key="download">
                            <button
                                onClick={handleDownloadFilteredData}
                                className="standardButton"
                                key="downloadFilteredData"
                            >
                                Download Filtered Data
                            </button>
                            <a
                                download="factoryDefects.csv"
                                target="_blank"
                                rel="noopener noreferrer"
                                ref={linkRef}
                                key="downloadFilteredImagesLink"
                                href={link}
                                hidden
                            >
                                DownloadLink
                            </a>
                        </div>
                        <div className="mainTable-selectPages" key="selectPages">
                            <select className="mainTable-selectRows" onChange={handleRowSelect}>
                                <option value={25}>Number of rows: 25</option>
                                <option value={50}>Number of rows: 50</option>
                                <option value={75}>Number of rows: 75</option>
                                <option value={100}>Number of rows: 100</option>
                            </select>
                            <div className="mainTable-totalEntries">
                                <p>Total entries: {data.length}</p>
                            </div>
                        </div>
                        <div className="mainTable-pagination">
                            <button
                                href="#"
                                onClick={() => {
                                    if (currentPage - 1 > 0) {
                                        setCurrentPage(currentPage - 1);
                                    }
                                }}
                            >
                                &laquo;
                            </button>
                            {pagination.map((_, i) => (
                                <button
                                    key={i}
                                    className={currentPage === i + 1 ? 'mainTable-active' : ''}
                                    onClick={() => setCurrentPage(i + 1)}
                                    href="#"
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => {
                                    if (currentPage + 1 <= pagination.length) {
                                        setCurrentPage(currentPage + 1);
                                    }
                                }}
                            >
                                &raquo;
                            </button>
                        </div>
                    </div>
                </>
            );
        } else {
            return [
                <Spinner loading={loading} key="spinner" />,
                <NoDataTable columnHeaders={columnHeaders} parseSQLToTitles={parseSQLToTitles} key="noData" />,
            ];
        }
    } else if (data && data.length === 0 && !errorMessage) {
        return [
            <Spinner loading={loading} key="spinner" />,
            <NoDataTable columnHeaders={columnHeaders} parseSQLToTitles={parseSQLToTitles} key="noData" />,
        ];
    } else if (errorMessage) {
        return [
            <Spinner loading={loading} key="spinner" />,
            <ErrorMessage errorMessage={errorMessage} columnHeaders={columnHeaders} key="errorMessage" />,
        ];
    } else {
        return [
            <Spinner loading={loading} key="spinner" />,
            <FetchingTable columnHeaders={columnHeaders} key="fetchingTable" />,
        ];
    }
}

function FetchingTable({ columnHeaders }) {
    return [
        <div className="TableDiv" key="columnHeaderKey3">
            <table className="mainTable">
                <tbody key="tbody1">
                    <tr className="mainTable-titleRow">
                        {columnHeaders.map((header, i) => (
                            <th key={i}>{parseSQLToTitles(header)}</th>
                        ))}
                    </tr>
                    <tr className="itemRow">
                        {columnHeaders.map((header, i) => (
                            <th key={header + i + 'nodata'}>Fetching data...</th>
                        ))}
                    </tr>
                </tbody>
            </table>
            <br></br>
        </div>,
    ];
}

function NoDataTable({ columnHeaders, parseSQLToTitles }) {
    return (
        <div className="TableDiv" key="columnHeaderKey3">
            <table className="mainTable">
                <tbody key="tbody1">
                    <tr className="mainTable-titleRow">
                        {columnHeaders.map((header, i) => (
                            <th key={i}>{parseSQLToTitles(header)}</th>
                        ))}
                    </tr>
                    <tr className="itemRow">
                        {columnHeaders.map((header, i) => (
                            <th key={header + i + 'nodata'}>No data added yet!</th>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function retrieveExcluded(dataFetchId) {
    if (dataFetchId === '/open_orders_management' || dataFetchId === '/processed_orders_management') {
        return Array.from([
            'discount',
            'sellingPrice',
            'newSellingPrice',
            'vat',
            'profit',
            'platformCommission',
            'profitMargin',
            'shippingPrice',
            'purchasePrice',
            'lineTotalNoVat',
            'image',
        ]);
    } else {
        return Array.from(['']);
    }
}

function ConditionalRender({
    data,
    filteredData,
    displayTableItemDescription,
    columnHeaders,
    dataFetchId,
    selectedWarehouse,
    warehouseData,
    selectedChannel,
}) {
    const detailedCell = Array.from(['orderItems', 'warehouseContacts']);

    function parseBoolean(int) {
        if (int === 1) {
            return 'True';
        } else if (int === 0) {
            return 'False';
        }
    }

    function calcAvailable(stock, inopenorders) {
        return String(Number(stock) - Number(inopenorders));
    }

    function parseJSON(json) {
        if (json) {
            return JSON.parse(json)[selectedWarehouse];
        }
    }

    let excludedVariables = retrieveExcluded(dataFetchId);

    if (filteredData) {
        return filteredData.map((primaryId, i) => {
            return (
                <tr className="itemRow" key={primaryId + '_' + i}>
                    {columnHeaders.map((header, j) => {
                        if (j === 0) {
                            return (
                                <th
                                    key={header + '_' + j}
                                    className="productDescription"
                                    id={primaryId[header]}
                                    onClick={(e) => displayTableItemDescription(e)}
                                >
                                    {primaryId[header]}
                                </th>
                            );
                        } else if (header === 'stock' || header === 'inOpenOrders') {
                            return <th key={header + '_' + j}>{parseJSON(primaryId[header])}</th>;
                        } else if (header === 'redProduct') {
                            return <th key={header + '_' + j}>{parseBoolean(primaryId[header])}</th>;
                        } else if (header === 'available') {
                            return (
                                <th key={header + '_' + j}>
                                    {calcAvailable(
                                        parseJSON(primaryId['stock']),
                                        parseJSON(primaryId['inOpenOrders']),
                                    ) || 'Add PO to update purchase price.'}
                                </th>
                            );
                        } else if (header === 'purchasePrice') {
                            return (
                                <th key={header + '_' + j}>
                                    {parseJSON(primaryId[header]) || 'Add PO to update purchase price.'}
                                </th>
                            );
                        } else if (header === 'total') {
                            if (warehouseData && selectedWarehouse) {
                                let tempArray = warehouseData.filter((warehouse, l) => {
                                    return warehouse['warehouseName'] === selectedWarehouse;
                                });
                                return (
                                    <th key={header + '_' + j}>
                                        {primaryId[header]
                                            ? ' ' + primaryId[header].toFixed(2) + ' ' + tempArray[0]['currency']
                                            : '0.00 ' + tempArray[0]['currency']}
                                    </th>
                                );
                            } else {
                                return (
                                    <th key={header + '_' + j}>
                                        {primaryId[header] ? ' ' + primaryId[header].toFixed(2) : '0.00'}
                                    </th>
                                );
                            }
                        } else if (detailedCell.includes(header)) {
                            let orderItems = JSON.parse(primaryId[header]);
                            if (orderItems) {
                                return (
                                    <th key={header + '_' + j} className="lineItems">
                                        <div className="lineItems">
                                            {orderItems.map((line, k) => {
                                                let keys = Object.keys(line);
                                                return (
                                                    <Fragment key={'line_' + k}>
                                                        {keys.map((key, l) => {
                                                            if (!excludedVariables.includes(key)) {
                                                                return (
                                                                    <Fragment key={'line_' + k + l}>
                                                                        {parseSQLToTitles(key)}: {line[key]}
                                                                        <br></br>
                                                                    </Fragment>
                                                                );
                                                            } else {
                                                                return '';
                                                            }
                                                        })}
                                                        <br></br>
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    </th>
                                );
                            } else {
                                return (
                                    <th key={header + '_' + j} className="lineItems">
                                        <div className="lineItems">No items added yet.</div>
                                    </th>
                                );
                            }
                        } else if (
                            header === 'sellingPrice' ||
                            header === 'profit' ||
                            header === 'profitMargin' ||
                            header === 'shippingPrice'
                        ) {
                            let sellingPrices = JSON.parse(primaryId['sellingPrice']);
                            let sellingPriceRow = '';

                            if (sellingPrices && selectedChannel) {
                                sellingPriceRow = sellingPrices.filter((row, i) => {
                                    return row['channelName'] === selectedChannel;
                                });
                            }
                            if (sellingPriceRow && sellingPriceRow.length !== 0) {
                                return (
                                    <th key={header + '_' + j}>
                                        <div className="lineItems">{sellingPriceRow[0][header]}</div>
                                    </th>
                                );
                            } else {
                                return <th key={header + '_' + j}>0.00</th>;
                            }
                        } else if (header === 'cbm') {
                            let cartonDimensions = JSON.parse(primaryId['cartonDimensions']);
                            if (cartonDimensions && cartonDimensions.length !== 0) {
                                let cbm = Object.values(cartonDimensions);
                                cbm = cbm.reduce((a, b) => {
                                    return a * b;
                                });
                                return <th key={header + '_' + j}>{cbm / 1000000}</th>;
                            } else {
                                return <th key={header + '_' + j}>0.00</th>;
                            }
                        } else if (!primaryId[header]) {
                            return <th key={header + '_' + j}>Add data.</th>;
                        } else {
                            return <th key={header + '_' + j}>{primaryId[header]}</th>;
                        }
                    })}
                </tr>
            );
        });
    } else {
        return data.map((primaryId, i) => {
            return (
                <tr className="itemRow" key={primaryId + '_' + i}>
                    {columnHeaders.map((header, j) => {
                        if (j === 0) {
                            return (
                                <th
                                    key={header + '_' + j}
                                    className="productDescription"
                                    id={primaryId[header]}
                                    onClick={(e) => displayTableItemDescription(e)}
                                >
                                    {primaryId[header]}
                                </th>
                            );
                        } else if (header === 'stock' || header === 'inOpenOrders') {
                            return (
                                <th key={header + '_' + j}>
                                    {parseJSON(primaryId[header]) || 'Add PO to update stock.'}
                                </th>
                            );
                        } else if (header === 'redProduct') {
                            return <th key={header + '_' + j}>{parseBoolean(primaryId[header])}</th>;
                        } else if (header === 'available') {
                            return (
                                <th key={header + '_' + j}>
                                    {calcAvailable(
                                        parseJSON(primaryId['stock']),
                                        parseJSON(primaryId['inOpenOrders']),
                                    ) || 'Add PO to update stock.'}
                                </th>
                            );
                        } else if (header === 'purchasePrice') {
                            return (
                                <th key={header + '_' + j}>
                                    {parseJSON(primaryId[header]) || 'Add PO to update purchase price.'}
                                </th>
                            );
                        } else if (header === 'total') {
                            return (
                                <th key={header + '_' + j}>
                                    {primaryId[header] ? '£ ' + primaryId[header].toFixed(2) : '$ 0.00'}
                                </th>
                            );
                        } else if (!primaryId[header]) {
                            return <th key={header + '_' + j}>Add data.</th>;
                        } else if (header === 'orderItems') {
                            let orderItems = JSON.parse(primaryId[header]);
                            return (
                                <th key={header + '_' + j}>
                                    {orderItems.map((line, k) => {
                                        return (
                                            <>
                                                {line.map((variable, l) => {
                                                    if (!excludedVariables.includes(Object.keys(variable)[0])) {
                                                        return (
                                                            <>
                                                                {parseSQLToTitles(Object.keys(variable)[0])}:{' '}
                                                                {Object.values(variable)[0]}
                                                                <br></br>
                                                            </>
                                                        );
                                                    } else {
                                                        return '';
                                                    }
                                                })}
                                                <br></br>
                                            </>
                                        );
                                    })}
                                </th>
                            );
                        } else {
                            return <th key={header + '_' + j}>{primaryId[header]}</th>;
                        }
                    })}
                </tr>
            );
        });
    }
}
