import { React, useEffect, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';
import { CsvUpload } from './csvUpload';
const openOrdersHeaders = Array.from([
    'orderId',
    'date',
    'status',
    'paidStatus',
    'customerName',
    'channelName',
    'orderItems',
    'warehouse',
    'total',
]);

const buttonList = Array.from(['openOrdersTable', 'newOrder', 'bulkActions']);

export function OpenOrdersButtons({ token, setToken, removeToken }) {
    const [activeTab, setActiveTab] = useState('openOrdersTable');
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showCSV, setShowCSV] = useState(false);
    const [endpoint, setEndpoint] = useState('');

    useEffect(() => {
        if (activeTab === 'openOrdersTable') {
            setShowForm(false);
            setShowTable(true);
        } else if (activeTab === 'newOrder') {
            setShowTable(false);
            setShowForm(true);
        } else if (activeTab === 'bulkActions') {
            console.log('clicked');
            setShowTable(false);
            setShowCSV(true);
            setEndpoint('/order_management');
            console.log('showCSV prop:', showCSV);
        }
    }, [activeTab]);

    return (
        <>
            <ButtonMenu buttonList={buttonList} setActiveTab={setActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={openOrdersHeaders}
                dataFetchId="/open_orders_management"
                key="openOrdersTable"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={setActiveTab}
                formFetchId={activeTab}
                token={token}
                removeToken={removeToken}
            />
            <CsvUpload
                showCSV={showCSV}
                endpoint={endpoint}
                token={token}
                removeToken={removeToken}
                csvId={'ordersBulkUpload'}
            />
        </>
    );
}
