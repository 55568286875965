import { useEffect, useState } from 'react';
import { DropdownWithSearchBar, DropdownWithSearchBarAndStock } from './dropdownWithSearchBar';
import { DropdownWithoutSearchBar } from './dropdownWithoutSearchBar';
import {
    fetchOptions,
    retrieveSubTableHeaders,
    parseTitle,
    convertMeasurements,
    cartonDimensionOptions,
    handleInfoClear,
    retrieveSubTableHeaderKeys,
    joinArray,
    handleMultiTitlesValues,
    handleTitleValue,
    clearAdded,
    ClearAndSave,
} from './helperFunctions';


export function SubTable({
    commonDataProps,
    utilityProps,
    subTableOptions,
    subTableCommonDataProps,
    subTableId,
    token,
}) {
    const [infoArray, setInfoArray] = useState(''); //aka parsed output of longData[0][subTableId]
    const [keys, setKeys] = useState('');
    const [defaultKeys, setDefaultKeys] = useState('');
    const [defaultData, setDefaultData] = useState('');
    const [addNewTitlesValues, setAddNewTitlesValues] = useState('');

    let editSubTableData = {
        infoArray,
        setInfoArray,
        keys,
        setKeys,
        defaultKeys,
        resetArray,
        defaultData,
        addNewTitlesValues,
        setAddNewTitlesValues,
        subTableCommonDataProps,
    };

    useEffect(() => {
        const data = commonDataProps.longData[0][subTableId];
        const parsedData = JSON.parse(commonDataProps.longData[0][subTableId]);

        if (data && !infoArray) {
            let keys = Array.isArray(parsedData) ? Object.keys(parsedData[0]) : Object.keys(defaultData);
            setInfoArray(parsedData);
            setKeys(keys);
            setDefaultKeys(keys);
            setDefaultData(parsedData);
            console.log(parsedData);
            let tempAddNewTitlesValues = {};
            keys.forEach((key, k) => {
                tempAddNewTitlesValues[key] = '';
            });
            setAddNewTitlesValues([tempAddNewTitlesValues]);
            /**INITIATE DEFAULT DATA */
        } else if (data && infoArray && JSON.stringify(parsedData) !== JSON.stringify(defaultData)) {
            //if parsed data !== default data, it means there has been a reload of long data
            console.log(parsedData === defaultData);
            setInfoArray('');
            // setDefaultKeys(Object.keys(parsedData));
            // setDefaultData(parsedData);
        }
    }, [commonDataProps.longData, subTableId, infoArray, defaultData]);

    function initialiseAddNewTitlesValues(e) {
        let tempArray = {};
        let keys = Array.isArray(defaultData) ? Object.keys(defaultData[0]) : Object.keys(defaultData);
        keys.forEach((key, k) => {
            tempArray[key] = '';
        });
        console.log(tempArray);
        setAddNewTitlesValues(tempArray);
        handleInfoClear(e);
    }

    function resetArray() {
        setInfoArray(JSON.parse(commonDataProps.longData[0][subTableId]));
        setKeys(defaultKeys);
        initialiseAddNewTitlesValues();
    }

    if (subTableId) {
        if (subTableId === 'stock') {
            return (
                <StockSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                />
            );
        } else if (subTableId === 'cartonDimensions') {
            return (
                <CartonDimensionsSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                    measurementStandard={subTableCommonDataProps.measurementStandard}
                />
            );
        } else if (subTableId === 'productDimensions') {
            return (
                <ProductDimensionsSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                    measurementStandard={subTableCommonDataProps.measurementStandard}
                />
            );
        } else if (subTableId === 'altSku' || subTableId === 'customProperties') {
            return (
                <DescriptionSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                    measurementStandard={subTableCommonDataProps.measurementStandard}
                />
            );
        } else if (subTableId === 'composition') {
            return (
                <CompositionSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                />
            );
        } else if (subTableId === 'poStockInfo') {
            return (
                <PurchaseOrderSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    commonDataProps={commonDataProps}
                    formId={subTableCommonDataProps.formId}
                    token={token}
                />
            );
        } else if (subTableId === 'warehouseContacts') {
            return (
                <WarehouseContactSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    commonDataProps={commonDataProps}
                    formId={subTableCommonDataProps.formId}
                    token={token}
                />
            );
        } else if (subTableId === 'variationGroupComponents') {
            return (
                <VariationSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                />
            );
        } else if (subTableId === 'shippingService') {
            return (
                <ShippingServiceSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                />
            );
        } else if (subTableId === 'sellingPrice') {
            return (
                <SellingPriceSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                    addNewTitlesValues={addNewTitlesValues}
                    formId={subTableCommonDataProps.formId}
                    selectedWarehouse={commonDataProps.selectedWarehouse}
                />
            );
        } else if (subTableId === 'orderItems') {
            return (
                <OrderItemsSubTable
                    longData={commonDataProps.longData}
                    subTableId={subTableId}
                    editSubTableData={editSubTableData}
                    token={token}
                    addNewTitlesValues={addNewTitlesValues}
                    selectedWarehouse={commonDataProps.selectedWarehouse}
                    formId={subTableCommonDataProps.formId}
                />
            );
        }
    }
}

//STOCK SUBTABLE
function StockSubTable({ longData, subTableId, editSubTableData, token }) {
    return (
        <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
            <tbody>
                {/* Header row */}
                <tr key={subTableId + 'Headers'}>
                    {retrieveSubTableHeaders(subTableId).map((header, i) => {
                        return (
                            <th className="subTableHeader" key={subTableId + '_' + header + i + 'Column'}>
                                {header}
                            </th>
                        );
                    })}
                </tr>
                {/* Body row */}
                {editSubTableData.infoArray ? (
                    editSubTableData.infoArray.map((row, r) => {
                        return editSubTableData.keys.map((key, k) => {
                            return (
                                <tr key={subTableId + 'Content_' + r + k}>
                                    <th
                                        className={
                                            editSubTableData.defaultKeys.includes(key)
                                                ? 'subTableEntry'
                                                : 'subTableEntryAdded'
                                        }
                                        key={key + '_keyAdded'}
                                    >
                                        {key}
                                    </th>
                                    <th
                                        className={
                                            editSubTableData.defaultKeys.includes(key)
                                                ? 'subTableEntry'
                                                : 'subTableEntryAdded'
                                        }
                                        key={key + 'stock' + r + k + '_valueAdded'}
                                    >
                                        {row[key]}
                                    </th>
                                    <th
                                        className={
                                            editSubTableData.defaultKeys.includes(key)
                                                ? 'subTableEntry'
                                                : 'subTableEntryAdded'
                                        }
                                        key={key + 'ioo' + r + k + '_valueAdded'}
                                    >
                                        {JSON.parse(longData[0]['inOpenOrders'])[0][key]}
                                    </th>
                                    <th
                                        className={
                                            editSubTableData.defaultKeys.includes(key)
                                                ? 'subTableEntry'
                                                : 'subTableEntryAdded'
                                        }
                                        key={key + 'available' + r + k + '_valueAdded'}
                                    >
                                        {row[key] - JSON.parse(longData[0]['inOpenOrders'])[0][key]}
                                    </th>
                                </tr>
                            );
                        });
                    })
                ) : (
                    <tr key={subTableId + '_null'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                        })}
                    </tr>
                )}
            </tbody>
        </table>
    );
}

//CARTON DIMENSIONS SUBTABLE
function CartonDimensionsSubTable({ longData, subTableId, editSubTableData, token, measurementStandard }) {
    const [title, setTitle] = useState('');
    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header + convertMeasurements(header, measurementStandard, 'title')}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return editSubTableData.keys.map((key, k) => {
                                return (
                                    <tr key={subTableId + 'Content_' + r + k}>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + '_keyAdded'}
                                        >
                                            {key}
                                        </th>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + r + k + '_valueAdded'}
                                        >
                                            {convertMeasurements(row[key], measurementStandard, key)}
                                        </th>
                                        <th className="deleteButtonContainer">
                                            <button
                                                className="deleteButtonSubTable"
                                                type="button"
                                                onClick={(e) =>
                                                    clearAdded(e, {
                                                        index: k,
                                                        arrayToRemoveFrom: editSubTableData.infoArray,
                                                        setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                        setKeys: editSubTableData.setKeys,
                                                        setSubTableFormData:
                                                            editSubTableData.subTableCommonDataProps
                                                                .setSubTableFormData,
                                                        mode: 'one-json',
                                                    })
                                                }
                                            >
                                                X
                                            </button>
                                        </th>
                                    </tr>
                                );
                            });
                        })
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <DropdownWithoutSearchBar
                                options={cartonDimensionOptions}
                                addNewTitlesValues={editSubTableData.addNewTitlesValues}
                                setAddNewTitlesValues={editSubTableData.setAddNewTitlesValues}
                                title={title}
                                setTitle={setTitle}
                                mode="dropdown-text"
                                subTableId={subTableId}
                            />
                        </th>
                        <th className="subTableAddNew">
                            <input
                                type="number"
                                step="0.01"
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleTitleValue(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        title: title,
                                        setTitle: setTitle,
                                        mode: 'dropdown-text',
                                    })
                                }
                                placeholder={'Add new ' + parseTitle(subTableId)[1] + '...'}
                            ></input>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                title="Add a new row to table."
                                onClick={(e) =>
                                    joinArray(e, {
                                        arrayToAdd: editSubTableData.addNewTitlesValues,
                                        arrayToAddTo: editSubTableData.infoArray,
                                        setArrayToAddTo: editSubTableData.setInfoArray,
                                        setKeys: editSubTableData.setKeys,
                                        setSubTableFormData:
                                            editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                        mode: 'one-json',
                                    })
                                }
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                className="editButton"
                                title="Clear input row."
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//PRODUCT DIMENSIONS SUBTABLE
function ProductDimensionsSubTable({ longData, subTableId, editSubTableData, token, measurementStandard }) {
    const [title, setTitle] = useState('');
    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header + convertMeasurements(header, measurementStandard, 'title')}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return editSubTableData.keys.map((key, k) => {
                                return (
                                    <tr key={subTableId + 'Content_' + r + k}>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + '_keyAdded'}
                                        >
                                            {key}
                                        </th>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + r + k + '_valueAdded'}
                                        >
                                            {convertMeasurements(row[key], measurementStandard, key)}
                                        </th>
                                        <th className="deleteButtonContainer">
                                            <button
                                                className="deleteButtonSubTable"
                                                type="button"
                                                onClick={(e) =>
                                                    clearAdded(e, {
                                                        index: k,
                                                        arrayToRemoveFrom: editSubTableData.infoArray,
                                                        setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                        setKeys: editSubTableData.setKeys,
                                                        setSubTableFormData:
                                                            editSubTableData.subTableCommonDataProps
                                                                .setSubTableFormData,
                                                        mode: 'one-json',
                                                    })
                                                }
                                            >
                                                X
                                            </button>
                                        </th>
                                    </tr>
                                );
                            });
                        })
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Add new title...'}
                                name="title"
                                onInput={(e) =>
                                    handleTitleValue(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        mode: 'text-text',
                                        title: title,
                                        setTitle: setTitle,
                                    })
                                }
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleTitleValue(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        mode: 'text-text',
                                        title: title,
                                        setTitle: setTitle,
                                    })
                                }
                                placeholder={'Add new ' + parseTitle(subTableId)[1] + '...'}
                                type="number"
                                step="0.01"
                            ></input>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                title="Add a new row to table."
                                onClick={(e) =>
                                    joinArray(e, {
                                        arrayToAdd: editSubTableData.addNewTitlesValues,
                                        arrayToAddTo: editSubTableData.infoArray,
                                        setArrayToAddTo: editSubTableData.setInfoArray,
                                        setKeys: editSubTableData.setKeys,
                                        setSubTableFormData:
                                            editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                        mode: 'one-json',
                                    })
                                }
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                title="Clear input row."
                                className="editButton"
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//DESCRIPTION SUBTABLE
function DescriptionSubTable({ longData, subTableId, editSubTableData, token, measurementStandard }) {
    const [title, setTitle] = useState('');
    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return editSubTableData.keys.map((key, k) => {
                                return (
                                    <tr key={subTableId + 'Content_' + r + k}>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + '_keyAdded'}
                                        >
                                            {key}
                                        </th>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + r + k + '_valueAdded'}
                                        >
                                            {row[key]}
                                        </th>
                                        <th className="deleteButtonContainer">
                                            <button
                                                className="deleteButtonSubTable"
                                                type="button"
                                                onClick={(e) =>
                                                    clearAdded(e, {
                                                        index: k,
                                                        arrayToRemoveFrom: editSubTableData.infoArray,
                                                        setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                        setKeys: editSubTableData.setKeys,
                                                        setSubTableFormData:
                                                            editSubTableData.subTableCommonDataProps
                                                                .setSubTableFormData,
                                                        mode: 'one-json',
                                                    })
                                                }
                                            >
                                                X
                                            </button>
                                        </th>
                                    </tr>
                                );
                            });
                        })
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Add new title...'}
                                name="title"
                                onInput={(e) =>
                                    handleTitleValue(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        mode: 'text-text',
                                        title: title,
                                        setTitle: setTitle,
                                    })
                                }
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleTitleValue(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        mode: 'text-text',
                                        title: title,
                                        setTitle: setTitle,
                                    })
                                }
                                placeholder={'Add new ' + parseTitle(subTableId)[1] + '...'}
                                type="text"
                            ></input>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                title="Add a new row to table."
                                onClick={(e) =>
                                    joinArray(e, {
                                        arrayToAdd: editSubTableData.addNewTitlesValues,
                                        arrayToAddTo: editSubTableData.infoArray,
                                        setArrayToAddTo: editSubTableData.setInfoArray,
                                        setKeys: editSubTableData.setKeys,
                                        setSubTableFormData:
                                            editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                        mode: 'one-json',
                                    })
                                }
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                className="editButton"
                                title="Clear input row."
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//COMPOSITION SUBTABLE
function CompositionSubTable({ longData, subTableId, editSubTableData, token, measurementStandard }) {
    const [title, setTitle] = useState('');
    const skuType = longData[0]['type'];
    const [filteredCompositionOptions, setFilteredCompositionOptions] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [compositionOptions, setCompositionOptions] = useState(null);

    useEffect(() => {
        console.log(searchTerm);
        if (searchTerm) {
            const filtered = compositionOptions.filter((option) => {
                return option.sku.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredCompositionOptions(filtered);
        }
    }, [searchTerm, longData, subTableId, compositionOptions]);

    useEffect(() => {
        fetchOptions('/composition_options', token).then((response) => {
            const nonCompositeOptions = response.filter((option, o) => {
                return option['composition'] !== 'Composite_SKU';
            });
            const options = nonCompositeOptions.map((option) => option.sku);
            setCompositionOptions(options);
            setFilteredCompositionOptions(options);
        });
    }, [token]);

    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row*/}
                    {skuType === 'Base_SKU' || skuType === 'Component_SKU' ? (
                        <tr key={subTableId + '_null'}>
                            <th>Only composite SKU's will have SKU composition.</th>
                            <th>Only composite SKU's will have SKU composition.</th>
                        </tr>
                    ) : editSubTableData.infoArray ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return editSubTableData.keys.map((key, k) => {
                                return (
                                    <tr key={subTableId + 'Content_' + k}>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + '_keyAdded'}
                                        >
                                            {key}
                                        </th>
                                        <th
                                            className={
                                                editSubTableData.defaultKeys.includes(key)
                                                    ? 'subTableEntry'
                                                    : 'subTableEntryAdded'
                                            }
                                            key={key + '_valueAdded'}
                                        >
                                            {editSubTableData.infoArray[0][key]}
                                        </th>
                                        <th className="deleteButtonContainer">
                                            <button
                                                className="deleteButtonSubTable"
                                                type="button"
                                                onClick={(e) =>
                                                    clearAdded(e, {
                                                        index: k,
                                                        arrayToRemoveFrom: editSubTableData.infoArray,
                                                        setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                        setKeys: editSubTableData.setKeys,
                                                        setSubTableFormData:
                                                            editSubTableData.subTableCommonDataProps
                                                                .setSubTableFormData,
                                                        mode: 'one-json',
                                                    })
                                                }
                                            >
                                                X
                                            </button>
                                        </th>
                                    </tr>
                                );
                            });
                        })
                    ) : (
                        <tr>
                            <th>Loading...</th>
                        </tr>
                    )}
                    {/* Input row */}
                    {skuType === 'Base_SKU' || skuType === 'Component_SKU' ? null : (
                        <tr key={parseTitle(subTableId)[1] + 'Content'}>
                            <th className="subTableAddNew">
                                <DropdownWithSearchBar
                                    options={filteredCompositionOptions}
                                    addNewTitlesValues={editSubTableData.addNewTitlesValues}
                                    setAddNewTitlesValues={editSubTableData.setAddNewTitlesValues}
                                    title={title}
                                    setTitle={setTitle}
                                    setSearchTerm={setSearchTerm}
                                    mode="dropdown-text"
                                    subTableId={subTableId}
                                />
                            </th>
                            <th className="subTableAddNew">
                                <input
                                    type="number"
                                    step="1"
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleTitleValue(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            title: title,
                                            setTitle: setTitle,
                                            mode: 'dropdown-text',
                                        })
                                    }
                                    placeholder={'Add new ' + parseTitle(subTableId)[1] + '...'}
                                ></input>
                            </th>
                            <th className="subTableAddNewButton">
                                <button
                                    key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                    type="button"
                                    className="editButton"
                                    title="Add a new row to table."
                                    onClick={(e) =>
                                        joinArray(e, {
                                            arrayToAdd: editSubTableData.addNewTitlesValues,
                                            arrayToAddTo: editSubTableData.infoArray,
                                            setArrayToAddTo: editSubTableData.setInfoArray,
                                            setKeys: editSubTableData.setKeys,
                                            setSubTableFormData:
                                                editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                            mode: 'one-json',
                                        })
                                    }
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </th>
                            <th className="subTableAddNewButton">
                                <button
                                    key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                    type="button"
                                    className="editButton"
                                    title="Clear input row."
                                    onClick={(e) => handleInfoClear(e)}
                                >
                                    <i className="fas fa-broom" aria-hidden="true"></i>
                                </button>
                            </th>
                        </tr>
                    )}
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//PURCHASE ORDER SUBTABLE
function PurchaseOrderSubTable({ longData, subTableId, editSubTableData, token, commonDataProps, formId }) {
    let deliveredStatus = longData[0]['status'] === 'Delivered';
    const [searchTerm, setSearchTerm] = useState('');
    const [compositionOptions, setCompositionOptions] = useState(null);
    const [filteredCompositionOptions, setFilteredCompositionOptions] = useState(null);

    useEffect(() => {
        if (searchTerm) {
            const filtered = compositionOptions.filter((option) => {
                return option.sku.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredCompositionOptions(filtered);
        }
    }, [searchTerm, longData, subTableId, compositionOptions]);

    useEffect(() => {
        fetchOptions('/composition_options', token).then((response) => {
            setCompositionOptions(response);
            setFilteredCompositionOptions(response);
        });
    }, [token]);

    let inputVariables = {
        infoArray: editSubTableData.infoArray,
        formId: formId,
    };

    const defaultData = JSON.stringify(JSON.parse(longData[0][subTableId]));

    return (
        <>
            <table key={subTableId + 'Table'} className="longSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={header + '_header'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray && longData[0][subTableId] ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return (
                                <tr key={'content_' + r}>
                                    {retrieveSubTableHeaderKeys(subTableId).map((key, k) => {
                                        return (
                                            <th
                                                className={
                                                    defaultData.includes(JSON.stringify(row))
                                                        ? 'subTableEntry'
                                                        : 'subTableEntryAdded'
                                                }
                                                key={r + k + '_added'}
                                            >
                                                {row[key]}
                                            </th>
                                        );
                                    })}
                                    <th className="deleteButtonContainer">
                                        <button
                                            className="deleteButtonSubTable"
                                            type="button"
                                            onClick={(e) =>
                                                clearAdded(e, {
                                                    index: r,
                                                    arrayToRemoveFrom: editSubTableData.infoArray,
                                                    setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                    setKeys: editSubTableData.setKeys,
                                                    setSubTableFormData:
                                                        editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                                    mode: 'multi-json',
                                                })
                                            }
                                        >
                                            X
                                        </button>
                                    </th>
                                </tr>
                            );
                        })
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    {
                        <tr key={parseTitle(subTableId)[1] + 'Content'}>
                            <th className="subTableAddNew" key="numberInput" name="number">
                                {editSubTableData.infoArray ? editSubTableData.infoArray.length + 1 : 1}
                            </th>
                            {/* Add SKU */}
                            <th className="subTableAddNew" key="skuInput">
                                <DropdownWithSearchBar
                                    addNewTitlesValues={editSubTableData.addNewTitlesValues}
                                    setAddNewTitlesValues={editSubTableData.setAddNewTitlesValues}
                                    options={filteredCompositionOptions}
                                    subTableId={subTableId}
                                    setSearchTerm={setSearchTerm}
                                    identifier="sku"
                                    commonDataProps={commonDataProps}
                                    inputVariables={inputVariables}
                                    mode="mixed"
                                />
                            </th>
                            {/* Add quantity */}
                            <th className="subTableAddNew" key="quantityInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    type="number"
                                    step="1"
                                    min="1"
                                    placeholder={'0'}
                                    name="quantity"
                                ></input>
                            </th>
                            {/* Purchase Price */}
                            <th className="subTableAddNew" key="purchasePriceInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    type="number"
                                    step="0.01"
                                    placeholder={'0'}
                                    name="purchasePrice"
                                ></input>
                            </th>
                            {/* Line total */}
                            <th className="subTableAddNew" key="lineTotalInput">
                                {
                                    <input
                                        className="subTableAddNewInput"
                                        type="number"
                                        step="0.01"
                                        placeholder={'0'}
                                        name="lineTotal"
                                        readOnly
                                        value={
                                            editSubTableData.addNewTitlesValues
                                                ? editSubTableData.addNewTitlesValues[0]['lineTotal']
                                                : 'N/A'
                                        }
                                    ></input>
                                }
                            </th>
                            {/* Packaging */}
                            <th className="subTableAddNew" key="packagingInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    placeholder={'e.g. 4/1'}
                                    name="packaging"
                                ></input>
                            </th>
                            {/* Carton Height */}
                            <th className="subTableAddNew" key="cartonHeightInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    placeholder={'0'}
                                    name="cartonHeight"
                                ></input>
                            </th>
                            {/* Carton Length */}
                            <th className="subTableAddNew" key="cartonLengthInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    type="number"
                                    step="0.01"
                                    placeholder={'0'}
                                    name="cartonLength"
                                ></input>
                            </th>
                            {/* Carton Width */}
                            <th className="subTableAddNew" key="cartonWidthInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    type="number"
                                    step="0.01"
                                    placeholder={'0'}
                                    name="cartonWidth"
                                ></input>
                            </th>
                            {/* Carton Weight */}
                            <th className="subTableAddNew" key="cartonWeightInput">
                                <input
                                    className="subTableAddNewInput"
                                    onInput={(e) =>
                                        handleMultiTitlesValues(e, {
                                            addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                            setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                            subTableId: subTableId,
                                            mode: 'mixed',
                                            inputVariables: inputVariables,
                                        })
                                    }
                                    type="number"
                                    step="0.01"
                                    placeholder={'0'}
                                    name="cartonWeight"
                                ></input>
                            </th>
                            {/* CBM */}
                            <th className="subTableAddNew" key="cbmInput">
                                {editSubTableData.addNewTitlesValues
                                    ? editSubTableData.addNewTitlesValues[0]['cbm']
                                    : 'N/A'}
                            </th>
                            <th className="subTableAddNewButton" key="addInput">
                                <button
                                    key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                    type="button"
                                    className={deliveredStatus ? 'disabledButton' : 'editButton'}
                                    onClick={(e) =>
                                        joinArray(e, {
                                            arrayToAdd: editSubTableData.addNewTitlesValues,
                                            arrayToAddTo: editSubTableData.infoArray,
                                            setArrayToAddTo: editSubTableData.setInfoArray,
                                            setKeys: editSubTableData.setKeys,
                                            setSubTableFormData:
                                                editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                            mode: 'multi-json',
                                        })
                                    }
                                    disabled={deliveredStatus}
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </th>
                            <th className="subTableAddNewButton" key="resetInput">
                                <button
                                    key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                    type="button"
                                    className={deliveredStatus ? 'disabledButton' : 'editButton'}
                                    disabled={deliveredStatus}
                                    title="Clear input row."
                                    onClick={(e) => handleInfoClear(e)}
                                >
                                    <i className="fas fa-broom" aria-hidden="true"></i>
                                </button>
                            </th>
                        </tr>
                    }
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//WAREHOUSE CONTACT SUBTABLE
function WarehouseContactSubTable({ longData, subTableId, editSubTableData, token, formId }) {
    const defaultData = JSON.stringify(JSON.parse(longData[0][subTableId]));
    let inputVariables = {
        infoArray: editSubTableData.infoArray,
        formId: formId,
    };

    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + i + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray ? (
                        longData[0][subTableId] !== null ? (
                            //check if longData[0][subTableId] is not null before returning
                            editSubTableData.infoArray.map((row, r) => {
                                return (
                                    <tr key={'content_' + r}>
                                        {retrieveSubTableHeaderKeys(subTableId).map((key, k) => {
                                            return (
                                                <th
                                                    className={
                                                        defaultData.includes(JSON.stringify(row))
                                                            ? 'subTableEntry'
                                                            : 'subTableEntryAdded'
                                                    }
                                                    key={k + '_added'}
                                                >
                                                    {row[key]}
                                                </th>
                                            );
                                        })}
                                        <th className="deleteButtonContainer">
                                            <button
                                                className="deleteButtonSubTable"
                                                type="button"
                                                onClick={(e) =>
                                                    clearAdded(e, {
                                                        index: r,
                                                        arrayToRemoveFrom: editSubTableData.infoArray,
                                                        setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                        setKeys: editSubTableData.setKeys,
                                                        setSubTableFormData:
                                                            editSubTableData.subTableCommonDataProps
                                                                .setSubTableFormData,
                                                        mode: 'multi-json',
                                                    })
                                                }
                                            >
                                                X
                                            </button>
                                        </th>
                                    </tr>
                                );
                            })
                        ) : (
                            ''
                        )
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Add new name...'}
                                name="name"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        mode: 'mixed',
                                        inputVariables: inputVariables,
                                    })
                                }
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        mode: 'mixed',
                                        inputVariables: inputVariables,
                                    })
                                }
                                name="role"
                                placeholder={'Add new role...'}
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        mode: 'mixed',
                                        inputVariables: inputVariables,
                                    })
                                }
                                name="email"
                                placeholder={'Add new email...'}
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        mode: 'mixed',
                                        inputVariables: inputVariables,
                                    })
                                }
                                placeholder={'Add new phone...'}
                                name="phone"
                            ></input>
                        </th>
                        <th className="subTableAddNewButton" key="addInput">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                onClick={(e) =>
                                    joinArray(e, {
                                        arrayToAdd: editSubTableData.addNewTitlesValues,
                                        arrayToAddTo: editSubTableData.infoArray,
                                        setArrayToAddTo: editSubTableData.setInfoArray,
                                        setKeys: editSubTableData.setKeys,
                                        setSubTableFormData:
                                            editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                        mode: 'multi-json',
                                    })
                                }
                                title="Add a new row to table."
                                className="editButton"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton" key="resetInput">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                onClick={() => editSubTableData.resetArray()}
                                className="editButton"
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//VARIATION SERVICE SUBTABLE
function VariationSubTable({ longData, subTableId, editSubTableData, token }) {
    const [filteredCompositionOptions, setFilteredCompositionOptions] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [compositionOptions, setCompositionOptions] = useState(null);

    useEffect(() => {
        if (searchTerm) {
            const filtered = compositionOptions.filter((option) => {
                return option.sku.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredCompositionOptions(filtered);
        }
    }, [searchTerm, longData, subTableId, compositionOptions]);

    useEffect(() => {
        fetchOptions('/composition_options', token).then((response) => {
            setCompositionOptions(response);
            setFilteredCompositionOptions(response);
            console.log(response);
        });
    }, [token]);

    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray ? (
                        longData[0][subTableId] !== null ? (
                            editSubTableData.keys.map((key, k) => {
                                if (editSubTableData.defaultKeys.includes(key)) {
                                    return (
                                        <tr key={subTableId + 'Content_' + k}>
                                            <th className="subTableEntry" key={key + '_keyAdded'}>
                                                {key}
                                            </th>
                                            <th className="subTableEntry" key={editSubTableData[key] + '_valueAdded'}>
                                                {editSubTableData.infoArray[key]}
                                            </th>
                                            <th className="deleteButtonContainer">
                                                <button
                                                    className="deleteButtonSubTable"
                                                    type="button"
                                                    onClick={(e) => editSubTableData.clearAdded(e, k)}
                                                >
                                                    X
                                                </button>
                                            </th>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={subTableId + 'Content_' + k}>
                                            <th className="subTableEntryAdded" key={key + '_keyAdded'}>
                                                {key}
                                            </th>
                                            <th
                                                className="subTableEntryAdded"
                                                key={editSubTableData[key] + '_valueAdded'}
                                            >
                                                {editSubTableData.infoArray[key]}
                                            </th>
                                            <th className="deleteButtonContainer">
                                                <button
                                                    className="deleteButtonSubTable"
                                                    type="button"
                                                    onClick={(e) => editSubTableData.clearAdded(e, k)}
                                                >
                                                    X
                                                </button>
                                            </th>
                                        </tr>
                                    );
                                }
                            })
                        ) : (
                            editSubTableData.keys.map((key, i) => {
                                return (
                                    <tr key={subTableId + 'Content_' + i}>
                                        <>
                                            <th className="subTableEntryAdded" key={key + '_keyAdded'}>
                                                {key}
                                            </th>
                                            <th
                                                className="subTableEntryAdded"
                                                key={editSubTableData[key] + '_valueAdded'}
                                            >
                                                {editSubTableData.infoArray[key]}
                                            </th>
                                            <th className="deleteButtonContainer">
                                                <button
                                                    className="deleteButtonSubTable"
                                                    type="button"
                                                    onClick={(e) => editSubTableData.clearAdded(e, i)}
                                                >
                                                    X
                                                </button>
                                            </th>
                                        </>
                                    </tr>
                                );
                            })
                        )
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <DropdownWithSearchBar
                                options={filteredCompositionOptions}
                                handleMultiTitlesValues={editSubTableData.handleMultiTitlesValues}
                                subTableId={subTableId}
                                setSearchTerm={setSearchTerm}
                                identifier={'sku'}
                            />
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) => editSubTableData.handleMultiTitlesValues(e)}
                                placeholder={'Add new ' + parseTitle(subTableId)[1] + '...'}
                            ></input>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                title="Add a new row to table."
                                onClick={(e) => editSubTableData.joinMultiArray(e)}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                className="editButton"
                                title="Clear input row."
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//SHIPPING SERVICE SUBTABLE
function ShippingServiceSubTable({ longData, subTableId, editSubTableData, token }) {
    const [filteredSkuOptions, setFilteredSkuOptions] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [warehouseOptions, setWarehouseOptions] = useState('');
    const [courierOptions, setCourierOptions] = useState('');
    const [selectedCourier, setSelectedCourier] = useState('');
    const [shippingServiceOptions, setShippingServiceOptions] = useState('');
    const [compositeCheck, setCompositeCheck] = useState(false);

    useEffect(() => {
        if (longData[0]['type'] === 'Base_SKU' || longData[0]['type'] === 'Component_SKU') {
            let tempSkuList = [longData[0]['sku']];
            setFilteredSkuOptions(tempSkuList);
        } else if (longData[0]['type'] === 'Composite_SKU') {
            let parsedCompositionList = JSON.parse(longData[0]['composition']);
            let tempSkuList = Object.keys(parsedCompositionList);
            setFilteredSkuOptions(tempSkuList);
        }
    }, [longData]);

    useEffect(() => {
        fetchOptions('/warehouse_management', token).then((response) => {
            let filteredResponse = response.map((row, r) => {
                return row['warehouseName'];
            });
            setWarehouseOptions(filteredResponse);
        });
    }, [token]);

    useEffect(() => {
        fetchOptions('/courier_management', token).then((response) => {
            setCourierOptions(response);
        });
    }, [token]);

    useEffect(() => {
        let filteredServiceOptions = [];
        console.log(selectedCourier);
        if (selectedCourier) {
            filteredServiceOptions = courierOptions.filter((courier, c) => {
                console.log(courier);
                return courier['courier'] === selectedCourier;
            });

            setShippingServiceOptions(filteredServiceOptions);
        }
        console.log(filteredServiceOptions);
    }, [token, courierOptions, selectedCourier]);

    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray ? (
                        longData[0][subTableId] !== null ? (
                            editSubTableData.infoArray.map((row, r) => {
                                return (
                                    <tr key={subTableId + 'Content_' + r}>
                                        {retrieveSubTableHeaderKeys(subTableId).map((key, k) => {
                                            return (
                                                <th
                                                    className={
                                                        editSubTableData.defaultKeys.includes(r.toString())
                                                            ? 'subTableEntry'
                                                            : 'subTableEntryAdded'
                                                    }
                                                    key={key + r + k + '_keyAdded'}
                                                >
                                                    {row[key]}
                                                </th>
                                            );
                                        })}
                                        <th className="deleteButtonContainer">
                                            <button
                                                className="deleteButtonSubTable"
                                                type="button"
                                                onClick={(e) => editSubTableData.clearAdded(e, r)}
                                            >
                                                X
                                            </button>
                                        </th>
                                    </tr>
                                );
                            })
                        ) : (
                            editSubTableData.keys.map((key, i) => {
                                return (
                                    <tr key={subTableId + 'Content_' + i}>
                                        <>
                                            <th className="subTableEntryAdded" key={key + i + '_keyAdded'}>
                                                {key}
                                            </th>
                                            <th
                                                className="subTableEntryAdded"
                                                key={editSubTableData.infoArray[key] + i + '_valueAdded'}
                                            >
                                                {editSubTableData.infoArray[key]}
                                            </th>
                                            <th className="deleteButtonContainer" key={'deleteButton' + i}>
                                                <button
                                                    className="deleteButtonSubTable"
                                                    type="button"
                                                    onClick={(e) => editSubTableData.clearAdded(e, i)}
                                                >
                                                    X
                                                </button>
                                            </th>
                                        </>
                                    </tr>
                                );
                            })
                        )
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <DropdownWithoutSearchBar
                                options={filteredSkuOptions}
                                handleMultiTitlesValues={(e) => editSubTableData.handleMultiTitlesValues(e, 1)}
                                subTableId={subTableId}
                                identifier="sku"
                            />
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) => editSubTableData.handleMultiTitlesValues(e, 1)}
                                placeholder={'0'}
                                name="quantity"
                                type="number"
                                step={1}
                                // required
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <DropdownWithoutSearchBar
                                options={warehouseOptions}
                                handleMultiTitlesValues={(e) => editSubTableData.handleMultiTitlesValues(e, 1)}
                                subTableId={subTableId}
                                identifier="warehouse"
                            />
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) => editSubTableData.handleMultiTitlesValues(e, 1)}
                                placeholder={'0'}
                                name="shippingPrice"
                                type="number"
                                step={0.01}
                                // required
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <DropdownWithSearchBar
                                options={courierOptions}
                                handleMultiTitlesValues={(e) => editSubTableData.handleMultiTitlesValues(e, 1)}
                                subTableId={subTableId}
                                setSearchTerm={setSearchTerm}
                                identifier={'courier'}
                                setSubFilterList={setSelectedCourier}
                            />
                        </th>
                        <th className="subTableAddNew">
                            <DropdownWithSearchBar
                                options={shippingServiceOptions}
                                handleMultiTitlesValues={(e) => editSubTableData.handleMultiTitlesValues(e, 1)}
                                subTableId={subTableId}
                                setSearchTerm={setSearchTerm}
                                identifier={'shippingService'}
                            />
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                onClick={(e) => editSubTableData.joinMultiArray(e)}
                                title="Add a new row to table."
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                className="editButton"
                                title="Clear input row."
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//SELLING PRICE SUBTABLE
function SellingPriceSubTable({
    longData,
    subTableId,
    editSubTableData,
    token,
    selectedWarehouse,
    addNewTitlesValues,
    formId,
}) {
    const [filteredChannelOptions, setFilteredChannelOptions] = useState(null);
    const [channelOptions, setChannelOptions] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [purchasePrice, setCurrentPurchasePrice] = useState(0);

    useEffect(() => {
        if (channelOptions && editSubTableData.infoArray) {
            let jsonChannelList = editSubTableData.infoArray.map((row, r) => {
                return row['channelName'];
            });

            let tempfilteredChannelOptions = channelOptions.filter((channel, c) => {
                return !jsonChannelList.includes(channel['channelName']);
            });
            setFilteredChannelOptions(tempfilteredChannelOptions);
        }
    }, [channelOptions, editSubTableData.infoArray]);

    useEffect(() => {
        fetchOptions('/channel_management', token).then((response) => {
            setChannelOptions(response);
            setFilteredChannelOptions(response);
        });
    }, [token]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = channelOptions.filter((option) => {
                return option.channelName.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredChannelOptions(filtered);
        }
    }, [searchTerm, longData, subTableId, channelOptions]);

    useEffect(() => {
        if (longData && selectedWarehouse) {
            setCurrentPurchasePrice(JSON.parse(longData[0]['purchasePrice'])[0][selectedWarehouse]);
        }
    }, [longData, selectedWarehouse]);

    let inputVariables = {
        infoArray: editSubTableData.infoArray,
        formId: formId,
        purchasePrice: purchasePrice,
    };

    const defaultData = JSON.stringify(JSON.parse(longData[0][subTableId]));

    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray && longData[0][subTableId] !== null ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return (
                                <tr key={'content_' + r}>
                                    {retrieveSubTableHeaderKeys(subTableId).map((key, k) => {
                                        if (defaultData.includes(JSON.stringify(row))) {
                                            return (
                                                <th className="subTableEntry" key={r + k + '_added'}>
                                                    {row[key]}
                                                </th>
                                            );
                                        } else {
                                            return (
                                                <th className="subTableEntryAdded" key={r + k + '_added'}>
                                                    {row[key]}
                                                </th>
                                            );
                                        }
                                    })}
                                    <th className="deleteButtonContainer">
                                        <button
                                            className="deleteButtonSubTable"
                                            type="button"
                                            onClick={(e) =>
                                                clearAdded(e, {
                                                    index: r,
                                                    arrayToRemoveFrom: editSubTableData.infoArray,
                                                    setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                    setKeys: editSubTableData.setKeys,
                                                    setSubTableFormData:
                                                        editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                                    mode: 'multi-json',
                                                })
                                            }
                                        >
                                            X
                                        </button>
                                    </th>
                                </tr>
                            );
                        })
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew">
                            <DropdownWithSearchBar
                                options={filteredChannelOptions}
                                addNewTitlesValues={editSubTableData.addNewTitlesValues}
                                setAddNewTitlesValues={editSubTableData.setAddNewTitlesValues}
                                subTableId={subTableId}
                                identifier="channelName"
                                setSearchTerm={setSearchTerm}
                                selectedWarehouse={selectedWarehouse}
                                inputVariables={inputVariables}
                                mode="mixed"
                            />
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Add new...'}
                                name="purchasePrice"
                                type="number"
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['purchasePrice']
                                        : 'N/A'
                                }
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'Add new...'}
                                name="sellingPrice"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'0'}
                                name="platformCommission"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'0'}
                                name="shippingPrice"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Calculating...'}
                                name="vat"
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['vat']
                                        : 'N/A'
                                }
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Calculating...'}
                                name="profit"
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['profit']
                                        : 'N/A'
                                }
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Calculating...'}
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['profitMargin']
                                        : 'N/A'
                                }
                                name="profitMargin"
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                title="Add a new row to table."
                                onClick={(e) =>
                                    joinArray(e, {
                                        arrayToAdd: editSubTableData.addNewTitlesValues,
                                        arrayToAddTo: editSubTableData.infoArray,
                                        setArrayToAddTo: editSubTableData.setInfoArray,
                                        setKeys: editSubTableData.setKeys,
                                        setSubTableFormData:
                                            editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                        mode: 'multi-json',
                                    })
                                }
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                className="editButton"
                                title="Clear input row."
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}

//ORDER ITEMS SUBTABLE
function OrderItemsSubTable({ longData, subTableId, editSubTableData, token, selectedWarehouse, formId }) {
    const [inventoryOptions, setInventoryOptions] = useState(null);
    const [filteredInventoryOptions, setFilteredInventoryOptions] = useState('');
    const [sellingPriceArray, setSellingPriceArray] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSku, setSelectedSku] = useState('');
    const [currentPurchasePrice, setCurrentPurchasePrice] = useState(0);
    const [currentImageLink, setCurrentImageLink] = useState('');

    let inputVariables = {
        infoArray: editSubTableData.infoArray,
        formId: formId,
        purchasePrice: currentPurchasePrice,
        imageLink: currentImageLink,
        sku: selectedSku,
    };

    useEffect(() => {
        fetchOptions('/inventory_management', token).then((response) => {
            setInventoryOptions(response);
            setFilteredInventoryOptions(response);
            console.log(response);
        });
    }, [token]);

    useEffect(() => {
        if (inventoryOptions) {
            const filtered = inventoryOptions.filter((option) => {
                return option.sku.toLowerCase().includes(searchTerm.toLowerCase());
            });
            setFilteredInventoryOptions(filtered);
        }
    }, [searchTerm, inventoryOptions]);

    useEffect(() => {
        let filteredServiceOptions = [];
        if (selectedSku) {
            filteredServiceOptions = inventoryOptions.filter((sku, s) => {
                return sku['sku'] === selectedSku;
            });
            let tempPurchasePrice = JSON.parse(filteredServiceOptions[0]['purchasePrice'])[selectedWarehouse].toFixed(
                2,
            );
            let tempImageLink = JSON.parse(filteredServiceOptions[0]['imagesLinks'])[0];
            console.log(tempPurchasePrice);
            setCurrentPurchasePrice(tempPurchasePrice);
            setCurrentImageLink(tempImageLink);
        }
    }, [token, inventoryOptions, selectedSku, selectedWarehouse]);

    const defaultData = JSON.stringify(JSON.parse(longData[0][subTableId]));
    console.log(defaultData);

    return (
        <>
            <table key={subTableId + 'Table'} className="shortSubTableInfoTable">
                <tbody>
                    {/* Header row */}
                    <tr key={subTableId + 'Headers'}>
                        {retrieveSubTableHeaders(subTableId).map((header, i) => {
                            return (
                                <th className="subTableHeader" key={subTableId + '_' + header + 'Column'}>
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                    {/* Body row */}
                    {editSubTableData.infoArray && longData[0][subTableId] !== null ? (
                        editSubTableData.infoArray.map((row, r) => {
                            return (
                                <tr key={'content_' + r}>
                                    {retrieveSubTableHeaderKeys(subTableId).map((key, k) => {
                                        console.log(JSON.stringify(row));
                                        if (defaultData.includes(JSON.stringify(row))) {
                                            if (key === 'image') {
                                                return (
                                                    <th className="subTableEntry" key={r + k + '_added'}>
                                                        <img
                                                            className="thumbnailImage"
                                                            alt="lead_image"
                                                            src={row[key]}
                                                        ></img>
                                                    </th>
                                                );
                                            } else {
                                                return (
                                                    <th className="subTableEntry" key={r + k + '_added'}>
                                                        {row[key]}
                                                    </th>
                                                );
                                            }
                                        } else {
                                            if (key === 'image') {
                                                return (
                                                    <th className="subTableEntryAdded" key={r + k + '_added'}>
                                                        <img
                                                            className="thumbnailImage"
                                                            alt="lead_image"
                                                            src={row[key]}
                                                        ></img>
                                                    </th>
                                                );
                                            } else {
                                                return (
                                                    <th className="subTableEntryAdded" key={r + k + '_added'}>
                                                        {row[key]}
                                                    </th>
                                                );
                                            }
                                        }
                                    })}
                                    <th className="deleteButtonContainer">
                                        <button
                                            className="deleteButtonSubTable"
                                            type="button"
                                            onClick={(e) =>
                                                clearAdded(e, {
                                                    index: r,
                                                    arrayToRemoveFrom: editSubTableData.infoArray,
                                                    setArrayToRemoveFrom: editSubTableData.setInfoArray,
                                                    setKeys: editSubTableData.setKeys,
                                                    setSubTableFormData:
                                                        editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                                    mode: 'multi-json',
                                                })
                                            }
                                        >
                                            X
                                        </button>
                                    </th>
                                </tr>
                            );
                        })
                    ) : (
                        <tr key={subTableId + '_null'}>
                            {retrieveSubTableHeaders(subTableId).map((header, i) => {
                                return <th key={subTableId + '_null_' + i}>No data yet.</th>;
                            })}
                        </tr>
                    )}
                    {/* Input row */}
                    <tr key={parseTitle(subTableId)[1] + 'Content'}>
                        <th className="subTableAddNew" key="addImage">
                            <img className="thumbnailImage" alt="lead_image" src={currentImageLink}></img>
                        </th>
                        <th className="subTableAddNew" key="addSku">
                            <DropdownWithSearchBarAndStock
                                options={filteredInventoryOptions}
                                addNewTitlesValues={editSubTableData.addNewTitlesValues}
                                setAddNewTitlesValues={editSubTableData.setAddNewTitlesValues}
                                subTableId={subTableId}
                                identifier="sku"
                                setSearchTerm={setSearchTerm}
                                setSubFilterList={setSelectedSku}
                                selectedWarehouse={selectedWarehouse}
                                inputVariables={inputVariables}
                                mode="mixed"
                            />
                        </th>
                        <th className="subTableAddNew" key="addQuantity">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'Add new...'}
                                name="quantity"
                                type="number"
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addPurchasePrice">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Add new...'}
                                name="purchasePrice"
                                type="number"
                                value={currentPurchasePrice}
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addSellingPrice">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'Add new...'}
                                name="sellingPrice"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addPlatformCommission">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'0'}
                                name="platformCommission"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addShippingPrice">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'0'}
                                name="shippingPrice"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addVat">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'0'}
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['vat']
                                        : 'N/A'
                                }
                                name="vat"
                                type="number"
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addOtherCommission">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'0'}
                                name="otherCommission"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addDiscount">
                            <input
                                className="subTableAddNewInput"
                                onInput={(e) =>
                                    handleMultiTitlesValues(e, {
                                        addNewTitlesValues: editSubTableData.addNewTitlesValues,
                                        setAddNewTitlesValues: editSubTableData.setAddNewTitlesValues,
                                        subTableId: subTableId,
                                        inputVariables: inputVariables,
                                        mode: 'mixed',
                                    })
                                }
                                placeholder={'0'}
                                name="discount"
                                type="number"
                                required
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addProfit">
                            <input
                                className="subTableAddNewInput"
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['profit']
                                        : 'N/A'
                                }
                                placeholder={'Calculating...'}
                                name="profit"
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addProfitMargin">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Calculating...'}
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['profitMargin']
                                        : 'N/A'
                                }
                                name="profitMargin"
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addLineTotalNoVat">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Calculating...'}
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['lineTotalNoVat']
                                        : 'N/A'
                                }
                                name="lineTotalNoVat"
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNew" key="addLineTotalVat">
                            <input
                                className="subTableAddNewInput"
                                placeholder={'Calculating...'}
                                value={
                                    editSubTableData.addNewTitlesValues
                                        ? editSubTableData.addNewTitlesValues[0]['lineTotalVat']
                                        : 'N/A'
                                }
                                name="lineTotalVat"
                                readOnly
                            ></input>
                        </th>
                        <th className="subTableAddNewButton" key="addButton">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'AddNew'}
                                type="button"
                                className="editButton"
                                title="Add a new row to table."
                                onClick={(e) =>
                                    joinArray(e, {
                                        arrayToAdd: editSubTableData.addNewTitlesValues,
                                        arrayToAddTo: editSubTableData.infoArray,
                                        setArrayToAddTo: editSubTableData.setInfoArray,
                                        setKeys: editSubTableData.setKeys,
                                        setSubTableFormData:
                                            editSubTableData.subTableCommonDataProps.setSubTableFormData,
                                        mode: 'multi-json',
                                    })
                                }
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th className="subTableAddNewButton" key="reset">
                            <button
                                key={'edit' + parseTitle(subTableId)[2] + 'Reset'}
                                type="button"
                                className="editButton"
                                title="Clear input row."
                                onClick={(e) => handleInfoClear(e)}
                            >
                                <i className="fas fa-broom" aria-hidden="true"></i>
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ClearAndSave
                setInfoArray={editSubTableData.setInfoArray}
                setKeys={editSubTableData.setKeys}
                longData={longData}
                subTableId={subTableId}
                defaultKeys={editSubTableData.defaultKeys}
                setShowHideConfirmBox={editSubTableData.subTableCommonDataProps.setShowHideConfirmBox}
                setConfirmData={editSubTableData.subTableCommonDataProps.setConfirmData}
            />
        </>
    );
}
