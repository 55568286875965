import { useState, useEffect } from 'react';

const confirmDataList = Array.from(['deleteImageConfirmData', 'poStockInfo']);

export function ConfirmBox({
    formName,
    submitForm,
    showHideConfirmBox,
    setShowHideConfirmBox,
    longData,
    confirmData,
    subTableFormData,
    editTableFormData,
}) {
    const showHideClassName = showHideConfirmBox ? 'confirmBoxContainer' : 'modal display-none';

    const [originalString, setOriginalString] = useState('');
    const [newString, setNewString] = useState('');

    function hideConfirmBox() {
        setShowHideConfirmBox(false);
    }

    useEffect(() => {
        if (longData && longData[0][confirmData]) {
            setOriginalString(longData[0][confirmData].replace(/\s+/g, ''));
        }
    }, [longData, confirmData, subTableFormData]);

    useEffect(() => {
        if (subTableFormData) {
            setNewString(JSON.stringify(subTableFormData));
        } else if (editTableFormData) {
            setNewString(JSON.stringify(editTableFormData));
        }
    }, [subTableFormData, editTableFormData]);
    console.log(confirmData);

    if (confirmData.toLowerCase().includes('delete')) {
        return (
            <div className={showHideClassName} key="showHideClassNameConfirm">
                <div className="confirmBox-main">
                    <h1>Are you sure you want to delete image?</h1>
                    <div className="buttonContainer">
                        <button
                            type="submit"
                            form={formName}
                            onClick={(e) => submitForm(e, confirmData)}
                            className="standardButton"
                        >
                            Delete
                        </button>
                        <button type="button" onClick={hideConfirmBox} className="standardButton">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    } else if (subTableFormData && originalString !== newString) {
        return (
            <div className={showHideClassName} key="showHideClassNameConfirm">
                <div className="confirmBox-main">
                    <h1>Are you sure you want to save changes?</h1>
                    <div className="buttonContainer">
                        <button
                            type="submit"
                            form={formName}
                            onClick={(e) => submitForm(e, confirmData)}
                            className="standardButton"
                        >
                            Save
                        </button>
                        <button type="button" onClick={hideConfirmBox} className="standardButton">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    } else if (editTableFormData) {
        return (
            <div className={showHideClassName} key="showHideClassNameConfirm">
                <div className="confirmBox-main">
                    <h1>Are you sure you want to save changes?</h1>
                    <div className="buttonContainer">
                        <button
                            type="submit"
                            form={formName}
                            onClick={(e) => submitForm(e, confirmData)}
                            className="standardButton"
                        >
                            Save
                        </button>
                        <button type="button" onClick={hideConfirmBox} className="standardButton">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    } else if (confirmData === 'deliverPo') {
        return (
            <div className={showHideClassName} key="showHideClassNameConfirm">
                <div className="confirmBox-main">
                    <p>Are you sure you want to deliver PO? This cannot be undone.</p>
                    <p>Please check values again.</p>
                    <button type="button" onClick={hideConfirmBox} className="standardButton">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        form={formName}
                        onClick={(e) => submitForm(e, confirmData)}
                        className="standardButton"
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    } else if (confirmData === 'addAnnouncement') {
        console.log(confirmData);
        return (
            <div className={showHideClassName} key="showHideClassNameConfirm">
                <div className="confirmBox-main">
                    <p>Are you sure you want to post announcement?</p>
                    <button type="button" onClick={hideConfirmBox} className="standardButton">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        form={formName}
                        onClick={(e) => submitForm(e, confirmData)}
                        className="standardButton"
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    } else if ((!subTableFormData && !editTableFormData) || (originalString === newString && !editTableFormData)) {
        return (
            <div className={showHideClassName} key="showHideClassNameConfirm">
                <div className="confirmBox-main">
                    <h1>Error while savings changes</h1>
                    <div className="errorMessage" key="errorMessageContainer">
                        <i className="fa fa-exclamation-circle" aria-hidden="true" key="errorMessageIcon"></i>
                        <p>
                            <br></br>
                            There was an error in your request.<br></br>
                            Please make sure an edit was made before saving changes.<br></br>
                            <br></br>
                        </p>
                    </div>
                    <div className="buttonContainer">
                        <button type="button" onClick={hideConfirmBox} className="standardButton">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
