import { useState, useEffect, PureComponent } from 'react';
import moment from 'moment';
import { Spinner } from './spinner';
import { ErrorMessage } from './errorMessage';
import { parseSQLToTitles } from './parseSQLToTitles';
import { DropdownWithSearchBar } from './dropdownWithSearchBar';
import {
    PieChart,
    Pie,
    LineChart,
    Cell,
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
} from 'recharts';
const baseURL = process.env.REACT_APP_API_INVOKE_URL
const courierList = Array.from(['DHL', 'DPD', 'MXL', 'Panther', 'TNT', 'UPS', 'XDP', 'Yodel']);

const defectType = Array.from([
    'Chair seat',
    'Chair legs',
    'Table top',
    'Table legs',
    'Table base',
    'Missing screwpack',
    'Wrong colour in box',
    'Damaged in transit',
    'Others',
]);

const channelName = Array.from([
    'All About Chairs',
    'Amazon',
    'eBay',
    'Etsy',
    'Fruugo',
    'Manomano',
    'Mmilo Dropshipping',
    'Mmilo Trade',
    'onBuy',
    'Wayfair',
    'Wish',
]);

const claimType = Array.from([
    'Full order refund (inc. shipping)',
    'Partial order refund (inc. shipping)',
    'Full order refund (no shipping)',
    'Partial order refund (no shipping)',
    'Shipping cost only',
]);

const skuCategory = Array.from([
    'Accessory',
    'Barstool',
    'Bed',
    'Chair',
    'Children',
    'Lighting',
    'Sofa',
    'Table',
    'Other',
]);

const skuType = Array.from(['Base_SKU', 'Composite_SKU', 'Component_SKU']);

const supplierTypeList = Array.from(['General', 'Dropshipping']);

const viewByList = Array.from([
    'select...',
    'productSku',
    'defectType',
    'channelName',
    'claimType',
    'factory',
    'courier',
]);

export function AnalyticsPage({ showAnalytics, dataFetchId, analyticsFetchId, token }) {
    const classNameShowForm = showAnalytics ? 'PageDiv' : 'modal display-none';
    const [data, setData] = useState('');
    const [filteredData, setFilteredData] = useState('');
    const [historicalFilteredData, setHistoricalFilteredData] = useState('');
    const [dateData, setDateData] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [viewBy, setViewBy] = useState('');
    const [skuSearchTerm, setSkuSearchTerm] = useState(null);
    const [skuOptions, setSkuOptions] = useState(null);
    const [selectSku, setSelectSku] = useState(null);
    const [skuList, setSkuList] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [factoryList, setFactoryList] = useState('');

    useEffect(() => {
        setLoading(true);
        fetch(baseURL + dataFetchId, {
            credentials: 'include',
            method: 'GET',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage('Server responded with - ' + error);
                setLoading(false);
            });
    }, [dataFetchId]);

    useEffect(() => {
        let tempArray = '';

        if (data && fromDate && toDate && viewBy) {
            tempArray = data.filter((entry, i) => {
                const itemDate = new Date(entry.date);
                const year = itemDate.getFullYear();
                const month = String(itemDate.getMonth() + 1).padStart(2, '0');
                const day = String(itemDate.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                return formattedDate >= fromDate && formattedDate <= toDate;
            });

            setFilteredData(tempArray);
        }

        if (data && viewBy) {
            let viewByArray = retrieveViewBy(viewBy);

            if (viewBy === 'factory' && factoryList) {
                viewByArray = factoryList.map((entry, i) => {
                    return entry['factoryName'];
                });
            } else if (viewBy === 'productSku' && skuList) {
                viewByArray = skuList.map((entry, i) => {
                    return entry['sku'];
                });

                if (selectSku) {
                    tempArray = tempArray.filter((entry, i) => {
                        return entry['productSku'] === selectSku;
                    });
                    console.log(tempArray);

                    viewByArray = defectType;
                    console.log(viewByArray);
                }
            }

            const count = viewByArray.reduce((acc, type) => {
                acc[type] = 0;
                return acc;
            }, {});
            if (selectSku) {
                tempArray.forEach((entry) => {
                    count[entry['defectType']]++;
                });
            } else {
                tempArray.forEach((entry) => {
                    count[entry[viewBy]]++;
                });
            }

            tempArray = Object.entries(count).map(([name, value]) => ({ name, value }));

            tempArray = tempArray.filter((type, i) => {
                return type.value !== 0;
            });
            setFilteredData(tempArray);

            const historicalCount = viewByArray.reduce((acc, type) => {
                acc[type] = 0;
                return acc;
            }, {});
            let historicalTempArray = data;

            if (selectSku) {
                historicalTempArray = historicalTempArray.filter((entry, i) => {
                    return entry['productSku'] === selectSku;
                });
                historicalTempArray.forEach((entry) => {
                    historicalCount[entry['defectType']]++;
                });
            } else {
                historicalTempArray.forEach((entry) => {
                    historicalCount[entry[viewBy]]++;
                });
            }

            historicalTempArray = Object.entries(historicalCount).map(([name, value]) => ({ name, value }));
            historicalTempArray = historicalTempArray.filter((type, i) => {
                return type.value !== 0;
            });
            setHistoricalFilteredData(historicalTempArray);
        }
    }, [viewBy, data, fromDate, toDate, factoryList, skuList, selectSku]);

    useEffect(() => {
        if (viewBy === 'factory') {
            fetch('/factory_management')
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setFactoryList(data);
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        } else if (viewBy === 'productSku') {
            fetch('/inventory_management')
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setSkuList(data);
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        }
    }, [viewBy]);

    useEffect(() => {
        if (skuList && skuSearchTerm) {
            let tempArray = skuList.filter((entry, i) => {
                return entry['sku'].toLowerCase().includes(skuSearchTerm.toLowerCase());
            });
            console.log(tempArray);
            setSkuOptions(tempArray);
        } else if (skuList) {
            let tempArray = skuList.map((entry, j) => {
                return entry;
            });
            setSkuOptions(tempArray);
        }
    }, [skuSearchTerm, skuList]);

    function retrieveViewBy(viewBy) {
        if (viewBy === 'defectType') {
            return defectType;
        } else if (viewBy === 'channelName') {
            return channelName;
        } else if (viewBy === 'claimType') {
            return claimType;
        } else if (viewBy === 'courier') {
            return courierList;
        } else {
            return [];
        }
    }

    function handleSetViewBy(e) {
        setViewBy(e.target.value);
        if (e.target.value !== 'productSku') {
            setSelectSku(null);
            setSkuSearchTerm(null);
        }
    }

    function handleToDateChange(e) {
        setToDate(e.target.value);
    }

    function handleFromDateChange(e) {
        setFromDate(e.target.value);
    }

    if (data && data.length !== 0 && !errorMessage) {
        return (
            <div className={classNameShowForm} key="pageTitle">
                <h1 className="pageTitle" key="pageHeader">
                    {parseSQLToTitles(analyticsFetchId)}
                </h1>
                <div className="pieChartMenu" key="pieChartMenu">
                    <label htmlFor="fromDate">From:</label>
                    <input
                        type="date"
                        id="fromDate"
                        name="fromDate"
                        onChange={(e) => handleFromDateChange(e)}
                        max={toDate}
                    />
                    <label htmlFor="toDate">To:</label>
                    <input
                        type="date"
                        id="toDate"
                        name="toDate"
                        onChange={(e) => handleToDateChange(e)}
                        min={fromDate}
                    />
                    <label htmlFor="parameter">Parameter to view by:</label>
                    <select
                        placeholder="Please select a defect type."
                        className="dropdown"
                        name="viewBy"
                        id="viewBy"
                        onChange={(e) => handleSetViewBy(e)}
                        required
                    >
                        {viewByList.map((viewByOption, i) => (
                            <option value={viewByOption} key={i}>
                                {parseSQLToTitles(viewByOption)}
                            </option>
                        ))}
                    </select>
                    {viewBy === 'productSku' && skuOptions ? (
                        <DropdownWithSearchBar
                            options={skuOptions}
                            setSearchTerm={setSkuSearchTerm}
                            setAddNewTitle={setSelectSku}
                        />
                    ) : null}
                </div>
                <div className="pieChartContainer">
                    {filteredData && historicalFilteredData ? (
                        <PieChartContainer
                            filteredData={filteredData}
                            historicalFilteredData={historicalFilteredData}
                        />
                    ) : null}
                </div>
            </div>
        );
    } else if (data && data.length === 0 && !errorMessage) {
        return [<Spinner loading={loading} key="spinner" />];
    } else if (errorMessage) {
        return [
            <Spinner loading={loading} key="spinner" />,
            <ErrorMessage errorMessage={errorMessage} key="errorMessage" />,
        ];
    } else {
        return [
            <Spinner loading={loading} key="spinner" />,
            // <FetchingTable columnHeaders={columnHeaders} key="fetchingTable" />
        ];
    }
}

// const data01 = [
//     { name: 'Group A', value: 400 },
//     { name: 'Group B', value: 300 },
//     { name: 'Group C', value: 300 },
//     { name: 'Group D', value: 200 },
//   ];

const COLORS = [
    '#387dbe', // Light Blue
    '#ff931a', // Light Orange
    '#3fc53f', // Light Green
    '#d63b3b', // Light Red
    '#8e74c8', // Light Purple
    '#9e6d62', // Light Brown
    '#e77aa6', // Light Pink
    '#8a8a8a', // Light Gray
    '#b8c938', // Light Olive
];

export default function PieChartContainer({ filteredData, historicalFilteredData }) {
    return (
        <>
            <ResponsiveContainer width="100%" height={500}>
                <PieChart width={800} height={1000}>
                    <text fill="white" x={'25%'} y={'55%'} textAnchor="middle" dominantBaseline="middle">
                        Custom
                    </text>
                    <Pie
                        data={filteredData}
                        cx="25%"
                        cy="55%"
                        innerRadius={155}
                        outerRadius={180}
                        paddingAngle={1}
                        dataKey="value"
                        label={renderCustomizedLabel}
                        stroke="none"
                    >
                        {filteredData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <text fill="white" x={'75%'} y={'55%'} textAnchor="middle" dominantBaseline="middle">
                        Historical
                    </text>
                    <Pie
                        data={historicalFilteredData}
                        cx="75%"
                        cy="55%"
                        innerRadius={120}
                        outerRadius={145}
                        paddingAngle={1}
                        dataKey="value"
                        label={renderCustomizedLabel}
                        legendType="line"
                        stroke="none"
                    >
                        {historicalFilteredData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <br></br>
        </>
    );
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
    const radius = outerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'}>
            {name + `: ${(percent * 100).toFixed(0)}%, (${value})`}
        </text>
    );
};
