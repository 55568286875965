import { React, useEffect, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';

const processedOrdersHeaders = Array.from([
    'orderId',
    'date',
    'status',
    'customerName',
    'channelName',
    'orderItems',
    'warehouse',
    'total',
]);

const buttonList = Array.from(['processedOrdersTable']);

export function ProcessedOrdersButtons({ token, setToken, removeToken }) {
    const [activeTab, setActiveTab] = useState('processedOrdersTable');
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (activeTab === 'processedOrdersTable') {
            setShowForm(false);
            setShowTable(true);
        } else if (activeTab === 'newOrder') {
            setShowTable(false);
            setShowForm(true);
        }
    }, [activeTab]);

    return (
        <>
            <ButtonMenu buttonList={buttonList} setActiveTab={setActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={processedOrdersHeaders}
                dataFetchId="/processed_orders_management"
                key="processedOrdersTable"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={setActiveTab}
                formFetchId={activeTab}
                token={token}
                removeToken={removeToken}
            />
            ,
        </>
    );
}
