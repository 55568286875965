import { useState, useEffect } from 'react';
import { ConfirmBox } from './confirmBox';
import { Spinner } from './spinner';
import { handleInfoClear, formatMySQLDate } from './helperFunctions';
const baseURL = process.env.REACT_APP_API_INVOKE_URL

const announcementHeaders = Array.from([
    // "ID",
    'Date',
    'Title',
    'Announcement',
    'User',
    'Pin',
    'Delete',
]);

export function AnnouncementsBox({ token, setToken, removeToken, user }) {
    const [data, setData] = useState([]);
    const [titleInput, setTitleInput] = useState('');
    const [bodyInput, setBodyInput] = useState('');
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const [confirmData, setConfirmData] = useState('');
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(() => {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        let formattedDate = date.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
        return formattedDate;
    });
    const [toDate, setToDate] = useState(() => {
        let date = new Date();
        let formattedDate = date.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
        return formattedDate;
    });
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [pagination, setPagination] = useState();
    const [deleteIndex, setDeleteIndex] = useState();

    useEffect(() => {
        setLoading(true);
        fetch(`${baseURL}/announcements`, {
            credentials: 'include',
            method: 'GET',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    console.log(response.status);
                    if (response.status === 401) {
                        removeToken();
                        // setLoading(false);
                        // controller.abort()
                        //set error message
                    }

                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((res) => {
                setData(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [token, removeToken]);

    useEffect(() => {
        let tempArray = [];
        let dataLength = 5;
        //sort by date first
        if (data) {
            tempArray = data.sort((a, b) => {
                let first = String(a['date']);
                let second = String(b['date']);
                return second.localeCompare(first);
            });

            tempArray = tempArray.sort((a, b) => {
                let first = String(a['pin']);
                let second = String(b['pin']);

                return second.localeCompare(first);
            });

            tempArray = tempArray.filter((sortedEntry, i) => {
                let formattedDate = formatMySQLDate(sortedEntry['date']);
                if (!sortedEntry['pin']) {
                    return formattedDate >= fromDate && formattedDate <= toDate;
                } else {
                    return true;
                }
            });
        }

        const lastRowIndex = currentPage * rowsPerPage;
        const firstRowIndex = lastRowIndex - rowsPerPage;
        dataLength = tempArray.length;
        let currentRows = tempArray.slice(firstRowIndex, lastRowIndex);
        setFilteredData(currentRows);
        setPagination([...Array(Math.ceil(dataLength / rowsPerPage))]);
        setLoading(false);
    }, [data, toDate, fromDate, currentPage, rowsPerPage]);

    function handleAdd(e) {
        setLoading(true);
        const formData = new FormData();
        formData.append('title', titleInput);
        formData.append('announcement', bodyInput);
        formData.append('user', user);

        fetch(`${baseURL}/announcements`, {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
            }),
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                setLoading(false);
                setShowHideConfirmBox(false);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function handleDelete(e) {
        fetch(`${baseURL}/delete_announcement/` + deleteIndex, {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                setLoading(false);
                setShowHideConfirmBox(false);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    function handleToDateChange(e) {
        setToDate(e.target.value);
    }

    function handleFromDateChange(e) {
        setFromDate(e.target.value);
    }

    function handleRowClassChange(e) {
        console.log(e.target.parentNode.className);
        if (e.target.parentNode.className === 'announcementRows') {
            e.target.parentNode.className = 'announcementRowsSelected';
        } else if (e.target.parentNode.className === 'announcementRowsSelected') {
            e.target.parentNode.className = 'announcementRows';
        }
    }

    function handlePinIcon(pinStatus) {
        if (pinStatus) {
            return <i className="fas fa-solid fa-thumbtack selected"></i>;
        } else {
            return <i className="fas fa-solid fa-thumbtack"></i>;
        }
    }

    function handlePin(e, index) {
        setLoading(true);
        fetch(`${baseURL}/pin_announcement/` + index, {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                setLoading(false);
                setShowHideConfirmBox(false);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    function handleConfirm(e, confirmId) {
        e.preventDefault();
        setConfirmData(confirmId);
        if (confirmId === 'deleteAnnouncement') {
            setDeleteIndex(e.target.value);
        }
        setShowHideConfirmBox(true);
    }

    function submitForm(e, confirmData) {
        setLoading(true);

        if (confirmData === 'addAnnouncement') {
            handleAdd();
        } else if (confirmData === 'deleteAnnouncement' && deleteIndex) {
            handleDelete(e);
        }
    }

    if (filteredData && data.length !== 0) {
        return (
            <>
                <Spinner loading={loading} key="spinner-announcements" />
                <form
                    className="genericContainer"
                    key="announcementContainer"
                    name="announcements"
                    onSubmit={submitForm}
                >
                    <h1 className="modalTitle">Welcome to Mmilo Portal. Here's what's new: </h1>
                    <table className="announcementTable">
                        <tbody>
                            <tr className="announcementHeaders" key="announcementHeaders">
                                {announcementHeaders.map((row, i) => {
                                    return <th key={i + row}>{row}</th>;
                                })}
                            </tr>
                            {filteredData
                                ? filteredData.map((announcement, i) => (
                                      <tr
                                          onClick={(e) => handleRowClassChange(e)}
                                          className={'announcementRows'}
                                          key={'announcement.id_main' + i}
                                      >
                                          {/* <th key={'announcement.id_' + i} className="announcementBasic">{announcement["id"]}</th> */}
                                          <th key={'announcement.date_' + i} className="announcementTitle">
                                              {formatMySQLDate(announcement['date'])}
                                          </th>
                                          <th key={'announcement.title_' + i} className="announcementTitle">
                                              {announcement['title']}
                                          </th>
                                          <th key={'announcement.announcement_' + i} className="announcementText">
                                              {announcement['announcement']}
                                          </th>
                                          <th key={'announcement.user_' + i} className="announcementBasic">
                                              {announcement['user']}
                                          </th>
                                          <th
                                              key={'announcement.pin_' + i}
                                              className="announcementBasic"
                                              data-testid={'pin' + i}
                                              onClick={(e) => handlePin(e, announcement['id'])}
                                          >
                                              {handlePinIcon(announcement['pin'])}
                                          </th>
                                          <th key={'delete' + i} className="announcementBasic">
                                              <button
                                                  onClick={(e) => handleConfirm(e, 'deleteAnnouncement')}
                                                  className="deleteButton"
                                                  id={'buttonClear' + i}
                                                  value={announcement.id}
                                              >
                                                  X
                                              </button>
                                          </th>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                    <h6 htmlFor="inputTitle">Filter announcements by date: </h6>
                    <div className="dateContainer">
                        <input
                            className="filterDate"
                            type="date"
                            id="fromDate"
                            name="fromDate"
                            defaultValue={fromDate}
                            onChange={(e) => handleFromDateChange(e)}
                            max={toDate}
                        />
                        <p>to</p>
                        <input
                            className="filterDate"
                            type="date"
                            id="toDate"
                            name="toDate"
                            defaultValue={toDate}
                            onChange={(e) => handleToDateChange(e)}
                            min={fromDate}
                        />
                    </div>
                    <br></br>
                    <br></br>
                    <div className="selectPages" key="selectPages">
                        <select className="selectRows" onChange={(e) => setRowsPerPage(e.target.value)}>
                            <option value={25}>Number of rows: 25</option>
                            <option value={50}>Number of rows: 50</option>
                            <option value={75}>Number of rows: 75</option>
                            <option value={100}>Number of rows: 100</option>
                        </select>
                        <div className="totalEntries">
                            <p>Total entries: {data.length}</p>
                        </div>
                        <div className="pagination">
                            <button
                                href="#"
                                type="button"
                                onClick={() => {
                                    if (currentPage - 1 > 0) {
                                        setCurrentPage(currentPage - 1);
                                    }
                                }}
                            >
                                &laquo;
                            </button>
                            {pagination.map((_, i) => (
                                <button
                                    key={i}
                                    className={currentPage === i + 1 ? 'active' : ''}
                                    onClick={() => setCurrentPage(i + 1)}
                                    type="button"
                                    href="#"
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    if (currentPage + 1 <= pagination.length) {
                                        setCurrentPage(currentPage + 1);
                                    }
                                }}
                            >
                                &raquo;
                            </button>
                        </div>
                    </div>
                    <div className="addAnnouncement" id="addAnnouncement">
                        <h4>Input Title: </h4>
                        <input
                            name="titleInput"
                            className="announcementInput"
                            id="titleInput"
                            data-testid="titleInput-input"
                            placeholder="Enter announcement title..."
                            onChange={(e) => setTitleInput(e.target.value)}
                            required
                        ></input>
                        <br></br>
                        <h4>Input Message: </h4>
                        <textarea
                            name="bodyInput"
                            className="announcementInput"
                            id="bodyInput"
                            data-testid="bodyInput-input"
                            placeholder="Enter announcement here..."
                            onChange={(e) => setBodyInput(e.target.value)}
                            required
                        ></textarea>
                        <br></br>
                        <h5>Posting as {user}</h5>
                        <div className="buttonContainer">
                            <button
                                type="button"
                                className="standardButton"
                                id="buttonAdd"
                                onClick={(e) => handleConfirm(e, 'addAnnouncement')}
                            >
                                Post announcement
                            </button>
                            <button type="button" onClick={handleInfoClear} className="standardButton" id="buttonClear">
                                Clear
                            </button>
                        </div>
                        <ConfirmBox
                            showHideConfirmBox={showHideConfirmBox}
                            setShowHideConfirmBox={setShowHideConfirmBox}
                            formName="announcements"
                            submitForm={submitForm}
                            confirmData={confirmData}
                        />
                    </div>
                </form>
            </>
        );
    } else {
        return (
            <>
                <form className="genericContainer" key="announcementContainer" name="announcements">
                    <h1 className="modalTitle">Welcome to Mmilo Portal. Loading announcements...</h1>
                </form>
            </>
        );
    }
}
