export function ErrorMessage({ columnHeaders, errorMessage, login, bulkUpload }) {
    if (errorMessage) {
        console.log(errorMessage);
        if (columnHeaders) {
            return [
                <div className="TableDiv" key="columnHeaderKey3">
                    <table className="Table">
                        <tbody key="tbody1">
                            <tr className="titleRow">
                                {columnHeaders.map((header, i) => (
                                    <th key={i}>{header}</th>
                                ))}
                            </tr>
                            <tr className="itemRow">
                                {columnHeaders.map((header, i) => (
                                    <th key={header + i + 'nodata'}>No data found!</th>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <br></br>
                    <div className="errorMessage" key="errorMessageContainer">
                        <i className="fa fa-exclamation-circle" aria-hidden="true" key="errorMessageIcon"></i>
                        <p>
                            Sorry, there was an error with fetching data from the server.
                            <br></br>
                            Please try refreshing or contact your network administrator.
                            <br></br>
                            <br></br>
                            {errorMessage}
                        </p>
                    </div>
                </div>,
            ];
        } else if (login) {
            return (
                <>
                    <div className="errorMessage" key="errorMessageContainer">
                        <i className="fa fa-exclamation-circle" aria-hidden="true" key="errorMessageIcon"></i>
                        <p>
                            Sorry, we could not verify the login details entered.<br></br>
                            Please try again or contact your network administrator.<br></br>
                            {errorMessage}
                        </p>
                    </div>
                </>
            );
        } else if (bulkUpload) {
            return (
                <>
                    <div className="errorMessage" key="errorMessageContainer">
                        <i className="fa fa-exclamation-circle" aria-hidden="true" key="errorMessageIcon"></i>
                        <p>
                            Sorry, there was an error with uploading your .csv file.<br></br>
                            Please try again or contact your network administrator.<br></br>
                        </p>
                    </div>
                </>
            );
        } else {
            return [
                <div className="errorMessage" key="errorMessageContainer">
                    <i className="fa fa-exclamation-circle" aria-hidden="true" key="errorMessageIcon"></i>
                    <p>
                        Sorry, there was an error with fetching data from the server.<br></br>
                        Please try refreshing or contact your network administrator.<br></br>
                        <br></br>
                        {errorMessage}
                    </p>
                </div>,
            ];
        }
    }
}
