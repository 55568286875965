import { React, useEffect, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';

const purchaseOrdersHeaders = Array.from([
    'poNumber',
    'supplierReferenceNumber',
    'dateAdded',
    'status',
    'factoryName',
    'warehouse',
    'total',
]);

const buttonList = Array.from(['poTable', 'addPo']);

export function PurchaseOrdersButtons({ token, setToken, removeToken }) {
    const [activeTab, setActiveTab] = useState('inventoryTable');
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (activeTab === 'poTable') {
            setShowForm(false);
            setShowTable(true);
        } else if (activeTab === 'addPo') {
            setShowTable(false);
            setShowForm(true);
        }
    }, [activeTab]);

    return (
        <>
            <ButtonMenu buttonList={buttonList} setActiveTab={setActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={purchaseOrdersHeaders}
                dataFetchId="/purchase_orders"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={setActiveTab}
                formFetchId={activeTab}
                token={token}
                removeToken={removeToken}
            />
        </>
    );
}
