import { React, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';

const pricingHeaders = Array.from([
    'sku',
    'purchasePrice',
    'excludingWork',
    'cbm',
    'shippingPrice',
    'sellingPrice',
    'profit',
    'profitMargin',
]);

const buttonList = Array.from(['pricingTable']);

export function PricingButtons({ token, removeToken, setToken }) {
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);

    console.count('Rerender ');

    function handleActiveTab(activeTab) {
        if (activeTab === 'pricingTable') {
            setShowForm(false);
            setShowTable(true);
        }
    }

    return (
        <>
            <ButtonMenu buttonList={buttonList} key="pricingButtonMenu" setActiveTab={handleActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={pricingHeaders}
                dataFetchId="/pricing_management"
                token={token}
            />
            {/* <Form showForm={showForm} setActiveTab={handleActiveTab} formFetchId={"addWarehouse"} key="warehouseForm" />         */}
        </>
    );
}
