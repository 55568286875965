import { parseSQLToTitles } from './parseSQLToTitles';

export function ButtonMenu({ buttonList, setActiveTab }) {
    return (
        <>
            {buttonList.map((currentView, i) => (
                <button onClick={() => setActiveTab(currentView)} className="buttonMenu" key={currentView + '_' + i}>
                    {parseSQLToTitles(currentView)}
                </button>
            ))}
        </>
    );
}
