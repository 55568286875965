import { React, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';
import { AnalyticsPage } from './fetchAnalytics';
import { parseSQLToTitles } from './parseSQLToTitles';

const factoryHeaders = Array.from([
    'factoryName',
    'country',
    'contactName',
    'catalogue',
    'supplierType',
    'defectRate',
    'historicalDefectRate',
]);

const buttonList = Array.from(['factoryTable', 'addFactory', 'defectAnalytics']);

export function FactoryButtons({ token, setToken, removeToken }) {
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);

    function handleActiveTab(activeTab) {
        if (activeTab === 'factoryTable') {
            setShowForm(false);
            setShowAnalytics(false);
            setShowTable(true);
        } else if (activeTab === 'addFactory') {
            setShowTable(false);
            setShowAnalytics(false);
            setShowForm(true);
        } else if (activeTab === 'defectAnalytics') {
            setShowTable(false);
            setShowForm(false);
            setShowAnalytics(true);
        }
    }

    return (
        <>
            <ButtonMenu buttonList={buttonList} setActiveTab={handleActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={factoryHeaders}
                dataFetchId="/factory_management"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                formFetchId={'addFactory'}
                token={token}
                removeToken={removeToken}
                setActiveTab={handleActiveTab}
            />
            <AnalyticsPage
                showAnalytics={showAnalytics}
                analyticsFetchId={'defectAnalytics'}
                dataFetchId="/factory_defects"
                parseSQLToTitles={parseSQLToTitles}
                token={token}
                removeToken={removeToken}
            />
        </>
    );
}
