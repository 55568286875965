import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Spinner } from './spinner';
import { ErrorMessage } from './errorMessage';
import { SuccessMessage } from './successMessage';
const baseURL = process.env.REACT_APP_API_INVOKE_URL

export function ConfirmPassword({ token, setToken, removeToken, resetEmail, setResetToken }) {
    const [username, setUsername] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);

    function handleReset(e) {
        setLoading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('confirmPassword1', e.target['confirmPassword1'].value);
        formData.append('confirmPassword2', e.target['confirmPassword2'].value);
        fetch(`${baseURL}/confirm_reset_password/` + resetEmail, {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
            }),
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                setLoading(false);
                removeToken();
                setResetToken('');
                window.history.replaceState(null, 'New Page Title', '/');
                return Response.redirect('/', 303);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (newPassword1 !== '' && newPassword1 === newPassword2) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }, [newPassword1, newPassword2]);

    console.log('Reset password page rendered.');
    return (
        <>
            <>
                <Spinner loading={loading} />
                <div className="loginContainer">
                    <div className="backgroundImage" />
                    <section className="loginModal">
                        <form onSubmit={handleReset} className="modal-content">
                            <h1>Mmilo Portal</h1>
                            <h2>Confirm New Password</h2>
                            {successStatus ? (
                                <SuccessMessage successMessage={'Successfully changed password for user.'} />
                            ) : (
                                <>
                                    <label htmlFor="username">ENTER NEW PASSWORD</label>
                                    <input
                                        type="password"
                                        id="confirmPassword1"
                                        onChange={(e) => setNewPassword1(e.target.value)}
                                        value={newPassword1}
                                        placeholder={'NEW PASSWORD'}
                                        className="loginInput"
                                        required
                                    />
                                    <br></br>
                                    <label htmlFor="username">RE-ENTER NEW PASSWORD</label>
                                    <input
                                        type="password"
                                        id="confirmPassword2"
                                        onChange={(e) => setNewPassword2(e.target.value)}
                                        value={newPassword2}
                                        placeholder={'NEW PASSWORD'}
                                        className="loginInput"
                                        required
                                    />
                                    <br></br>
                                    {passwordMatch ? (
                                        <p className="match">PASSWORDS MATCH</p>
                                    ) : (
                                        <p className="noMatch">PASSWORDS DO NOT MATCH</p>
                                    )}
                                    <button type="submit" disabled={!passwordMatch}>
                                        CONFIRM CHANGE
                                    </button>
                                    <br></br>
                                    <br></br>
                                </>
                            )}
                        </form>
                    </section>
                    <ErrorMessage errorMessage={errorMessage} login={errorMessage.length > 0 ? true : false} />
                </div>
            </>
        </>
    );
}

export default ConfirmPassword;
