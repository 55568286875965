import { useState, useEffect } from 'react';
import { handleMultiTitlesValues, handleTitleValue } from './helperFunctions';

export function DropdownWithoutSearchBar({
    options,
    addNewTitlesValues,
    setAddNewTitlesValues,
    mode,
    subTableId,
    setSubFilterList,
    identifier,
    title,
    setTitle,
}) {
    const [hideDropdown, setHideDropdown] = useState(true);
    const wrapperClass = hideDropdown ? 'dropdownWrapperHidden' : 'dropdownWrapper';
    const [selectTitle, setSelectTitle] = useState('Select...');

    function handleDropdown() {
        if (hideDropdown === true) {
            setHideDropdown(false);
        } else {
            setHideDropdown(true);
        }
    }

    function handleSelect(e) {
        setSelectTitle(e.target.textContent);
        setHideDropdown(true);
        if (mode === 'dropdown-text') {
            handleTitleValue(e, {
                addNewTitlesValues: addNewTitlesValues,
                setAddNewTitlesValues: setAddNewTitlesValues,
                title: title,
                setTitle: setTitle,
                mode: mode,
            });
        } else {
            handleMultiTitlesValues(e, {
                addNewTitlesValues: addNewTitlesValues,
                setAddNewTitlesValues: setAddNewTitlesValues,
                mode: mode,
            });
        }

        if (setSubFilterList) {
            setSubFilterList(e.target.textContent);
        }
    }

    useEffect(() => {
        if (subTableId === 'fetchTableChannel') {
            setSelectTitle('All About Chairs');
        } else if (subTableId === 'fetchTableWarehouse') {
            setSelectTitle('Parcelship');
        }
    }, [subTableId]);

    if (options) {
        if (!identifier) {
            return (
                <>
                    <div className="dropdownButtonContainer" key={'dropdownButtonContainer' + subTableId}>
                        <button type="button" className="dropdownSubTableButton" onClick={(e) => handleDropdown(e)}>
                            {selectTitle ? selectTitle : 'Select...'}
                        </button>
                    </div>
                    <div className={wrapperClass} key={'contentWrapper' + subTableId}>
                        <ul className="dropdownContentExtend">
                            {options.map((option, i) => (
                                <li key={i} value={option} name={option} onClick={(e) => handleSelect(e)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="dropdownButtonContainer" key={'dropdownButtonContainer' + subTableId}>
                        <button type="button" className="dropdownSubTableButton" onClick={(e) => handleDropdown(e)}>
                            {selectTitle ? selectTitle : 'Select...'}
                        </button>
                    </div>
                    <div className={wrapperClass} key={'contentWrapper' + subTableId}>
                        <ul className="dropdownContentExtend">
                            {options.map((option, i) => {
                                return (
                                    <li
                                        key={i}
                                        value={option[identifier] ? option[identifier] : option}
                                        name={identifier}
                                        onClick={(e) => handleSelect(e)}
                                    >
                                        {option[identifier] ? option[identifier] : option}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            );
        }
    }
}
