import React, { useState, useEffect } from 'react';
import { Spinner } from './spinner';
import { BulkUploadNotification } from './bulkUploadNotification';
import { parseSQLToTitles } from './parseSQLToTitles';
import { DropdownWithoutSearchBar } from './dropdownWithoutSearchBar';
import { ErrorMessage } from './errorMessage';
const baseURL = process.env.REACT_APP_API_INVOKE_URLl
function findKey(obj, target) {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            if (obj[key].name === target) {
                return key;
            }
            const foundKey = findKey(obj[key], target);
            if (foundKey) return foundKey;
        }
    }
    return null;
}

export const CsvUpload = ({ showCSV, endpoint, csvId, token }) => {
    const [csvFile, setCsvFile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [data, setData] = useState({});
    const [selectedName, setSelectedName] = useState('');
    const [names, setNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState([]);
    const [returnCsvData, setReturnCsvData] = useState(null);
    const showHideClassName = showCSV ? 'modal display-block' : 'modal display-none';

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCsvFile(file);
    };

    useEffect(() => {
        // dont call the endpoint while its still blank
        if (endpoint === '') {
            // If endpoint is empty, don't make the API call
            return;
        }

        // Fetch data from the provided endpoint
        console.log('/bulk_upload' + endpoint);
        fetch(`${baseURL}/bulk_upload` + endpoint, {
            credentials: 'include',
            method: 'GET',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data);
                // Extract the names from the JSON response
                console.log(data);
                var names = [];
                for (const key in data) {
                    if (data[key].name) {
                        names.push(data[key].name);
                    }
                }
                setNames(names);
                console.log(names);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [endpoint, token]);

    const handleUpload = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', csvFile);

            // Simulated fetch call
            // Replace with actual fetch call to the specified endpoint
            console.log(endpoint);
            const endpointKey = endpoint;
            const target_endpoint = findKey(data, selectedName);
            const endpointUrl = `/bulk_upload/csv${endpoint}/${target_endpoint}`;
            console.log(data);
            console.log(endpointUrl);

            const response = await fetch(endpointUrl, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            });

            const responseData = await response.json();
            console.log('Checking response');
            console.log('Response Data:', responseData);
            setReturnCsvData(responseData.data);

            // Check if there are errors in the response
            if (responseData.errors && responseData.errors.length > 0) {
                // Handle the display of these errors
                setError(responseData.errors); // Assuming setError is a state setter for displaying errors
                console.log('Errors:', responseData.errors);
            } else {
                // Handle successful upload
                setApiResponse(responseData);
                console.log('CSV data uploaded successfully');
            }
        } catch (error) {
            // Handle fetch errors
            setError(error.message);
            console.error('Error uploading CSV:', error.message);
        } finally {
            setLoading(false);
        }

        //   if (!response.ok) {
        //     const errorResponse = await response.json();
        //     console.log(errorResponse)
        //     throw new Error(`Failed to upload CSV: ${errorResponse}`)

        //   }
        //   const responseData = await response.json();
        //   setApiResponse(responseData);
        //   console.log('CSV data uploaded successfully');
        //   // Additional actions after successful upload
        //   } catch (error) {
        //     setError(error)
        //   console.error('Error uploading CSV:', error.message);
        // }  finally {
        //   setLoading(false)
        // };
    };

    const handleDownload = async (selectedName) => {
        console.log(selectedName);
        console.log(endpoint);
        console.log(data);
        console.log(names);
        const target_endpoint = findKey(data, selectedName);
        console.log(target_endpoint);

        try {
            const response = await fetch(`${baseURL}/bulk_upload/csv${endpoint}/${target_endpoint}`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'data.csv';
            a.click();
            if (!response.ok) {
                throw new Error('Network response not ok');
            }
            const csvStrings = await response.json();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // useEffect(() => {
    //   if (csvFile) {
    //     handleUpload();
    //   }
    // }, [csvFile]);

    const handleClose = () => {
        // Reload the page on modal close
        window.location.reload();
        setIsModalOpen(false);
        // setActiveTab('inventoryTable');

        // Trigger the provided onClose function
    };

    const openGsheet = () => {
        const url = 'https://docs.google.com/spreadsheets/d/1mGQqxFdM1ktnNCttMl8GGvlTUJFl4HoPFvq-udxg9-Q/edit#gid=0'; // replace with your desired URL
        window.open(url, '_blank');
    };

    const handleReturnCSVDownload = () => {
        if (returnCsvData) {
            // Convert CSV data to blob
            const blob = new Blob([returnCsvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            // Create a link element to initiate the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'downloaded_file.csv');
            document.body.appendChild(link);
            link.click();

            // Clean up
            window.URL.revokeObjectURL(url);
        }
    };

    return (
        <div className={showHideClassName}>
            {isModalOpen && showCSV && (
                <div className="modal-main">
                    <div className="bulkUploads">
                        <div className="modalTitle">{parseSQLToTitles(csvId)}</div>
                        <label className="bulkUploadLabel" htmlFor="bulkUploadType">
                            Bulk Upload Type:
                        </label>
                        <div className="selectSkuContainer">
                            <DropdownWithoutSearchBar
                                options={names.map((name, i) => {
                                    return name;
                                })}
                                setAddNewValue={setSelectedName}
                                addNewValue={selectedName}
                                subTableId={'bulkUploads'}
                            />
                            {/* <select
                  value={selectedName}
                  className="selectedSku"
                  onChange={(e) => setSelectedName(e.target.value)}
                  defaultValue={"Select Bulk Upload Type"}
                >

                  {names.map((name, index) => (
                    <option className="optionsContainer" key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select> */}
                        </div>
                        <br></br>
                        <label className="bulkUploadLabel" htmlFor="csvFile">
                            Select CSV File
                        </label>
                        <input id="csvFile" type="file" accept=".csv" onChange={handleFileChange} />
                        <button
                            className={!csvFile || !selectedName ? 'inlineButtonDisabled' : 'inlineButton'}
                            onClick={handleUpload}
                            disabled={!csvFile || !selectedName}
                        >
                            Upload
                        </button>
                        {error.length > 0 && <ErrorMessage errorMessage={error.join('\n')} />}
                        {loading && <Spinner />}
                        {apiResponse && !error && (
                            <BulkUploadNotification type="success" message={JSON.stringify(apiResponse, null, 2)} />
                        )}
                        {/* {error && <BulkUploadNotification type="error" message={`Error: ${error.message}`} />}  */}
                        {error && <ErrorMessage errorMessage={error.message} bulkUpload={true} />}
                        <br></br>
                        <div className="buttonContainer">
                            <button className={'standardButton'} onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                className={'standardButton'}
                                onClick={() => handleDownload(selectedName)}
                                disabled={!selectedName}
                            >
                                Download CSV
                            </button>
                            <button className={'standardButton'} onClick={openGsheet}>
                                Link to CSV
                            </button>

                            {returnCsvData && (
                                <div>
                                    <button className={'standardButton'} onClick={handleReturnCSVDownload}>
                                        Download Output CSV
                                    </button>
                                    <br />
                                    <br />
                                    <pre>{returnCsvData}</pre>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
