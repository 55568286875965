import React from 'react';

export function Spinner(props) {
    const showHideLoading = props.loading ? 'spinnerModal display-block-spinner' : 'spinnerModal display-none';

    return (
        <div className={showHideLoading} key="spinnerContainer">
            <div align="center" className="cssload-fond" key="spinnerContainer1">
                <div className="cssload-container-general" key="spinnerContainer2">
                    <div className="cssload-internal">
                        <div className="cssload-ballcolor cssload-ball_1"> </div>
                    </div>
                    <div className="cssload-internal">
                        <div className="cssload-ballcolor cssload-ball_2"> </div>
                    </div>
                    <div className="cssload-internal">
                        <div className="cssload-ballcolor cssload-ball_3"> </div>
                    </div>
                    <div className="cssload-internal">
                        <div className="cssload-ballcolor cssload-ball_4"> </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TableSpinner(props) {
    const showHideLoading = props.loading ? 'spinnerModal display-block-spinner' : 'spinnerModal display-none';

    return [
        <tr className={showHideLoading} key="spinnerContainer">
            <td>
                <div align="center" className="cssload-fond" key="spinnerContainer1">
                    <div className="cssload-container-general" key="spinnerContainer2">
                        <div className="cssload-internal">
                            <div className="cssload-ballcolor cssload-ball_1"> </div>
                        </div>
                        <div className="cssload-internal">
                            <div className="cssload-ballcolor cssload-ball_2"> </div>
                        </div>
                        <div className="cssload-internal">
                            <div className="cssload-ballcolor cssload-ball_3"> </div>
                        </div>
                        <div className="cssload-internal">
                            <div className="cssload-ballcolor cssload-ball_4"> </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>,
    ];
}

///causing error <div> cannot appear as a child of tbody.
