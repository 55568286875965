import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Spinner } from './spinner';
import { ErrorMessage } from './errorMessage';
const baseURL = process.env.REACT_APP_API_INVOKE_URL

export function Login({ setToken, setUser }) {
    const userRef = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [buttonMessage, setButtonMessage] = useState('SEND CONFIRMATION EMAIL');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    const handleLogin = async (e) => {
        setLoading(true);
        e.preventDefault();
        const formData = new FormData();
        console.log(baseURL)
        formData.append('username', username);
        formData.append('password', password);
        fetch(`${baseURL}/login`, {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                // setToken(data["access_token"])
                setToken(data['access_token']);
                console.log(data['access_token']);
                setUser(data['user']);
                setLoading(false);
                setUsername('');
                setPassword('');
                setErrorMessage('');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setUsername('');
                setPassword('');
                setErrorMessage('Server responded with - ' + error);
            });
    };

    const handleReset = async (e) => {
        setLoading(true);
        setDisabled(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('username', username);
        fetch(`${baseURL}/forgot_password/` + username, {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                // setToken(data["access_token"])
                setLoading(false);
                setButtonMessage('CONFIRMATION EMAIL SENT');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setUsername('');
                setErrorMessage('Server responded with - ' + error);
            });
    };

    console.log('Log in page rendered.');
    return (
        <>
            {resetPassword ? (
                <>
                    <Spinner loading={loading} />
                    <div className="loginContainer">
                        <div className="backgroundImage" />
                        <section className="loginModal">
                            <form onSubmit={handleReset} className="modal-content">
                                <h1>Mmilo Portal</h1>
                                <h2>Reset Password</h2>
                                <label htmlFor="username">ENTER REGISTERED USERNAME</label>
                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete="on"
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username}
                                    placeholder={'USERNAME'}
                                    className="loginInput"
                                    required
                                    disabled={disabled}
                                />
                                <br></br>
                                <button type="submit" disabled={disabled}>
                                    {buttonMessage}
                                </button>
                                <p
                                    className="resetPassword"
                                    onClick={(e) => {
                                        setResetPassword(false);
                                        setDisabled(false);
                                    }}
                                >
                                    Back to login page
                                </p>
                            </form>
                        </section>
                        <ErrorMessage errorMessage={errorMessage} login={errorMessage.length > 0 ? true : false} />
                    </div>
                </>
            ) : (
                <>
                    <Spinner loading={loading} />
                    <div className="loginContainer">
                        <div className="backgroundImage" />
                        <section className="loginModal">
                            <form onSubmit={handleLogin} className="modal-content">
                                <h1>Mmilo Portal</h1>
                                <h2> Sign In</h2>
                                <label htmlFor="username">USERNAME</label>
                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete="on"
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username}
                                    placeholder={'USERNAME'}
                                    className="loginInput"
                                    required
                                />
                                <br></br>
                                <label htmlFor="password">PASSWORD</label>
                                <input
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    placeholder={'PASSWORD'}
                                    className="loginInput"
                                    required
                                />
                                <br></br>
                                <button>SIGN IN</button>
                                <p className="registerContainer">
                                    <span className="accountRequest">Request an account</span>
                                    <span className="contact">Contact sean@mmilo.co.uk</span>
                                </p>
                                <p
                                    className="resetPassword"
                                    onClick={(e) => {
                                        setResetPassword(true);
                                    }}
                                >
                                    Reset password
                                </p>
                            </form>
                        </section>
                        <ErrorMessage errorMessage={errorMessage} login={errorMessage.length > 0 ? true : false} />
                    </div>
                </>
            )}
        </>
    );
}

export default Login;
