import { React, useEffect, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';
import { AnalyticsPage } from './fetchAnalytics';

const factoryDefectsHeaders = Array.from([
    'id',
    'orderId',
    'date',
    'productSku',
    'defectType',
    'channelName',
    'claimAmount',
    'claimType',
    'factory',
    'courier',
]);

const buttonList = Array.from(['defectTable', 'recordDefect', 'analytics']);

export function DefectButtons({ token, setToken, removeToken }) {
    const [activeTab, setActiveTab] = useState('defectTable');
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);

    useEffect(() => {
        if (activeTab === 'defectTable') {
            setShowForm(false);
            setShowTable(true);
            setShowAnalytics(false);
        } else if (activeTab === 'recordDefect') {
            setShowTable(false);
            setShowAnalytics(false);
            setShowForm(true);
        } else if (activeTab === 'analytics') {
            setShowTable(false);
            setShowForm(false);
            setShowAnalytics(true);
        }
    }, [activeTab]);

    return (
        <>
            <ButtonMenu buttonList={buttonList} setActiveTab={setActiveTab} key="defectButtonMenu" />
            <Table
                showTable={showTable}
                columnHeaders={factoryDefectsHeaders}
                dataFetchId="/factory_defects"
                key="factoryDefectsTable"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={setActiveTab}
                formFetchId={activeTab}
                token={token}
                removeToken={removeToken}
            />
            <AnalyticsPage
                showAnalytics={showAnalytics}
                dataFetchId={'/factory_defects'}
                analyticsFetchId={'defectAnalytics'}
                token={token}
                removeToken={removeToken}
            />
        </>
    );
}
