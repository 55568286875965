export function SuccessMessage({ successMessage }) {
    console.log(successMessage);
    if (successMessage) {
        return (
            <>
                <div className="successMessage">
                    <i className="fas fa-solid fa-check"></i>
                    <p>{successMessage}</p>
                </div>
            </>
        );
    }
}
