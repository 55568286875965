import React from 'react';
import { Link } from 'react-router-dom';
const baseURL = process.env.REACT_APP_API_INVOKE_URL
const logoutInfo = {
    icon: 'fa fa-sign-out',
    title: 'Log out',
};

export function LogoutButton({ removeToken }) {
    const handleSubmit = async (e) => {
        e.preventDefault();
        fetch(`${baseURL}/logout`, {
            method: 'POST',
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                } else {
                    removeToken();
                }
            })
            .catch((error) => {
                console.log(error);
                setTimeout(5);
            });
    };

    return (
        <li className="dropdown" key="dropdown">
            <Link onClick={(e) => handleSubmit(e)}>
                <span>&ensp;</span>
                <i className={logoutInfo.icon} aria-hidden="true">
                    {' '}
                    {logoutInfo.title}
                </i>
            </Link>
        </li>
        // <button onClick={(e) => handleSubmit(e)}>LOG OUT</button>
    );
}

export default LogoutButton;
