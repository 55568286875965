import outlookImage from './img/outlook.png';
import parcelshipImage from './img/parcelship.png';
import wrikeImage from './img/wrike.png';
import aacImage from './img/aac.png';
import amazonImage from './img/amazon.png';
import ebayImage from './img/ebay.png';
import mmiloDropshippingImage from './img/mmilodropshipping.png';
import mmiloTradeImage from './img/mmiloTrade.png';
import wayfairImage from './img/wayfair.png';

const titles = Array.from([
    { title: 'Outlook', link: 'https://outlook.office.com/mail/', icon: outlookImage },
    { title: 'Parcelship', link: 'https://portal.parcelship.co.uk/_client/index.php', icon: parcelshipImage },
    { title: 'Wrike', link: 'https://www.wrike.com/workspace.htm?acc=2193401&wr=13#/home', icon: wrikeImage },
    { title: 'AAC', link: 'https://outlook.office.com/mail/', icon: aacImage },
    { title: 'Amazon', link: 'https://outlook.office.com/mail/', icon: amazonImage },
    { title: 'Dropshipping', link: 'https://outlook.office.com/mail/', icon: mmiloDropshippingImage },
    { title: 'eBay', link: 'https://outlook.office.com/mail/', icon: ebayImage },
    { title: 'Trade', link: 'https://outlook.office.com/mail/', icon: mmiloTradeImage },
    { title: 'Wayfair', link: 'https://outlook.office.com/mail/', icon: wayfairImage },
]);

export function QuickLinksBox() {
    return (
        <div className="quickLinksContainer">
            <h1>Quick Links</h1>
            <table className="quickLinksTable">
                <tbody>
                    <tr key="titleRow">
                        {titles.map((link, i) => {
                            return <th key={'title' + link['title']}>{link['title']}</th>;
                        })}
                    </tr>
                    <tr key="iconRow">
                        {titles.map((link, i) => {
                            return (
                                <th key={'icon' + link['title']}>
                                    <a href={link['link']} target="_blank" rel="noopener noreferrer">
                                        <img src={link['icon']} alt={'icon' + link['title']} />
                                    </a>
                                </th>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
