import { useState, useEffect } from 'react';
import { DropdownWithSearchBar } from './dropdownWithSearchBar';
import { Spinner } from './spinner';
import inventoryGuildelines1 from './img/mmilo-portal-sku-guidelines_1.jpg';
import inventoryGuildelines2 from './img/mmilo-portal-sku-guidelines_2.jpg';

const courierList = Array.from(['DHL', 'DPD', 'MXL', 'Panther', 'TNT', 'UPS', 'XDP', 'Yodel']);
const baseURL = process.env.REACT_APP_API_INVOKE_URL
const defectType = Array.from([
    'Chair seat',
    'Chair legs',
    'Table top',
    'Table legs',
    'Table base',
    'Missing screwpack',
    'Wrong colour in box',
    'Damaged in transit',
    'Others (add description)',
]);

const channelName = Array.from([
    'All About Chairs',
    'Amazon',
    'eBay',
    'Etsy',
    'Fruugo',
    'Manomano',
    'Mmilo Dropshipping',
    'Mmilo Trade',
    'onBuy',
    'Wayfair',
    'Wish',
]);

const claimType = Array.from([
    'Full order refund (inc. shipping)',
    'Partial order refund (inc. shipping)',
    'Full order refund (no shipping)',
    'Partial order refund (no shipping)',
    'Shipping cost only',
]);

const skuCategory = Array.from([
    'Accessory',
    'Barstool',
    'Bed',
    'Chair',
    'Children',
    'Lighting',
    'Sofa',
    'Table',
    'Other',
]);

const skuType = Array.from(['Base_SKU', 'Composite_SKU', 'Component_SKU']);

const supplierTypeList = Array.from(['General', 'Dropshipping']);

const variationTypeList = Array.from(['Colour', 'Material', 'Size']);

const orderStatusList = Array.from(['Draft', 'Open']);

const paidStatusList = Array.from(['Unpaid', 'Paid', 'Partially paid']);

export const Form = ({ showForm, setActiveTab, formFetchId, token, removeToken }) => {
    const classNameShowForm = showForm ? 'modal display-block' : 'modal display-none';
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const [addNewTitle, setAddNewTitle] = useState('');

    function handleClear(e) {
        e.target['form'].reset();
    }

    function imageHandler(e) {
        const files = e.target.files;
        setSelectedFiles(files);
    }

    function submitForm(e, listInfo) {
        console.log(formFetchId);
        if (formFetchId === 'recordDefect') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();
            formData.append('orderId', e.target['orderId'].value);
            formData.append('productSku', addNewTitle);
            formData.append('description', e.target['description'].value);
            formData.append('replacementSku', e.target['replacementSku'].value);
            formData.append('replacementQuantity', e.target['replacementQuantity'].value);
            formData.append('defectType', e.target['defectType'].value);
            formData.append('channelName', e.target['channelName'].value);
            formData.append('claimAmount', e.target['claimAmount'].value);
            formData.append('claimType', e.target['claimType'].value);
            formData.append('courier', e.target['courier'].value);
            formData.append('factory', e.target['factory'].value);
            var n_imgs = 0;

            if (selectedFiles) {
                n_imgs = selectedFiles.length;
                for (var x = 0; x < n_imgs; x++) {
                    formData.append('images_' + x, selectedFiles[x]);
                }
            }

            formData.append('n_imgs', n_imgs);

            if (addNewTitle === '') {
                alert('Please ensure product SKU is selected.');
                setLoading(false);
            } else {
                fetch(`${baseURL}/factory_defects`, {
                    credentials: 'include',
                    method: 'POST',
                    headers: new Headers({
                        'Access-Control-Allow-Origin': '*',
                        Authorization: 'Bearer ' + token,
                    }),
                    body: formData,
                })
                    .then((response) => {
                        console.log(response);
                        setLoading(false);
                        window.location.reload();
                        setActiveTab('defectTable');
                    })
                    .then((body) => console.log(body))
                    .catch((error) => {
                        console.log(error);
                    });
            }

            ///fetch requests are async, meaning they start first and then the code moves on to the next line.
            ///And then at some point the fetch request completes.
        } else if (formFetchId === 'addSku') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();

            formData.append('newSku', e.target['newSku'].value);
            formData.append('title', e.target['title'].value);
            formData.append('skuCategory', e.target['skuCategory'].value);
            formData.append('skuType', e.target['skuType'].value);
            formData.append('factory', e.target['factory'].value);

            fetch(`${baseURL}/inventory_management`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            })
                .then((response) => {
                    console.log(response);
                    setLoading(false);
                    window.location.reload();
                    setActiveTab('inventoryTable');
                })
                .then((body) => console.log(body))
                .catch((error) => {
                    console.log(error);
                });
        } else if (formFetchId === 'addPo') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();
            formData.append('supplierReferenceNumber', e.target['supplierReferenceNumber'].value);
            formData.append('factoryName', e.target['factoryName'].value);
            formData.append('warehouse', e.target['warehouse'].value);

            console.log(formData);

            fetch(`${baseURL}/purchase_orders`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    setLoading(false);
                    window.location.reload();
                    setActiveTab('poTable');
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (formFetchId === 'addVariationGroup') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();
            formData.append('variationGroupSku', e.target['variationGroupSku'].value.toUpperCase());
            formData.append('variationGroupType', e.target['variationGroupType'].value);
            formData.append('variationGroupTitle', e.target['variationGroupTitle'].value);

            console.log(formData);

            fetch(`${baseURL}/variation_management`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    setLoading(false);
                    window.location.reload();
                    setActiveTab('inventoryTable');
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (formFetchId === 'addFactory') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();
            formData.append('factoryName', e.target['factoryName'].value.toUpperCase());
            formData.append('country', e.target['country'].value);
            formData.append('supplierType', e.target['supplierType'].value);

            console.log(formData);

            fetch(`${baseURL}/factory_management`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    setLoading(false);
                    window.location.reload();
                    setActiveTab('factoryTable');
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (formFetchId === 'addWarehouse') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();
            formData.append('warehouseName', e.target['warehouseName'].value.toUpperCase());
            formData.append('country', e.target['country'].value);

            console.log(formData);

            fetch(`${baseURL}/warehouse_management`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    setLoading(false);
                    window.location.reload();
                    setActiveTab('warehouseTable');
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (formFetchId === 'addNewUser') {
            e.preventDefault();
            let password = e.target['password'].value;
            let confirmPassword = e.target['confirmPassword'].value;
            //check if email already exists
            console.log(listInfo);
            let users = listInfo.filter((userRow, i) => {
                return userRow['email'] === e.target['email'].value;
            });
            console.log(users);

            if (users.length > 0) {
                e.preventDefault();
                alert('Username already taken! Please select something else.');
            }

            if (password !== confirmPassword) {
                e.preventDefault();
                alert('Please make sure you entered the correct password!');
            } else {
                setLoading(true);
                e.preventDefault();
                const formData = new FormData();
                console.log(token);

                formData.append('firstName', e.target['firstName'].value);
                formData.append('lastName', e.target['lastName'].value);
                formData.append('email', e.target['email'].value);
                console.log(password);
                formData.append('password', e.target['password'].value);
                formData.append('confirmPassword', e.target['confirmPassword'].value);

                for (var pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }

                fetch(`${baseURL}/register`, {
                    credentials: 'include',
                    method: 'POST',
                    headers: new Headers({
                        'Access-Control-Allow-Origin': '*',
                        Authorization: 'Bearer ' + token,
                    }),
                    body: formData,
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        setLoading(false);
                        window.location.reload();
                        setActiveTab('userTable');
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else if (formFetchId === 'newOrder') {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData();

            formData.append('customerName', e.target['customerName'].value);
            formData.append('shippingAddress', e.target['shippingAddress'].value);
            formData.append('orderStatus', e.target['orderStatus'].value);
            formData.append('paidStatus', e.target['paidStatus'].value);

            fetch(`${baseURL}/open_orders_management`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    setLoading(false);
                    window.location.reload();
                    setActiveTab('openOrdersTable');
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    if (formFetchId !== '') {
        return [
            <ConditionalRender
                formFetchId={formFetchId}
                classNameShowForm={classNameShowForm}
                submitForm={submitForm}
                imageHandler={imageHandler}
                setActiveTab={setActiveTab}
                setSelectedFiles={setSelectedFiles}
                handleClear={handleClear}
                loading={loading}
                key="conditionalRender"
                setAddNewTitle={setAddNewTitle}
                token={token}
                removeToken={removeToken}
            />,
        ];
    }
};
//NOTE: YOU SHOULD NOT HAVE A FORM AS A COMPONENT, DECLARED INSIDE ANOTHER COMPONENT. DECLARE IT SEPARATELY AND THEN INCLUDE IT IN THE OTHER COMPONENT.
//IF YOU DO NOT DO THIS, THE DOM FOR "FORM" WILL REFRESH ONCHANGE, CAUSING ALL INPUT IN CONDITIONALRENDER TO ALSO REFRESH.
export const ConditionalRender = ({
    formFetchId,
    classNameShowForm,
    submitForm,
    imageHandler,
    setActiveTab,
    handleClear,
    loading,
    setAddNewTitle,
    token,
    removeToken,
}) => {
    const [factoryList, setFactoryList] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [skuList, setSkuList] = useState([]);
    const [filteredSkuList, setFilteredSkuList] = useState([]);
    const [userList, setUserList] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (formFetchId === 'addSku' || formFetchId === 'addPo' || formFetchId === 'recordDefect') {
            fetch(`${baseURL}/factory_management`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setFactoryList(data);
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        }
    }, [formFetchId, token]);

    useEffect(() => {
        if (formFetchId === 'addPo') {
            fetch(`${baseURL}/warehouse_management`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setWarehouseList(data);
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        }
    }, [formFetchId, token]);

    useEffect(() => {
        if (formFetchId === 'addFactory' || formFetchId === 'addWarehouse') {
            fetch('https://restcountries.com/v3.1/all')
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    const countries = data.map((country) => country.name.common);
                    setCountryList(countries.sort());
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        }
    }, [formFetchId]);

    useEffect(() => {
        if (formFetchId === 'recordDefect') {
            fetch(`${baseURL}/inventory_management_composite_base_skus`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    setSkuList(data);
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        }
    }, [formFetchId, token]);

    useEffect(() => {
        if (formFetchId === 'addNewUser') {
            fetch(`${baseURL}/user_management`, {
                credentials: 'include',
                method: 'GET',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            removeToken();
                            // setLoading(false);
                            // controller.abort()
                            //set error message
                        }
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setUserList(data);
                })
                .catch((error) => {
                    console.log(error);
                    // setErrorMessage("Server responded with - " + error)
                    // setLoading(false)
                });
        }
    }, [formFetchId, removeToken, token]);

    useEffect(() => {
        if (skuList) {
            let tempArray = skuList.filter((sku, i) => {
                if (searchTerm) {
                    return sku.sku.toLowerCase().includes(searchTerm.toLowerCase());
                } else {
                    return sku;
                }
            });
            setFilteredSkuList(tempArray);
        }
    }, [searchTerm, skuList]);

    if (formFetchId === 'recordDefect' && factoryList && skuList) {
        return [
            <Spinner loading={loading} key="spinner-layer-2" />,
            <div className={classNameShowForm} key="recordDefectsTable">
                <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="recordDefectsForm">
                    <p className="modalTitle">Record New Factory Defect</p>
                    <label htmlFor="orderId">Order ID: </label>
                    <br></br>
                    <input
                        type="text"
                        id="orderId"
                        name="orderId"
                        placeholder="Please enter Order ID (e.g. UK123456789)"
                        required
                    ></input>
                    <br></br>
                    <div className="formInlineButton">
                        <label htmlFor="productSku">Product SKU: </label>
                        <DropdownWithSearchBar
                            options={filteredSkuList}
                            setSearchTerm={setSearchTerm}
                            setAddNewTitle={setAddNewTitle}
                        />
                    </div>
                    <label htmlFor="description">Description: </label>
                    <br></br>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        placeholder="Please enter brief description of defect."
                        required
                    ></input>
                    <br></br>
                    <label htmlFor="replacementSku">Replacement SKU: </label>
                    <br></br>
                    <input
                        type="text"
                        id="replacementSku"
                        name="replacementSku"
                        placeholder="Please select from dropdown."
                        required
                    ></input>
                    <br></br>
                    <label htmlFor="replacementQuantity">Replacement quantity: </label>
                    <br></br>
                    <input
                        type="text"
                        id="replacementQuantity"
                        name="replacementQuantity"
                        placeholder="Please enter quantity of replacement part sent."
                        required
                    ></input>
                    <br></br>
                    <label htmlFor="claimAmount">Claim amount (£): </label>
                    <br></br>
                    <input
                        type="text"
                        id="claimAmount"
                        name="claimAmount"
                        placeholder="Please enter claim amount."
                        required
                    ></input>
                    <br></br>
                    <label htmlFor="defectType">Defect type: </label>
                    <select
                        placeholder="Please select a defect type."
                        className="dropdown"
                        name="defectType"
                        id="defectType"
                        required
                    >
                        {defectType.map((defectType, i) => (
                            <option value={defectType} key={i}>
                                {defectType}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="claimType">Claim type: </label>
                    <select
                        placeholder="Please select a claim type."
                        className="dropdown"
                        name="claimType"
                        id="claimType"
                        required
                    >
                        {claimType.map((claimType, i) => (
                            <option value={claimType} key={i}>
                                {claimType}
                            </option>
                        ))}
                    </select>
                    <br></br>
                    <label htmlFor="channelName">Channel name: </label>
                    <select
                        placeholder="Please select a channel."
                        className="dropdown"
                        name="channelName"
                        id="channelName"
                        required
                    >
                        {channelName.map((channelName, i) => (
                            <option value={channelName} key={i}>
                                {channelName}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="courier">Courier: </label>
                    <select
                        placeholder="Please select courier."
                        className="dropdown"
                        name="courier"
                        id="courier"
                        required
                    >
                        {courierList.map((courier, i) => (
                            <option value={courier} key={i}>
                                {courier}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="factory">Factory: </label>
                    <select
                        placeholder="Please select factory"
                        className="dropdown"
                        name="factory"
                        id="factory"
                        required
                    >
                        {factoryList.map((factory, i) => (
                            <option value={factory['factoryName']} key={i}>
                                {factory['factoryName']}
                            </option>
                        ))}
                    </select>
                    <br></br>
                    <label htmlFor="uploadImages">Upload images: </label>
                    <br></br>
                    <input
                        type="file"
                        id="images"
                        name="images"
                        onChange={(e) => {
                            imageHandler(e);
                        }}
                        multiple
                        accept="image/*"
                        className="imageUpload"
                    />
                    <br></br>
                    <br></br>
                    <button type="button" onClick={() => setActiveTab('defectTable')} className="standardButton">
                        Cancel
                    </button>
                    <button type="submit" className="standardButton">
                        Save and Close
                    </button>
                    <button
                        type="button"
                        className="standardButton"
                        onClick={(e) => {
                            handleClear(e);
                        }}
                    >
                        Clear Information
                    </button>
                </form>
            </div>,
        ];
    } else if (formFetchId === 'addSku') {
        return [
            <Spinner loading={loading} key="spinner-layer-2" />,
            <div className={classNameShowForm} key="addSkuTable">
                <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="recordDefectsForm">
                    <p className="modalTitle">Add New SKU</p>
                    <label htmlFor="newSku">New SKU: </label>
                    <br></br>
                    <input
                        type="text"
                        id="newSku"
                        name="newSku"
                        placeholder="Please enter new SKU (e.g. T-7012G-BLK-100-x1)."
                        required
                    ></input>
                    <p className="parameterDescription">
                        Please follow standard guidelines for SKU naming as per images below.
                    </p>
                    <img alt="SKU naming guidelines 1." className="guidelinesImage" src={inventoryGuildelines1}></img>
                    <img alt="SKU naming guidelines 2." className="guidelinesImage" src={inventoryGuildelines2}></img>
                    <br></br>
                    <label htmlFor="title">Title: </label>
                    <br></br>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Please input title for SKU."
                        required
                    ></input>
                    <br></br>
                    <label htmlFor="skuCategory">Category: </label>
                    <br></br>
                    <select
                        placeholder="Please select category."
                        className="dropdown"
                        name="skuCategory"
                        id="skuCategory"
                        required
                    >
                        {skuCategory.map((category, i) => (
                            <option value={category} key={i}>
                                {category}
                            </option>
                        ))}
                    </select>
                    <br></br>
                    <label htmlFor="skuType">SKU Type: </label>
                    <br></br>
                    <select placeholder="Please select type." className="dropdown" name="skuType" id="skuType" required>
                        {skuType.map((skuType, i) => (
                            <option value={skuType} key={i}>
                                {skuType}
                            </option>
                        ))}
                    </select>
                    <br></br>
                    <label htmlFor="skuType">Factory: </label>
                    <br></br>
                    <select placeholder="Please select type." className="dropdown" name="factory" id="factory" required>
                        {factoryList
                            ? factoryList.map((factory, i) => (
                                  <option value={factory['factoryName']} key={i}>
                                      {factory['factoryName']}
                                  </option>
                              ))
                            : null}
                    </select>
                    <br></br>
                    <p className="parameterDescription">*More details can be added after SKU creation.</p>
                    <button type="button" onClick={() => setActiveTab('inventoryTable')} className="standardButton">
                        Cancel
                    </button>
                    <button type="submit" className="standardButton">
                        Save and Close
                    </button>
                    <button
                        type="button"
                        className="standardButton"
                        onClick={(e) => {
                            handleClear(e);
                        }}
                    >
                        Clear Information
                    </button>
                </form>
            </div>,
        ];
    } else if (formFetchId === 'addPo' && factoryList) {
        console.log(warehouseList);
        return [
            <Spinner loading={loading} key="spinner-layer-2" />,
            <div className={classNameShowForm} key="addPoTable">
                <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="addPoForm">
                    <p className="modalTitle">Add New Purchase Order</p>
                    <label htmlFor="supplierReferenceNumber">Supplier Reference Number: </label>
                    <br></br>
                    <input
                        type="text"
                        id="supplierReferenceNumber"
                        name="supplierReferenceNumber"
                        placeholder="Please enter supplier reference number."
                        required
                    ></input>
                    <br></br>
                    <label htmlFor="factoryName">Factory name: </label>
                    <select
                        placeholder="Please select factory."
                        className="dropdown"
                        name="factoryName"
                        id="factoryName"
                        required
                    >
                        {factoryList.map((factory, i) => (
                            <option value={factory.factoryName} key={i}>
                                {factory.factoryName}
                            </option>
                        ))}
                    </select>
                    <br></br>
                    <label htmlFor="warehouseName">Deliver to warehouse: </label>
                    <select
                        placeholder="Please select factory."
                        className="dropdown"
                        name="warehouse"
                        id="warehouse"
                        required
                    >
                        {warehouseList.map((warehouse, i) => (
                            <option value={warehouse.warehouseName} key={i}>
                                {warehouse.warehouseName}
                            </option>
                        ))}
                    </select>
                    <p className="parameterDescription">*More details can be added after PO creation.</p>
                    <button type="button" onClick={() => setActiveTab('inventoryTable')} className="standardButton">
                        Cancel
                    </button>
                    <button type="submit" className="standardButton">
                        Save and Close
                    </button>
                    <button
                        type="button"
                        className="standardButton"
                        onClick={(e) => {
                            handleClear(e);
                        }}
                    >
                        Clear Information
                    </button>
                </form>
            </div>,
        ];
    } else if (formFetchId === 'addFactory' && countryList) {
        return (
            <>
                <Spinner loading={loading} key="spinner-layer-2" />
                <div className={classNameShowForm} key="addFactoryTable">
                    <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="addFactoryForm">
                        <p className="modalTitle">Add New Factory</p>
                        <label htmlFor="factoryName">Factory Name: </label>
                        <br></br>
                        <input
                            type="text"
                            id="factoryName"
                            name="factoryName"
                            placeholder="Please enter warehouse name."
                            required
                        ></input>
                        <br></br>
                        <label htmlFor="country">Country: </label>
                        <select
                            placeholder="Please select origin country."
                            className="dropdown"
                            name="country"
                            id="country"
                            required
                        >
                            {countryList.map((country, i) => (
                                <option value={country} key={i}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        <br></br>
                        <label htmlFor="supplierType">Supplier type: </label>
                        <select
                            placeholder="Please select supplier type."
                            className="dropdown"
                            name="supplierType"
                            id="supplierType"
                            required
                        >
                            {supplierTypeList.map((supplierType, i) => (
                                <option value={supplierType} key={i}>
                                    {supplierType}
                                </option>
                            ))}
                        </select>
                        <p className="parameterDescription">*More details can be added after factory creation.</p>
                        <button type="button" onClick={() => setActiveTab('factoryTable')} className="standardButton">
                            Cancel
                        </button>
                        <button type="submit" className="standardButton">
                            Save and Close
                        </button>
                        <button
                            type="button"
                            className="standardButton"
                            onClick={(e) => {
                                handleClear(e);
                            }}
                        >
                            Clear Information
                        </button>
                    </form>
                </div>
            </>
        );
    } else if (formFetchId === 'addWarehouse' && countryList) {
        return (
            <>
                <Spinner loading={loading} key="spinner-layer-2" />
                <div className={classNameShowForm} key="addWarehouseTable">
                    <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="addWarehouseForm">
                        <p className="modalTitle">Add New Warehouse</p>
                        <label htmlFor="warehouseName">Warehouse Name: </label>
                        <br></br>
                        <input
                            type="text"
                            id="warehouseName"
                            name="warehouseName"
                            placeholder="Please enter warehouse name."
                            required
                        ></input>
                        <br></br>
                        <label htmlFor="country">Country: </label>
                        <select
                            placeholder="Please select origin country."
                            className="dropdown"
                            name="country"
                            id="country"
                            required
                        >
                            {countryList.map((country, i) => (
                                <option value={country} key={i}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        <br></br>
                        <p className="parameterDescription">*More details can be added after warehouse creation.</p>
                        <button type="button" onClick={() => setActiveTab('warehouseTable')} className="standardButton">
                            Cancel
                        </button>
                        <button type="submit" className="standardButton">
                            Save and Close
                        </button>
                        <button
                            type="button"
                            className="standardButton"
                            onClick={(e) => {
                                handleClear(e);
                            }}
                        >
                            Clear Information
                        </button>
                    </form>
                </div>
            </>
        );
    } else if (formFetchId === 'addVariationGroup') {
        return (
            <>
                <Spinner loading={loading} key="spinner-layer-2" />
                <div className={classNameShowForm} key="addVariationTable">
                    <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="addVariationForm">
                        <p className="modalTitle">Add New Variation Group</p>
                        <label htmlFor="variationName">Variation Group SKU: </label>
                        <br></br>
                        <input
                            type="text"
                            id="variationGroupSku"
                            name="variationGroupSku"
                            placeholder="Please enter variation SKU."
                            required
                        ></input>
                        <label htmlFor="variationGroupTitle">Variation Group Title: </label>
                        <br></br>
                        <input
                            type="text"
                            id="variationGroupTitle"
                            name="variationGroupTitle"
                            placeholder="Please enter variation title."
                            required
                        ></input>
                        <label htmlFor="variationGroupType">Variation Group Type: </label>
                        <br></br>
                        <select
                            placeholder="Please select origin country."
                            className="dropdown"
                            name="variationGroupType"
                            id="variationGroupType"
                            required
                        >
                            {variationTypeList.map((type, i) => (
                                <option value={type} key={i}>
                                    {type}
                                </option>
                            ))}
                        </select>
                        <p className="parameterDescription">*More details can be added after creation.</p>
                        <button type="button" onClick={() => setActiveTab('inventoryTable')} className="standardButton">
                            Cancel
                        </button>
                        <button type="submit" className="standardButton">
                            Save and Close
                        </button>
                        <button
                            type="button"
                            className="standardButton"
                            onClick={(e) => {
                                handleClear(e);
                            }}
                        >
                            Clear Information
                        </button>
                    </form>
                </div>
            </>
        );
    } else if (formFetchId === 'newOrder') {
        return (
            <>
                <Spinner loading={loading} key="spinner-layer-2" />
                <div className={classNameShowForm} key="addVariationTable">
                    <form name="form" onSubmit={(e) => submitForm(e)} className="modal-main" key="newOrderForm">
                        <p className="modalTitle">Add New Order</p>
                        <label htmlFor="orderStatus">Order status: </label>
                        <select
                            placeholder="Please select order status."
                            className="dropdown"
                            name="orderStatus"
                            id="orderStatus"
                            required
                        >
                            {orderStatusList.map((type, i) => (
                                <option value={type} key={i}>
                                    {type}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="paidStatus">Paid status: </label>
                        <select
                            placeholder="Please select order status."
                            className="dropdown"
                            name="paidStatus"
                            id="paidStatus"
                            required
                        >
                            {paidStatusList.map((type, i) => (
                                <option value={type} key={i}>
                                    {type}
                                </option>
                            ))}
                        </select>
                        <br></br>
                        <label htmlFor="customerName">Customer Name: </label>
                        <br></br>
                        <input
                            type="text"
                            id="customerName"
                            name="customerName"
                            placeholder="Please enter customer name."
                            required
                        ></input>
                        <br></br>
                        <label htmlFor="variationGroupType">Shipping Address: </label>
                        <br></br>
                        <input
                            type="text"
                            id="shippingAddress"
                            name="shippingAddress"
                            placeholder="Please enter shipping address."
                            required
                        ></input>
                        <p className="parameterDescription">*More details can be added after creation.</p>
                        <button type="button" onClick={() => setActiveTab('inventoryTable')} className="standardButton">
                            Cancel
                        </button>
                        <button type="submit" className="standardButton">
                            Save and Close
                        </button>
                        <button
                            type="button"
                            className="standardButton"
                            onClick={(e) => {
                                handleClear(e);
                            }}
                        >
                            Clear Information
                        </button>
                    </form>
                </div>
            </>
        );
    } else if (formFetchId === 'addNewUser' && userList) {
        return (
            <>
                <Spinner loading={loading} key="spinner-layer-2" />
                <div className={classNameShowForm}>
                    <form
                        name="form"
                        onSubmit={(e) => submitForm(e, userList)}
                        className="modal-main"
                        key="newOrderForm"
                    >
                        <p className="modalTitle">Add New User</p>
                        <label htmlFor="firstName">First name: </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Please enter user's first name."
                            required
                        ></input>
                        <br></br>
                        <label htmlFor="lastName">Last name: </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Please enter user's last name."
                            required
                        ></input>
                        <br></br>
                        <label htmlFor="email">E-mail: </label>
                        <input type="email" id="email" name="email" placeholder="Please enter email." required></input>
                        <br></br>
                        <label htmlFor="password">Password: </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Please enter password."
                            required
                        ></input>
                        <br></br>
                        <label htmlFor="password">Confirm password: </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Please confirm password."
                            required
                        ></input>
                        <p className="parameterDescription">
                            *Please note only admins and super admins can create users.
                        </p>
                        <button type="button" onClick={() => setActiveTab('userTable')} className="standardButton">
                            Cancel
                        </button>
                        <button type="submit" className="standardButton">
                            Save and Close
                        </button>
                        <button
                            type="button"
                            className="standardButton"
                            onClick={(e) => {
                                handleClear(e);
                            }}
                        >
                            Clear Information
                        </button>
                    </form>
                </div>
            </>
        );
    }
};
