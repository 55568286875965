/**This is the controller file that contains all of the components. A component IS a function that returns UI. */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Hamburger, Navbar, Pages } from './navbar';
import useToken from './useToken';
import Login from './login';
import { ResetPasswordConfirm } from './pages';

function App() {
    const { token, removeToken, setToken, user, removeUser, setUser } = useToken();
    const [resetToken, setResetToken] = useState('');
    const [resetEmail, setResetEmail] = useState('');

    useEffect(() => {
        let location = window.location.href;

        if (location.includes('/reset_password/')) {
            location = location.split('/reset_password/')[1];
            location = location.split('/');

            let email = location[0];
            let token = location[1];
            setResetToken(token);
            setResetEmail(email);
        }
    }, []);

    return (
        <BrowserRouter>
            <Hamburger />
            {resetToken ? (
                <ResetPasswordConfirm
                    token={resetToken}
                    resetEmail={resetEmail}
                    removeToken={removeToken}
                    setResetToken={setResetToken}
                />
            ) : !token || token === '' || token === 'undefined' ? (
                <Login setToken={setToken} setUser={setUser} />
            ) : (
                <>
                    <Navbar
                        token={token}
                        removeToken={removeToken}
                        setToken={setToken}
                        user={user}
                        removeUser={removeUser}
                        setUser={setUser}
                    />
                    <Pages
                        token={token}
                        removeToken={removeToken}
                        setToken={setToken}
                        user={user}
                        removeUser={removeUser}
                        setUser={setUser}
                    />
                </>
            )}
        </BrowserRouter>
    );
}

export default App;
