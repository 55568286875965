import { React, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';
import { CsvUpload } from './csvUpload';

const inventoryHeaders = Array.from([
    'sku',
    'title',
    'productCategory',
    'type',
    'purchasePrice',
    'stock',
    'inOpenOrders',
    'available',
    'redProduct',
]);

const variationHeaders = Array.from([
    'variationGroupSku',
    'variationGroupType',
    'variationGroupTitle',
    'variationGroupComponents',
]);

const buttonList = Array.from([
    'inventoryTable',
    'addSku',
    'variationTable',
    'addVariationGroup',
    'analytics',
    'bulkActions',
]);

export function InventoryButtons({ token, setToken, removeToken }) {
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [formFetchId, setFormFetchId] = useState('');
    const [dataFetchId, setDataFetchId] = useState('/inventory_management');
    const [tableHeaders, setTableHeaders] = useState(inventoryHeaders);
    const [showCSV, setShowCSV] = useState(false);
    const [endpoint, setEndpoint] = useState('');

    console.count('Rerender ');

    function handleActiveTab(activeTab) {
        if (activeTab === 'inventoryTable') {
            setShowForm(false);
            setShowTable(true);
            setShowCSV(false);
            setTableHeaders(inventoryHeaders);
            setDataFetchId('/inventory_management');
        } else if (activeTab === 'addSku') {
            setShowTable(false);
            setShowForm(true);
            setShowCSV(false);
            setFormFetchId('addSku');
        } else if (activeTab === 'addVariationGroup') {
            setShowTable(false);
            setShowForm(true);
            setShowCSV(false);
            setFormFetchId('addVariationGroup');
        } else if (activeTab === 'variationTable') {
            setShowForm(false);
            setShowTable(true);
            setShowCSV(false);
            setTableHeaders(variationHeaders);
            setDataFetchId('/variation_management');
        } else if (activeTab === 'bulkActions') {
            console.log('clicked');
            setShowTable(false);
            setShowCSV(true);
            setEndpoint('/inventory_management');
            console.log('showCSV prop:', showCSV);
        }
    }

    return (
        <>
            <ButtonMenu buttonList={buttonList} key="inventoryButtonMenu" setActiveTab={handleActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={tableHeaders}
                dataFetchId={dataFetchId}
                key={dataFetchId}
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={handleActiveTab}
                formFetchId={formFetchId}
                token={token}
                removeToken={removeToken}
            />
            <CsvUpload
                showCSV={showCSV}
                setActiveTab={handleActiveTab}
                endpoint={endpoint}
                csvId={'inventoryBulkUpload'}
                token={token}
                removeToken={removeToken}
            />
        </>
    );
}
