export function parseSQLToTitles(string) {
    if (string === 'poNumber') {
        return 'PO No.';
    } else if (string === 'supplierReferenceNumber') {
        return 'Supplier Ref. No.';
    } else if (string === 'claimAmount') {
        return 'Claim Amount ($)';
    }

    let word = string;
    let words = string.split(/(?=[A-Z])/); // split words at capital letters
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)); // capitalize the first letter of each word
    if (/\s/.test(words)) {
        word = words.join('');
    } else {
        word = words.join(' ');
    }

    if (word.toLowerCase().includes('sku')) {
        return word.replace(/sku/i, 'SKU');
    } else if (word.toLowerCase().includes('id')) {
        return word.replace(/id/i, 'ID');
    } else if (word.toLowerCase().includes('po')) {
        return word.replace(/po/i, 'PO');
    } else {
        return word;
    }
}
