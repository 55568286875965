import { React, useEffect, useState } from 'react';
import { Table } from './fetchTable';
import { Form } from './fetchForm';
import { ButtonMenu } from './buttonMenu';

const userHeaders = Array.from(['id', 'email', 'firstName', 'lastName', 'accessLevel']);

const buttonList = Array.from(['userTable', 'addNewUser']);

export function UserButtons({ token, setToken, removeToken }) {
    const [activeTab, setActiveTab] = useState('userTable');
    const [showTable, setShowTable] = useState(true);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (activeTab === 'userTable') {
            setShowForm(false);
            setShowTable(true);
        } else if (activeTab === 'addNewUser') {
            setShowTable(false);
            setShowForm(true);
        }
    }, [activeTab]);

    return (
        <>
            <ButtonMenu buttonList={buttonList} setActiveTab={setActiveTab} />
            <Table
                showTable={showTable}
                columnHeaders={userHeaders}
                dataFetchId="/user_management"
                token={token}
                removeToken={removeToken}
            />
            <Form
                showForm={showForm}
                setActiveTab={setActiveTab}
                formFetchId={activeTab}
                token={token}
                removeToken={removeToken}
            />
        </>
    );
}
