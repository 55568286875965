const baseURL = process.env.REACT_APP_API_INVOKE_URL


/**FETCH OPTIONS */
export async function fetchOptions(endpoint, token) {
    const response = await fetch(baseURL + endpoint, {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        }),
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    return response;
}

/**SAVE */
const confirmDataEndpointMapping = {
    cartonDimensions: '/inventory_management_update/',
    productDimensions: '/inventory_management_update/',
    altSku: '/inventory_management_update/',
    customProperties: '/inventory_management_update/',
    composition: '/inventory_management_update/',
    poStockInfo: '/update_po/',
    deliverPo: '/update_po_status/',
    warehouseContacts: '/warehouse_management_update/',
    warehouseDetails: '/warehouse_management_update/',
    factoryDetails: '/factory_management_update/',
    variationDetails: '/variation_management_update/',
    editVariation: '/variation_management_update/',
    shippingService: '/inventory_management_update/',
    sellingPrice: '/inventory_management_update/',
    orderItems: '/edit_open_order_items/',
    deleteSku: '/inventory_management_delete/',
    inventoryUploadImage: '/inventory_management_image_upload/',
    inventoryDeleteImage: '/inventory_management_image_delete/',
    editSku: '/inventory_management_edit/',
};

export function handleUploadImage(
    confirmData,
    {
        selectedFiles = undefined,
        longData = undefined,
        mainId = undefined,
        token = undefined,
        setCustomImageUploadButtonClass = undefined,
        setLoading = undefined,
        setErrorMessage = undefined,
        setShowHideConfirmBox = undefined,
        targetDesc = undefined,
    },
) {
    const formData = new FormData();
    var n_imgs = 0;
    if (selectedFiles) {
        n_imgs = selectedFiles.length;
        for (var x = 0; x < n_imgs; x++) {
            formData.append('images_' + x, selectedFiles[x]);
        }
    }
    formData.append('n_imgs', n_imgs);
    if (JSON.parse(longData[0]['imagesLinks'])) {
        formData.append('imagesLinks', JSON.parse(longData[0]['imagesLinks']));
        formData.append('n_imgs_current', JSON.parse(longData[0]['imagesLinks']).length);
    } else {
        formData.append('imagesLinks', []);
        formData.append('n_imgs_current', '0');
    }

    fetch(baseURL + confirmDataEndpointMapping[confirmData] + longData[0][mainId], {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
        }),
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            setLoading(false);
            setShowHideConfirmBox(false);
            setCustomImageUploadButtonClass('customImageUploadButton');
            // Persist display state and target description
            localStorage.setItem('isDisplayed', 'true');
            localStorage.setItem('targetDesc', targetDesc);
            // Set a flag before reloading the page
            localStorage.setItem('pageReloaded', 'true');
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            setErrorMessage('Server responded with - ' + error);
            // Persist display state and target description
            localStorage.setItem('isDisplayed', 'true');
            localStorage.setItem('targetDesc', targetDesc);
            // Set a flag before reloading the page
            localStorage.setItem('pageReloaded', 'true');
            window.location.reload();
        });
}

export function handleDeleteImage(
    confirmData,
    {
        longData = undefined,
        mainId = undefined,
        token = undefined,
        setLoading = undefined,
        setErrorMessage = undefined,
        targetDesc = undefined,
        imageDeletionIndex = undefined,
        setShowHideConfirmBox = undefined,
    },
) {
    const formData = new FormData();
    formData.append('imagesLinks', JSON.parse(longData[0]['imagesLinks']));
    formData.append('imageIndex', imageDeletionIndex);

    fetch(baseURL + confirmDataEndpointMapping[confirmData] + longData[0][mainId], {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
        }),
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            setLoading(false);
            setShowHideConfirmBox(false);
            // Persist display state and target description
            localStorage.setItem('isDisplayed', 'true');
            localStorage.setItem('targetDesc', targetDesc);
            // Set a flag before reloading the page
            localStorage.setItem('pageReloaded', 'true');
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            setErrorMessage('Server responded with - ' + error);
            // Persist display state and target description
            localStorage.setItem('isDisplayed', 'true');
            localStorage.setItem('targetDesc', targetDesc);
            // Set a flag before reloading the page
            localStorage.setItem('pageReloaded', 'true');
            window.location.reload();
        });
}

export function handleSubmit(
    confirmData,
    {
        subTableFormData = undefined,
        setSubTableFormData = undefined,
        longData = undefined,
        token = undefined,
        setLoading = undefined,
        setShowHideConfirmBox = undefined,
        setErrorMessage = undefined,
        measurementStandard = undefined,
        mainId = undefined,
        targetDesc = undefined,
        event = undefined,
        setFetchCompleted = undefined,
    },
) {
    event.preventDefault();
    const formData = new FormData();

    if (confirmData && confirmData.includes('delete')) {
        if (longData[0]['imagesLinks'] && JSON.parse(longData[0]['imagesLinks'])) {
            formData.append('imagesLinks', JSON.parse(longData[0]['imagesLinks']));
            formData.append('n_imgs_current', JSON.parse(longData[0]['imagesLinks']).length);
        } else {
            formData.append('imagesLinks', []);
            formData.append('n_imgs_current', '0');
        }
        fetch(baseURL + confirmData + longData[0][mainId], {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
            }),
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                setLoading(false);
                setShowHideConfirmBox(false);
                setSubTableFormData('');
                // Persist display state and target description
                localStorage.setItem('isDisplayed', 'true');
                localStorage.setItem('targetDesc', targetDesc);

                // Set a flag before reloading the page
                localStorage.setItem('pageReloaded', 'true');

                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setErrorMessage('Server responded with - ' + error);
                // Persist display state and target description
                localStorage.setItem('isDisplayed', 'true');
                localStorage.setItem('targetDesc', targetDesc);

                // Set a flag before reloading the page
                localStorage.setItem('pageReloaded', 'true');
                window.location.reload();
            });
    } else if (confirmData) {
        formData.append(confirmData, JSON.stringify(subTableFormData));
        formData.append('updateType', confirmData);
        formData.append('measurementStandard', measurementStandard);
        formData.append('warehouse', longData[0]['warehouse']);
        formData.append('supplierReferenceNumber', longData[0]['supplierReferenceNumber']);
        fetch(baseURL + confirmDataEndpointMapping[confirmData] + longData[0][mainId], {
            credentials: 'include',
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
            }),
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                setLoading(false);
                setShowHideConfirmBox(false);
                setSubTableFormData('');

                // Persist display state and target description
                localStorage.setItem('isDisplayed', 'true');
                localStorage.setItem('targetDesc', targetDesc);

                // Set a flag before reloading the page
                localStorage.setItem('pageReloaded', 'true');

                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setErrorMessage('Server responded with - ' + error);
                // Persist display state and target description
                localStorage.setItem('isDisplayed', 'true');
                localStorage.setItem('targetDesc', targetDesc);

                // Set a flag before reloading the page
                localStorage.setItem('pageReloaded', 'true');

                window.location.reload();
            });
    }
}

export function handleEdit(
    confirmData,
    {
        editFormData = undefined,
        setEditFormData = undefined,
        longData = undefined,
        token = undefined,
        setLoading = undefined,
        setShowHideConfirmBox = undefined,
        setErrorMessage = undefined,
        measurementStandard = undefined,
        mainId = undefined,
        targetDesc = undefined,
    },
) {
    const formData = new FormData();
    formData.append(confirmData, JSON.stringify(editFormData));

    fetch(baseURL + confirmDataEndpointMapping[confirmData] + longData[0][mainId], {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
        }),
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            setLoading(false);
            setShowHideConfirmBox(false);
            setEditFormData('');
            setTimeout(5);
            refreshLongData(targetDesc);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            setErrorMessage('Server responded with - ' + error);
            setTimeout(50);
            refreshLongData(targetDesc);
        });
}

export function editOn(
    e,
    {
        readOnly = undefined,
        setReadOnly = undefined,
        setEditButtonClass = undefined,
        setInputClass = undefined,
        confirmData = undefined,
        setConfirmData = undefined,
        setShowHideConfirmBox = undefined,
    },
) {
    console.log(readOnly);
    console.log(confirmData);
    if (readOnly) {
        setReadOnly(false);
        setEditButtonClass('standardButtonTriggered');
        setInputClass('inTableInputTriggered');
    } else {
        setReadOnly(true);
        setEditButtonClass('standardButton');
        setInputClass('inTableInput');
        setConfirmData(confirmData);
        setShowHideConfirmBox(true);
    }
}

export function handleEditValues(e, { editTableFormData, setEditTableFormData }) {
    const name = e.target.getAttribute('name');
    let value = '';
    if (!e.target.textContent) {
        value = e.target.value;
    } else {
        value = e.target.textContent;
    }
    console.log('value:', value);
    console.log('name:', name);

    let tempAddNewTitlesValues = { ...editTableFormData };
    console.log(tempAddNewTitlesValues);
    tempAddNewTitlesValues[name] = value;
    console.log(tempAddNewTitlesValues);
    setEditTableFormData(tempAddNewTitlesValues);
}

/**REFRESH LONG DATA */
export function refreshLongData({
    targetDesc,
    setTargetDesc,
    dataFetchId,
    token,
    setLongData,
    setLoading,
    setErrorMessage,
}) {
    console.log(targetDesc);
    if (targetDesc) {
        fetch(baseURL + dataFetchId + '/' + targetDesc, {
            credentials: 'include',
            method: 'GET',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                setLongData(data);
                // setTargetDesc();
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage('Server responded with - ' + error);
            });
    }
}

/**SUBTABLE HELPERS */
export function joinArray(
    e,
    {
        arrayToAdd = undefined,
        arrayToAddTo = undefined,
        setArrayToAddTo = undefined,
        setSubTableFormData = undefined,
        setKeys = undefined,
        mode = undefined,
    },
) {
    //initial array data types are either :
    // 1. one json array with keys and values (one-json)
    // 2. js array of multiple json arrays (multi-json)
    // 3. empty (null)
    let tempInfoArray = arrayToAddTo;
    console.log('before add: ', tempInfoArray);

    if (mode === 'one-json') {
        //i.e. merge two json arrays together
        console.log(arrayToAdd);
        let newKeys = Object.keys(arrayToAdd[0]);
        console.log(newKeys);
        let oldKeys = Object.keys(arrayToAddTo[0]);
        console.log(oldKeys);
        let commonKeys = newKeys.filter((key, k) => {
            return oldKeys.includes(key);
        });
        console.log('commonKeys: ', commonKeys);
        if (commonKeys.length === 0) {
            newKeys.forEach((key, k) => {
                if (arrayToAdd[0][key]) {
                    tempInfoArray[0][key] = arrayToAdd[0][key];
                } else {
                    alert('Please fill in all values.');
                }
            });
        } else if (newKeys) {
            handleInfoClear(e);
            alert('There is already an entry for that information.');
        }

        console.log('after add: ', tempInfoArray);
        setArrayToAddTo(tempInfoArray);
        setSubTableFormData(tempInfoArray);
        setKeys(Object.keys(tempInfoArray[0]));
    } else if (mode === 'multi-json') {
        console.log(arrayToAdd);
        let tempArrayToAdd = arrayToAdd;
        let tempKeys = Object.keys(tempArrayToAdd[0]);

        let checkAdd = tempKeys.filter((key, k) => {
            if (tempArrayToAdd[0][key]) {
                return false;
            } else {
                return true;
            }
        });
        console.log(checkAdd);
        if (checkAdd.length === 0) {
            console.log(arrayToAdd);
            console.log(tempInfoArray);
            tempInfoArray.push.apply(tempInfoArray, [arrayToAdd[0]]);
            console.log(tempInfoArray);
            setArrayToAddTo(tempInfoArray);
            setSubTableFormData(tempInfoArray);
            setKeys(Object.keys(tempInfoArray));
        } else {
            alert('Please fill in all values.');
        }
    }
}

export function clearAdded(
    e,
    {
        index = undefined,
        arrayToRemoveFrom = undefined,
        setArrayToRemoveFrom = undefined,
        setKeys = undefined,
        setSubTableFormData = undefined,
        mode = undefined,
    },
) {
    console.log('Before clear: ' + JSON.stringify(arrayToRemoveFrom));
    console.log(index);
    var tempArray = arrayToRemoveFrom;
    if (mode === 'one-json') {
        const tempKeys = Object.keys(tempArray[0]);
        console.log('Keys before clear: ' + JSON.stringify(tempKeys));
        const keyToRemove = tempKeys[index];
        console.log('Key to remove: ' + JSON.stringify(keyToRemove));
        let newTempArray = tempArray;
        tempKeys.forEach((key, k) => {
            if (k === index) {
                delete newTempArray[0][key];
            }
        });
        let newTempKeys = Object.keys(newTempArray[0]);
        console.log('After remove key: ' + JSON.stringify(newTempArray));
        setArrayToRemoveFrom(newTempArray);
        setKeys(newTempKeys);
        setSubTableFormData(newTempArray);
    } else if (mode === 'multi-json') {
        console.log('Keys before clear: ' + JSON.stringify(tempArray));
        console.log('Index: ' + index);
        tempArray.splice(index, 1); // Remove the element at the specified index
        console.log('After remove key: ' + JSON.stringify(tempArray));
        const modifiedNewTempKeys = Array.from({ length: tempArray.length }, (_, index) => index);
        console.log('Key after remove: ' + JSON.stringify(modifiedNewTempKeys));
        setArrayToRemoveFrom(tempArray);
        setKeys(modifiedNewTempKeys);
        setSubTableFormData(tempArray);
    }
}

export function handleTitleValue(
    e,
    {
        addNewTitlesValues = undefined,
        setAddNewTitlesValues = undefined,
        title = undefined,
        setTitle = undefined,
        // mode = "dropdown-text"
        mode = undefined,
    },
) {
    let updatedTitleValues = [{}];
    let name = e.target.getAttribute('name');
    let value = e.target.textContent ? e.target.textContent : e.target.value;

    if (mode === 'dropdown-text') {
        console.log('before: ', updatedTitleValues);
        if (name === value) {
            //if dropdown
            setTitle(e.target.textContent);
        } else {
            //if text
            updatedTitleValues[0][title] = value;
            setAddNewTitlesValues(updatedTitleValues);
        }
    } else if (mode === 'text-text') {
        console.log('before: ', updatedTitleValues);
        if (name === 'title') {
            setTitle(e.target.value);
        } else {
            updatedTitleValues[0][title] = value;
            setAddNewTitlesValues(updatedTitleValues);
        }
    }
    console.log('after: ', updatedTitleValues);
}

export function handleMultiTitlesValues(
    e,
    {
        addNewTitlesValues = undefined,
        setAddNewTitlesValues = undefined,
        subTableId = undefined,
        inputVariables = undefined,
        mode = 'mixed',
    },
) {
    //types of input:
    //Multiple mixed inputs (multi)
    let name = e.target.getAttribute('name');

    console.log(addNewTitlesValues);
    if (mode === 'mixed') {
        console.log(inputVariables.formId);
        const form = document.getElementById(inputVariables.formId);
        if (!form) {
            console.error(`Form with ID ${inputVariables.formId} not found.`);
            return;
        }
        const formData = new FormData(form);

        let value = '';
        if (!e.target.textContent) {
            value = e.target.value;
        } else {
            value = e.target.textContent;
        }
        console.log('value:', value);
        console.log('name:', name);
        console.log(subTableId);
        let tempAddNewTitlesValues = { ...addNewTitlesValues };
        if (subTableId === 'sellingPrice') {
            tempAddNewTitlesValues[0][name] = value;
            const sellingPrice = parseFloat(formData.get('sellingPrice') || 0);
            const platformCommission = parseFloat(formData.get('platformCommission') || 0);
            const shippingPrice = parseFloat(formData.get('shippingPrice') || 0);
            const inputParams = {
                sellingPrice: sellingPrice,
                platformCommission: platformCommission,
                shippingPrice: shippingPrice,
                purchasePrice: inputVariables.purchasePrice,
            };
            let tempArray = calcProfit(subTableId, inputParams);
            tempAddNewTitlesValues[0]['vat'] = tempArray['vat'];
            tempAddNewTitlesValues[0]['profit'] = tempArray['profit'];
            tempAddNewTitlesValues[0]['profitMargin'] = tempArray['profitMargin'];
            tempAddNewTitlesValues[0]['purchasePrice'] = inputVariables.purchasePrice;
            console.log(tempAddNewTitlesValues);
            setAddNewTitlesValues(tempAddNewTitlesValues);
        } else if (subTableId === 'orderItems') {
            tempAddNewTitlesValues[0][name] = value;
            const sellingPrice = parseFloat(formData.get('sellingPrice') || 0);
            const platformCommission = parseFloat(formData.get('platformCommission') || 0);
            const shippingPrice = parseFloat(formData.get('shippingPrice') || 0);
            const otherCommission = parseFloat(formData.get('otherCommission') || 0);
            const discount = parseFloat(formData.get('discount') || 0);
            const quantity = parseFloat(formData.get('quantity') || 0);
            const inputParams = {
                sellingPrice: sellingPrice,
                platformCommission: platformCommission,
                shippingPrice: shippingPrice,
                purchasePrice: inputVariables.purchasePrice,
                otherCommission: otherCommission,
                discount: discount,
                quantity: quantity,
            };
            let tempArray = calcProfit(subTableId, inputParams);
            tempAddNewTitlesValues[0]['vat'] = tempArray['vat'];
            tempAddNewTitlesValues[0]['profit'] = tempArray['profit'];
            tempAddNewTitlesValues[0]['profitMargin'] = tempArray['profitMargin'];
            tempAddNewTitlesValues[0]['purchasePrice'] = inputVariables.purchasePrice;
            tempAddNewTitlesValues[0]['lineTotalVat'] = tempArray['lineTotalVat'];
            tempAddNewTitlesValues[0]['lineTotalNoVat'] = tempArray['lineTotalNoVat'];
            tempAddNewTitlesValues[0]['image'] = inputVariables.imageLink;
            setAddNewTitlesValues(tempAddNewTitlesValues);
        } else if (subTableId === 'poStockInfo') {
            tempAddNewTitlesValues[0][name] = value;
            const purchasePrice = parseFloat(formData.get('purchasePrice') || 0);
            const quantity = parseFloat(formData.get('quantity') || 0);
            const lineTotal = +(purchasePrice * quantity).toFixed(2);
            tempAddNewTitlesValues[0]['lineTotal'] = lineTotal;

            // Get all carton dimensions from FormData
            const cartonHeight = parseFloat(formData.get('cartonHeight'));
            const cartonLength = parseFloat(formData.get('cartonLength'));
            const cartonWidth = parseFloat(formData.get('cartonWidth'));
            const cartonWeight = parseFloat(formData.get('cartonWeight'));

            // Check if all carton dimensions are available and valid
            if (
                !isNaN(cartonHeight) &&
                !isNaN(cartonLength) &&
                !isNaN(cartonWidth) &&
                !isNaN(cartonWeight) &&
                cartonHeight > 0 &&
                cartonLength > 0 &&
                cartonWidth > 0 &&
                cartonWeight > 0
            ) {
                const cartonDimensions = {
                    Height: cartonHeight,
                    Length: cartonLength,
                    Width: cartonWidth,
                    Weight: cartonWeight,
                };
                const cbm = calcCBM(cartonDimensions);
                tempAddNewTitlesValues[0]['cbm'] = cbm;
            } else {
                tempAddNewTitlesValues[0]['cbm'] = 'N/A'; // Or some other default value
            }

            const number = Object.keys(inputVariables.infoArray).length + 1;
            tempAddNewTitlesValues[0]['number'] = number;

            console.log(tempAddNewTitlesValues);
            setAddNewTitlesValues(tempAddNewTitlesValues);
        } else {
            let tempAddNewTitlesValues = { ...addNewTitlesValues };
            console.log(tempAddNewTitlesValues);
            tempAddNewTitlesValues[0][name] = value;
            console.log(tempAddNewTitlesValues);
            setAddNewTitlesValues(tempAddNewTitlesValues);
        }
    }
}

export function handleInfoClear(e) {
    document.forms[0].reset();
}

/**PARSE DATE */
export function parseDate(string) {
    if (string !== null) {
        return string.substring(0, 16);
    } else {
        return '00:00:00';
    }
}

export function formatMySQLDate(date) {
    let tempDate = new Date(date);
    tempDate = tempDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
    return tempDate;
}

/**HANDLE UNITS */
export function handleMeasurementType(measurementStandard, setMeasurementStandard) {
    if (measurementStandard === 'metric') {
        setMeasurementStandard('imperial');
    } else if (measurementStandard === 'imperial') {
        setMeasurementStandard('metric');
    }
}

/**CLEAR & SAVE ROW */
export function ClearAndSave({
    setInfoArray = undefined,
    setKeys = undefined,
    longData = undefined,
    subTableId = undefined,
    defaultKeys = undefined,
    setShowHideConfirmBox = undefined,
    setConfirmData = undefined,
}) {
    return (
        <>
            <div className="editButtonHolder" key="productDimensionsButtons">
                <button
                    key="editProductDimensionsClear"
                    type="button"
                    className="editButton"
                    title="Refresh table."
                    onClick={(e) =>
                        resetArray(e, {
                            setInfoArray,
                            setKeys,
                            longData,
                            subTableId,
                            defaultKeys,
                        })
                    }
                >
                    <i className="fa fa-refresh"></i>
                </button>
                <button
                    key="editProductDimensionsSave"
                    type="button"
                    className="editButton"
                    title="Save changes."
                    onClick={(e) =>
                        showConfirm(e, {
                            subTableId,
                            setConfirmData,
                            setShowHideConfirmBox,
                        })
                    }
                >
                    <i className="fas fa-save"></i>
                </button>
            </div>
        </>
    );
}

export function showConfirm(
    e,
    { subTableId = undefined, setConfirmData = undefined, setShowHideConfirmBox = undefined },
) {
    e.preventDefault();
    setConfirmData(subTableId);
    setShowHideConfirmBox(true);
}

export function resetArray(
    e,
    {
        setInfoArray = undefined,
        setKeys = undefined,
        longData = undefined,
        subTableId = undefined,
        defaultKeys = undefined,
    },
) {
    setInfoArray(JSON.parse(longData[0][subTableId]));
    setKeys(defaultKeys);
    initialiseAddNewTitlesValues();
}

export function initialiseAddNewTitlesValues(e, { defaultData = undefined, setAddNewTitlesValues = undefined }) {
    let tempArray = {};
    let keys = Array.isArray(defaultData) ? Object.keys(defaultData[0]) : Object.keys(defaultData);
    keys.forEach((key, k) => {
        tempArray[key] = '';
    });
    console.log(tempArray);
    setAddNewTitlesValues(tempArray);
    handleInfoClear(e);
}

/**CALC HELPERS */
export function calcProfit(
    subTableId,
    {
        sellingPrice = undefined,
        platformCommission = undefined,
        shippingPrice = undefined,
        purchasePrice = undefined,
        otherCommission = undefined,
        discount = undefined,
        quantity = undefined,
    },
) {
    let vat = sellingPrice ? 0.2 * sellingPrice : 0;
    sellingPrice = sellingPrice ? sellingPrice : 0;
    purchasePrice = purchasePrice ? purchasePrice : 0;
    platformCommission = platformCommission ? platformCommission : 0;
    shippingPrice = shippingPrice ? shippingPrice : 0;
    otherCommission = otherCommission ? otherCommission : 0;
    discount = discount ? discount : 0;
    quantity = quantity ? quantity : 0;

    if (subTableId === 'sellingPrice') {
        let profit =
            sellingPrice - vat - purchasePrice - platformCommission - shippingPrice - otherCommission - discount;
        let profitMargin = profit / sellingPrice;
        let tempArray = {};
        tempArray['vat'] = +parseFloat(vat).toFixed(2);
        tempArray['profit'] = +parseFloat(profit).toFixed(2);
        tempArray['profitMargin'] = +parseFloat(profitMargin).toFixed(2);
        return tempArray;
    } else if (subTableId === 'orderItems') {
        vat = sellingPrice ? 0.2 * sellingPrice * quantity : 0;
        let profit =
            quantity * (sellingPrice - purchasePrice) -
            vat -
            platformCommission -
            shippingPrice -
            otherCommission -
            discount;
        let profitMargin = profit / quantity / sellingPrice;
        let lineTotalVat = quantity * sellingPrice;
        let lineTotalNoVat = quantity * sellingPrice - vat;
        let tempArray = {};
        tempArray['vat'] = +parseFloat(vat).toFixed(2);
        tempArray['profit'] = +parseFloat(profit).toFixed(2);
        tempArray['profitMargin'] = +parseFloat(profitMargin).toFixed(2);
        tempArray['lineTotalVat'] = +parseFloat(lineTotalVat).toFixed(2);
        tempArray['lineTotalNoVat'] = +parseFloat(lineTotalNoVat).toFixed(2);
        return tempArray;
    }
}

export function calcCBM(cartonDimensions) {
    let height = parseFloat(cartonDimensions['Height']);
    let width = parseFloat(cartonDimensions['Width']);
    let length = parseFloat(cartonDimensions['Length']);
    let weight = parseFloat(cartonDimensions['Weight']);
    let cbm = +((height * width * length) / (10 ** 6 * weight)).toFixed(4);
    return cbm;
}

/**OTHER HELPERS */
export function translateHeaderToKeyFormat(string) {
    const removedBracket = string.replace(/\s*\([^)]*\)/g, '');
    const splitArray = removedBracket.split(' ');
    const updatedArray = splitArray.map((element, i) => (i === 0 ? element.toLowerCase() : element));
    const joinedArray = updatedArray.join('');

    if (string === 'Line Total Exc. VAT') {
        return 'lineTotalNoVat';
    } else if (string === 'Line Total Inc. VAT') {
        return 'lineTotalVat';
    } else {
        return joinedArray;
    }
}

export function retrieveSubTableHeaders(subTableId) {
    if (subTableId === 'productDimensions') {
        return productDimensionHeaders;
    } else if (subTableId === 'altSku') {
        return altSkuHeaders;
    } else if (subTableId === 'customProperties') {
        return customPropertiesHeaders;
    } else if (subTableId === 'cartonDimensions') {
        return cartonDimensionsHeaders;
    } else if (subTableId === 'stock') {
        return stockHeaders;
    } else if (subTableId === 'composition') {
        return compositionHeaders;
    } else if (subTableId === 'poStockInfo') {
        return poStockInfoHeaders;
    } else if (subTableId === 'warehouseContacts') {
        return warehouseContactHeaders;
    } else if (subTableId === 'variationGroupComponents') {
        return variationHeaders;
    } else if (subTableId === 'shippingService') {
        return shippingHeaders;
    } else if (subTableId === 'sellingPrice') {
        return sellingPriceHeaders;
    } else if (subTableId === 'orderItems') {
        return orderItemsHeaders;
    }
}

export function retrieveSubTableHeaderKeys(subTableId) {
    if (subTableId === 'poStockInfo') {
        return poStockInfoHeaderKeys;
    } else if (subTableId === 'warehouseContacts') {
        return warehouseContactsHeaderKeys;
    } else if (subTableId === 'shippingService') {
        return shippingServiceHeaderKeys;
    } else if (subTableId === 'sellingPrice') {
        return sellingPriceHeaderKeys;
    } else if (subTableId === 'orderItems') {
        return orderItemsHeaderKeys;
    }
}

export function parseTitle(str) {
    let capitalized = str.replace(/([a-z])([A-Z])/g, '$1 $2'); // replace lowercase letter followed by an uppercase letter with the lowercase letter, a space, and the uppercase letter
    capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1); // capitalize the first letter of the string
    //expected: "Custom Properties "

    let uncapitalized = str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    //expected: "custom properties"

    let capitalized2 = str.charAt(0).toUpperCase() + str.slice(1);
    //expected: "CustomProperties"

    return [capitalized, uncapitalized, capitalized2];
}

export function convertMeasurements(measurementValue, measurementStandard, measurementType) {
    const tempType = measurementType.toLowerCase();

    if (tempType === 'weight') {
        let tempValue = typeof measurementValue === 'string' ? parseFloat(measurementValue) : measurementValue;
        if (measurementStandard === 'imperial') {
            return (tempValue * 2.205).toFixed(2);
        } else if (measurementStandard === 'metric') {
            return measurementValue;
        }
    } else if (tempType === 'title') {
        if (measurementValue === 'Value') {
            if (measurementStandard === 'imperial') {
                return ' (in, lbs)';
            } else if (measurementStandard === 'metric') {
                return ' (cm, kg)';
            }
        } else {
            return '';
        }
    } else {
        let tempValue = typeof measurementValue === 'string' ? parseFloat(measurementValue) : measurementValue;
        if (measurementStandard === 'imperial') {
            return (tempValue / 2.54).toFixed(2);
        } else if (measurementStandard === 'metric') {
            return measurementValue;
        }
    }
}

export function calcTotal(array, parameter) {
    let tempArrayToSum = [];
    array.map((row, i) => {
        tempArrayToSum[i] = row[parameter];
        return null;
    });
    let sum = tempArrayToSum.reduce((a, b) => {
        return parseInt(a) + parseInt(b);
    });
    return sum;
}

export function sortByKey(arrayToSort, keysToSortWith) {
    let tempSortedArray = {};

    keysToSortWith.map((key, k) => {
        tempSortedArray[key] = arrayToSort[key];
        return key, k;
    });

    return tempSortedArray;
}

export function parseSQLToTitles(string) {
    if (string === 'poNumber') {
        return 'PO No.';
    } else if (string === 'supplierReferenceNumber') {
        return 'Supplier Ref. No.';
    } else if (string === 'claimAmount') {
        return 'Claim Amount ($)';
    } else if (string === 'cbm') {
        return (
            <>
                CBM (m<sup>3</sup>)
            </>
        );
    } else if (string === 'paidStatus') {
        return 'Paid Status';
    }

    let word = string;
    let words = string.split(/(?=[A-Z])/); // split words at capital letters
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)); // capitalize the first letter of each word
    if (/\s/.test(words)) {
        word = words.join('');
    } else {
        word = words.join(' ');
    }

    if (word.toLowerCase().includes('sku')) {
        return word.replace(/sku/i, 'SKU');
    } else if (word.toLowerCase().includes('id')) {
        return word.replace(/id/i, 'ID');
    } else {
        return word;
    }
}

// export function calcInput(subTableId, totalArray, currentArrayLine){
//     if(subTableId === "poStockInfo"){
//         console.log(currentArrayLine)
//         let cbm = 0
//         let lineTotal = 0
//         let number = 0

//         // cbm = (currentArrayLine["cartonHeight"]*currentArrayLine["cartonWidth"]*currentArrayLine["cartonLength"])/((10**6)*currentArrayLine["cartonWeight"])
//         cbm = calcCBM(currentArrayLine["cartonHeight"], currentArrayLine["cartonWidth"], currentArrayLine["cartonLength"], currentArrayLine["cartonWeight"])
//         lineTotal = (currentArrayLine["purchasePrice"]*currentArrayLine["quantity"])
//         number = totalArray.length + 1

//         let calculatedInput = {"cbm" : cbm, "lineTotal" : lineTotal, "number" : number}

//         return calculatedInput
//     }
// }

// export function parseStockType(longDataArray, header) {
//     if (longDataArray[0][header]) {
//         var total = 0;
//         var jsonObj = JSON.parse(longDataArray[0][header]);
//         const keys = Object.keys(jsonObj);

//         keys.map((key) => (total += parseInt(jsonObj[key])));
//         return total;
//     } else {
//         return 'No data yet.';
//     }
// }

// export function convertKeyPair(array, mode) {
//     console.log(array);
//     console.log(mode);
//     if (mode === 'multi-to-single') {
//         let tempSingleArray = {};

//         array.map((term, i) => {
//             let key = Object.keys(term)[0];
//             let value = Object.values(term)[0];

//             tempSingleArray[key] = value;
//             return true;
//         });
//         return tempSingleArray;
//     }
// }

// export function retrieveTitlesFromSubTableId(subTableId) {
//     if (subTableId === 'cartonDimensions') {
//         return cartonDimensionOptions;
//     } else {
//         return Array.from(['dummy', 'dummy_2']);
//     }
// }

export function capitalise(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**HEADERS */

const productDimensionHeaders = Array.from(['Product Dimensions', 'Value']);

const cartonDimensionsHeaders = Array.from(['Carton Dimensions', 'Value']);

const altSkuHeaders = Array.from(['Name', 'Value']);

const customPropertiesHeaders = Array.from(['Name', 'Value']);

const compositionHeaders = Array.from(['SKU', 'Quantity']);

const stockHeaders = Array.from(['Warehouse', 'Stock', 'In Open Orders', 'Available']);

export const warehouseContactHeaders = Array.from(['Name', 'Role', 'Email', 'Phone']);

export const variationHeaders = Array.from(['SKU', 'Value']);

export const shippingHeaders = Array.from([
    'SKU',
    'Quantity',
    'Warehouse',
    'Shipping Price',
    'Courier',
    'Shipping Service',
]);

export const sellingPriceHeaders = Array.from([
    'Channel Name',
    'Purchase Price',
    'Selling Price (GMV)',
    'Platform Commission',
    'Shipping Price',
    'VAT',
    'Profit',
    'Profit Margin',
]);

export const poStockInfoHeaders = Array.from([
    'Number',
    'SKU',
    'Quantity',
    'Purchase Price ($)',
    'Line Total ($)',
    'Packaging (unit/ctn)',
    'Carton Height (cm)',
    'Carton Length (cm)',
    'Carton Width (cm)',
    'Carton Weight (kg)',
    'CBM',
]);

export const orderItemsHeaders = Array.from([
    'Image',
    'SKU',
    'Quantity',
    'Purchase Price',
    'Selling Price (GMV)',
    'Platform Commission',
    'Shipping Price',
    'VAT',
    'Other Commission',
    'Discount',
    'Profit',
    'Profit Margin',
    'Line Total No VAT',
    'Line Total VAT',
]);

/**HEADER KEYS */
export const poStockInfoHeaderKeys = Array.from([
    'number',
    'sku',
    'quantity',
    'purchasePrice',
    'lineTotal',
    'packaging',
    'cartonHeight',
    'cartonLength',
    'cartonWidth',
    'cartonWeight',
    'cbm',
]);

export const warehouseContactsHeaderKeys = Array.from(['name', 'role', 'email', 'phone']);

export const shippingServiceHeaderKeys = Array.from([
    'sku',
    'quantity',
    'warehouse',
    'shippingPrice',
    'courier',
    'shippingService',
]);

export const orderItemsHeaderKeys = Array.from([
    'image',
    'sku',
    'quantity',
    'purchasePrice',
    'sellingPrice',
    'platformCommission',
    'shippingPrice',
    'vat',
    'otherCommission',
    'discount',
    'profit',
    'profitMargin',
    'lineTotalNoVat',
    'lineTotalVat',
]);

export const sellingPriceHeaderKeys = Array.from([
    'channelName',
    'purchasePrice',
    'sellingPrice',
    'platformCommission',
    'shippingPrice',
    'vat',
    'profit',
    'profitMargin',
]);

/**OPTIONS */
export const cartonDimensionOptions = Array.from(['Height', 'Length', 'Width', 'Weight']);

export const productCategory = Array.from(['Barstool']);
