import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AnnouncementsBox } from './announcements';
import { QuickLinksBox } from './quickLinks';
import { DefectButtons } from './factoryDefectsButtons';
import { InventoryButtons } from './inventoryManagementButtons';
import { PurchaseOrdersButtons } from './purchaseOrdersButtons';
import { FactoryButtons } from './factoryManagementButtons';
import { WarehouseButtons } from './warehouseManagementButtons';
import { PricingButtons } from './pricingButtons';
import { OpenOrdersButtons } from './openOrdersButtons';
import { ProcessedOrdersButtons } from './processedOrdersButtons';
import { UserButtons } from './userManagementButtons';
import { ConfirmPassword } from './confirmPassword';
import { DraftOrdersButtons } from './draftOrdersButtons';

function BannerImage({ title }) {
    return [
        <div className="bannerImage" key="bannerImage">
            <span key="bannerTitle">Mmilo Portal: {title}</span>
        </div>,
        <div className="spacerDiv" key="spacerDiv"></div>,
    ];
}

function Footer() {
    return [
        <br key="footerLineBreak"></br>,
        <div className="footer" key="footerCopyright">
            <p key="copywriteMessage">Copyright © 2023: Mmilo Modern Furniture Ltd</p>
        </div>,
    ];
}

export function Home({ token, setToken, removeToken, user }) {
    return (
        <>
            <BannerImage title="Home" />
            <QuickLinksBox />
            <AnnouncementsBox token={token} setToken={setToken} removeToken={removeToken} user={user} />
            <Footer />
        </>
    );
}

export function InventoryManagement({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Inventory > Inventory Management" />
            <InventoryButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function ContainerManagement({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Inventory > Container Management" />
            <Footer />
        </>
    );
}

export function FactoryManagement({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Inventory > Factory Management" />
            <FactoryButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function WarehouseManagement({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Inventory > Warehouse Management" />
            <WarehouseButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function PurchaseOrders({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Inventory > Purchase Orders" />
            <PurchaseOrdersButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function OpenOrders({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Orders > Open Orders" />
            <OpenOrdersButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function DraftOrders({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Orders > Open Orders" />
            <DraftOrdersButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function ProcessedOrders({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Orders > Processed Orders" />
            <ProcessedOrdersButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function ChannelManagement({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Integrations > Channel Management" />
            <Footer />
        </>
    );
}

export function ExportCentre({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Data > Export Centre" />
            <Footer />
        </>
    );
}

export function RedProducts({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Data > Red Products" />
            <Footer />
        </>
    );
}

export function FactoryDefects({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Data > Factory Defects" />
            <DefectButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function Invoicing({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Data > Invoicing" />
            <Footer />
        </>
    );
}

export function Pricing({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Data > Pricing" />
            <PricingButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function ApplyLeave({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Admin > Apply Leave" key="bannerApplyLeave" />
            <Footer key="footerApplyLeave" />
        </>
    );
}

export function TrainingMaterial({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Admin > Training Material" key="bannerTrainingMaterial" />
            <Footer key="footerTrainingMaterial" />
        </>
    );
}

export function UserManagement({ token, setToken, removeToken }) {
    return (
        <>
            <BannerImage title="Admin > User Management" />
            <UserButtons setToken={setToken} removeToken={removeToken} token={token} />
            <Footer />
        </>
    );
}

export function ResetPasswordConfirm({ token, setToken, removeToken, resetEmail, setResetToken }) {
    return (
        <>
            <ConfirmPassword
                setToken={setToken}
                removeToken={removeToken}
                token={token}
                resetEmail={resetEmail}
                setResetToken={setResetToken}
            />
        </>
    );
}

export function Error404() {
    //useLocation is called a hook.
    let location = useLocation();
    return (
        <div>
            <h1>Resource not found at {location.pathname} !</h1>
        </div>
    );
}
